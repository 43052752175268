// import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";

const SearchBar = ({
  handleSearch,
  searchValue,
  setShowSearch,
  title,
  placeholderTitle,
}) => (
  <>
    <TextField
      id="search-bar"
      onChange={(e) => {
        if (e.target.value.length === 0) {
          setShowSearch(false);
        } else {
          setShowSearch(true);
        }
        handleSearch(e);
      }}
      value={searchValue}
      label={title}
      variant="outlined"
      sx={{ width: 400, maxWidth: "80%" }}
      placeholder={placeholderTitle}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            style={{ border: "0px solid transparent" }}
          >
            <IconButton aria-label="search">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  </>
);
export default SearchBar;
