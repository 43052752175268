import { apiSlice } from "app/api/apiSlice";

export const serviceProviderApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getServices: builder.query({
      query: (query) => {
        const { pagination, searchValue } = query;
        const { pageIndex, pageSize } = pagination;
        const searchQuery = searchValue ? `&query=${searchValue}` : "";
        return {
          url: `/service_provider/services/?page=${pageIndex}&page_size=${pageSize}${searchQuery}`,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: ["Services"],
    }),
    getServiceRequests: builder.query({
      query: (query) => {
        const { pagination, searchValue } = query;
        const { pageIndex, pageSize } = pagination;
        const searchQuery = searchValue ? `&query=${searchValue}` : "";
        return {
          url: `/service_provider/service/requests/?page=${pageIndex}&page_size=${pageSize}${searchQuery}`,
        };
      },
    }),
    addService: builder.mutation({
      query: (body) => ({
        url: `/service_provider/service/add/`,
        method: "POST",
        body,
      }),
    }),
    deleteService: builder.mutation({
      query: (body) => ({
        url: `/service_provider/service/remove/`,
        method: "DELETE",
        credentials: "include",
        body,
      }),
      invalidatesTags: ["Services"],
    }),
  }),
});

export const {
  useGetServicesQuery,
  useGetServiceRequestsQuery,
  useAddServiceMutation,
  useDeleteServiceMutation,
} = serviceProviderApiSlice;
