import { InnerPages } from "menu";

export const getHomeRoute = (pages, perms) => {
  for (const page of pages) {
    const { items, ...item } = page;
    if (items) {
      const tempPath = getHomeRoute(Object.values(items), perms);
      if (tempPath !== InnerPages.Profile.path) return tempPath;
    } else if (perms.find((perm) => perm.module_type === item.module)) {
      return item.path;
    }
  }

  return InnerPages.Profile.path;
};
