import { useState } from "react";
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useField } from "formik";
import { MuiTelInput } from "mui-tel-input";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers";
const { styled } = require("@mui/system");
export const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#DEA449", // Customize the original white border color
    },
    "&:active fieldset": {
      borderColor: "#DEA449", // Customize the hover border color
    },
    "&:hover fieldset": {
      borderColor: "#DEA449", // Customize the hover border color
    },
    "&:focus fieldset": {
      borderColor: "#DEA449", // Customize the hover border color
    },
    "& input": {
      color: "black", // Set the text color to black
    },
  },
}));
// import { ThemeProvider } from "@mui/styles";

export const SubmitButton = ({
  isSubmitting,
  isDisabled = false,
  text = "Continue",
  ...props
}) => {
  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      disabled={isSubmitting || isDisabled}
      sx={{ my: 1 }}
      {...props}
    >
      {isSubmitting ? <CircularProgress size={24} /> : text}
    </Button>
  );
};

export const FormikTextField = ({ label, ...props }) => {
  const [field, meta, helpers, required] = useField(props);

  return (
    <TextField
      sx={{ my: 1 }}
      label={label}
      required={required || false}
      {...field}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      {...props}
    />
  );
};
export const FormikPasswordField = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <TextField
      sx={{ my: 1 }}
      label={label}
      {...props}
      {...field}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            style={{ border: "0pc solid transparent" }}
          >
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
};
export const FormikPhoneField = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);

  return (
    <MuiTelInput
      sx={{ my: 1 }}
      label={label}
      defaultCountry="ET"
      value={field.value}
      {...field}
      {...props}
      onChange={(value) => helpers.setValue(value)}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
};

export const FormikDateField = ({ name, label, ...props }) => {
  const [field, meta, helpers] = useField(name);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {/* <ThemeProvider> */}
      <DatePicker
        label={label}
        {...props}
        {...field}
        onChange={(value) => helpers.setValue(value)}
        renderInput={(params) => (
          <StyledTextField
            sx={{
              my: 1,
              mr: 3,
              height: "4.5rem",
              width: "100%",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#333333",
                  backgroundColor: "none",
                  outline: "none", //
                  // Cu // Customize the original white border color
                },

                "&:active fieldset": {
                  borderColor: "#BBBDBF",
                  backgroundColor: "none",
                  outline: "none !important", // Remove the default outline
                },
                "&:hover fieldset": {
                  borderColor: "#DEA449",
                  backgroundColor: "none",
                  outline: "none", //
                  // Cu // Customize the hover border color
                },
                "&:focus fieldset": {
                  borderColor: "#DEA449",
                  backgroundColor: "none",
                  outline: "none !important", // Remove the default outline
                },
                "& input": {
                  color: "black", // Set the text color to black
                },
              },
            }}
            name={name}
            label={label}
            {...props}
            {...params}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
          />
        )}
      />
      {/* </ThemeProvider> */}
    </LocalizationProvider>
  );
};
