import AddIcon from "@mui/icons-material/Add";
import { Box, Stack, Typography } from "@mui/material";
import EnhancedDataTable from "components/EnhancedDataTable";
import StyledToolbarButton from "components/styled-components/StyledToolbarButton";
import { useModal } from "contexts/modalContext";
import { StyledHeaderVarient } from "layout/Header/adminPagesHeader/Styles";
// import { useState } from "react";
import ServiceProviderRequestsHeader from "../ServiceProviderRequest/ServiceProviderRequestHeader";

const { useLocation } = require("react-router-dom");

const ServiceProviderDetail = () => {
  const location = useLocation();
  const person = location.state;
  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
    },
    {
      id: "price",
      numeric: true,
      disablePadding: true,
      label: "Price",
    },
  ];

  const Header = () => {
    return (
      <Box>
        <ServiceProviderRequestsHeader
          subHeader={"Here's a detail of your service"}
          header={person.person.name}
          showAllTime={false}
        />
        <StyledHeaderVarient sx={{ pb: 2, color: "primary.main" }}>
          <Stack spacing={0.5}>
            <Stack
              direction="row"
              spacing={1}
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              <Typography variant={"h5"} color={"primary.main"}>
                Service Description
              </Typography>
            </Stack>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                alignItems: "center",
              }}
            >
              <Typography
                variant="h4"
                fontWeight={700}
                //   fontSize={26}
                sx={{ alignSelf: "flex-end" }}
                color="primary.main"
              >
                {person.person.description}
              </Typography>
            </Box>
          </Stack>
          <Stack spacing={0.5}>
            <Stack
              direction="row"
              spacing={1}
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              <Typography variant={"h5"}>Service Price</Typography>
            </Stack>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                alignItems: "center",
              }}
            >
              <Typography
                variant="h3"
                fontWeight={700}
                //   fontSize={26}
                sx={{ alignSelf: "flex-end" }}
                color="primary.main"
              >
                {person.person.price}
              </Typography>
            </Box>
          </Stack>
        </StyledHeaderVarient>
      </Box>
    );
  };

  const ToolbarActions = () => {
    const { toggleModal } = useModal();

    // module access
    // const moduleAccess = useModuleAccess("SERVICE_PROVIDER"); // make this SERVICE_PROVIDER

    return (
      <Box
        sx={{ display: "flex", gap: "0.6rem", p: "0.5rem", flexWrap: "wrap" }}
      >
        {
          <StyledToolbarButton
            onClick={() => toggleModal("modal1")}
            startIcon={<AddIcon />}
          >
            <Typography variant="p">Add Accessories</Typography>
          </StyledToolbarButton>
        }
      </Box>
    );
  };

  const { showModal, toggleModal } = useModal();
  // const [modalData, setModalData] = useState({
  //   open: false,
  //   id: "",
  //   price: "",
  //   name: "",
  //   description: "",
  //   accessories: "",
  // });

  // const [snackOptions, setSnackOptions] = useState({
  //   open: false,
  //   text: "",
  // });

  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   setSnackOptions({
  //     open: false,
  //   });
  // };
  const onEdit = (id, row) => {
    toggleModal("modal1", {
      action: "edit",
      id,
      row,
    });
  };

  const onView = (id, row) => {
    toggleModal("modal1", {
      action: "view",
      id,
      row,
    });
  };

  const onDeleteIconClicked = (id) => {
    toggleModal("modal3", {
      action: "delete",
      id,
    });
  };

  // const getDeleteBody = (items, password) => {
  //   return {
  //     event_request_ids: items,
  //     password,
  //   };
  // };

  //   const deleteHandler = async (id, password) => {
  //     const res = await deleteService(getDeleteBody([id], password));
  //     if (res?.error) {
  //       throw new Error(res.error.data.message);
  //     }
  //   };

  return (
    <Box>
      <Header />
      <EnhancedDataTable
        headCells={headCells}
        rows={person?.person?.accessories}
        rowCount={person?.person?.accessories.length}
        manualPagination={true}
        pagination={person?.person?.accessories.length}
        // onPaginationChange={setPagination}
        onEdit={onEdit}
        onView={onView}
        onDelete={onDeleteIconClicked}
        // isLoading={isLoading}
        allowExport={false}
        toolbarActions={<ToolbarActions />}
        // editIcon={moduleAccess.update}
        // deleteIcon={moduleAccess.delete}
        // onDeleteSelected={handleMassDelete}
        // searchProps={{ searchValue, setSearchValue, showSearch, setShowSearch }}
      />
    </Box>
  );
};

export default ServiceProviderDetail;
