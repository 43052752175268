import { Box, Grid, Typography, Button, CircularProgress } from "@mui/material";
import { useGetMultipleTicketsQuery } from "features/publicEvents/publicEventsApiSlice";
import { useLanguage } from "hooks/useLanguage";
import TicketItem from "./TicketItem";
import { useSearchParams } from "react-router-dom";

const Tickets = () => {
  const { language } = useLanguage();
  const [searchParams] = useSearchParams();
  const order_id = searchParams?.get("id");

  const { data, isLoading } = useGetMultipleTicketsQuery({
    order_id: order_id,
  });

  if (isLoading) {
    return (
      <Box sx={{ py: 24 }}>
        <CircularLoadingCenter isLoading />
      </Box>
    );
  }

  if (!order_id) {
    return (
      <Typography variant={"h6"} sx={{ mt: 2, mb: 22 }}>
        Ticket order id not found.
      </Typography>
    );
  }

  if (!data) {
    return (
      <Typography variant={"h6"} sx={{ mt: 2, mb: 22 }}>
        Something went wrong while loading your tickets.
      </Typography>
    );
  }

  return (
    <Box sx={{ pt: 2, pb: 16, px: 4 }}>
      <Typography variant={"h6"} sx={{ textAlign: "center", mt: 2, mb: 4 }}>
        Here {data.ticket_codes.length > 1 ? "are" : "is"} your{" "}
        {data.ticket_codes.length}{" "}
        {data.ticket_codes.length > 1 ? "tickets" : "ticket"} for{" "}
        {data.event.name[language]}.
      </Typography>
      <Grid container>
        {data.ticket_codes.map((ticketCode, index) => {
          const ticket = {
            name: data.event.name[language],
            start: data.event.start_date,
            end: data.event.end_date,
            code: ticketCode,
            type: data.ticket_type[language],
          };

          return (
            <Grid key={ticketCode} xs={12} md={6} lg={4} xl={3} sx={{ my: 2 }}>
              <Box sx={{ width: { xs: "100%", md: "90%" } }}>
                <TicketItem {...ticket} />
                <Button
                  sx={{ mt: "10px", width: "100%" }}
                  href={`https://dev.api.event.testserver.awuraplc.org/portal/invitation/download/?ticket_code=${data.ticket_codes[index]}`}
                  variant="contained"
                  // onClick={handleDownload}
                  // disabled={isDownloading}
                >
                  Download Ticket
                </Button>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

const index = () => {
  return <Tickets />;
};

export default index;

const CircularLoadingCenter = ({ isLoading }) => {
  return (
    <Box display="flex" justifyContent={"center"}>
      {isLoading && <CircularProgress />}
    </Box>
  );
};
