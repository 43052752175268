import { styled } from "@mui/system";
import { Paper, Typography ,Grid} from "@mui/material";
import Logo from "../../res/images/NewOwlLogo.webp";

const Background = styled(Paper)({
  height: "100vh",
  background: "white",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const NotAllowed = ({ errorMessage }) => {
  return (
    <Background elevation={0}>
      <img src={Logo} alt="logo" style={{ width: "30%" }} />

      {
errorMessage && errorMessage.length > 0 ?(
<Grid display={"flex"}
 justifyContent={"ccenter"} 
 flexDirection={"column"}
 >
<Typography
        mx={"23rem"}
        
          fontFamily={"Campton"}
          fontSize={"1.2rem"}
          fontWeight={300}
          color="#DEA449"
        >
        {errorMessage}
        </Typography>
  <Typography
  mx={"23rem"}
 
  fontFamily={"Campton"}
  fontSize={"1.2rem"}
  fontWeight={300}
  color="#DEA449"
>
Please try again later.
</Typography>
</Grid>
):(
<Grid>
<Typography color="primary.main" variant="h5">
        You are not authorized to access this page.
      </Typography>
      <Typography color="primary.main" variant="h6">
        Please contact the administrator.
      </Typography>
</Grid>
)
      }
      
    </Background>
  );
};

export default NotAllowed;
