import Close from "@mui/icons-material/Close";
import { Box, Grid, IconButton, Modal, Typography } from "@mui/material";
import { ModalItemsContainer } from "components/CustomModal/Styles";
import { useModal } from "contexts/modalContext";
import { useGetSubscriptionPaymentDetailQuery } from "features/subscription/subscriptionApiSlice";

const ShowSubscriptionHistory = ({
  id,
  handleClose,
  open,
  dvr_setting,
  ...props
}) => {
  const { showModal, toggleModal, modalPayload } = useModal();
  const { data: detail, isLoading: isDetailLoading } =
    useGetSubscriptionPaymentDetailQuery(modalPayload?.id);

  const data = [
    {
      key: "Contacts",
      value: detail?.created_contacts,
      total: dvr_setting?.max_no_contacts,
    },
    {
      key: "Email Marketing Campains",
      value: detail?.created_email_marketing_campaigns,
      total: dvr_setting?.max_no_email_marketing_campaigns,
    },
    {
      key: "Events",
      value: detail?.created_events,
      total: dvr_setting?.max_no_events,
    },
    {
      key: "In App Ads",
      value: detail?.created_in_app_ads,
      total: dvr_setting?.max_no_in_app_ads,
    },
    {
      key: "Invitaions",
      value: detail?.created_invitation,
      total: dvr_setting?.max_no_invitation,
    },
    {
      key: "Marketing Campain Templates",
      value: detail?.created_marketing_campaign_templates,
      total: dvr_setting?.max_no_marketing_campaign_templates,
    },
    {
      key: "Push Notifications",
      value: detail?.created_push_notifications,
      total: dvr_setting?.max_no_push_notifications,
    },
    {
      key: "System Users",
      value: detail?.created_system_users,
      total: dvr_setting?.max_no_system_users,
    },
  ];

  return (
    <Modal
      open={showModal.modal1}
      onClose={() => toggleModal("modal1")}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ModalItemsContainer sx={{ width: "fit-content" }}>
        <Typography
          align="center"
          variant="h4"
          sx={{
            fontWeight: 700,
            mb: 3,
            color: "primary.main",
          }}
        >
          Here is an overview of your subscription
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{
            alignSelf: "flex-end",
            position: "absolute",
            top: "0",
            right: "0",
            mb: 8,
          }}
        >
          <Close />
        </IconButton>
        {!isDetailLoading && (
          <Box>
            {data?.map((item) => {
              return (
                <Grid
                  xs={12}
                  container
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{
                      fontWeight: 400,
                      mb: 1.5,
                    }}
                  >
                    {item.key}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      mb: 1.5,
                      color: "primary.main",
                    }}
                  >
                    {item.value + "/" + item.total}
                  </Typography>
                </Grid>
              );
            })}
          </Box>
        )}
      </ModalItemsContainer>
    </Modal>
  );
};

export default ShowSubscriptionHistory;
