import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import ProtectedRoutes from "routes/ProtectedRoutes";
import VendorLogin from "pages/vendors/VendorLogin";
import VendorHome from "pages/vendors/VendorHome";
import VendorEventDetail from "pages/vendors/VendorEventDetail";
import VendorRequests from "pages/vendors/VendorRequests";
import { servicesPages, vendorPages, userPages } from "menu";
import VendorProfile from "pages/vendors/VendorProfile";
import VendorNotifications from "pages/vendors/VendorNotifications";
import VendorAppBar from "./VendorAppBar";

const routes = [
  { path: vendorPages.VendorHome.path, exact: true, element: <VendorHome /> },
  {
    path: vendorPages.VendorEventDetail.path,
    exact: true,
    element: <VendorEventDetail />,
  },
  {
    path: vendorPages.VendorRequests.path,
    exact: true,
    element: <VendorRequests />,
  },
  {
    path: vendorPages.VendorProfile.path,
    exact: true,
    element: <VendorProfile />,
  },
  {
    path: vendorPages.VendorNotifications.path,
    exact: true,
    element: <VendorNotifications />,
  },
];

const noAsideRoutes = [
  servicesPages.ServicesLogin.path,
  vendorPages.VendorLogin.path,
  userPages.UserLogin.path,
];

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function Nav() {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      {!noAsideRoutes.includes(location.pathname) && (
        <VendorAppBar open={open} setOpen={setOpen} />
      )}

      <Box
        width={"100%"}
        sx={{
          py: !noAsideRoutes.includes(location.pathname) ? 1 : 0,
          px: !noAsideRoutes.includes(location.pathname) ? 2 : 0,
        }}
      >
        {!noAsideRoutes.includes(location.pathname) && <DrawerHeader />}
        <Routes>
          <Route
            path={vendorPages.VendorLogin.path}
            exact
            element={<VendorLogin />}
          />
          <Route
            element={
              // <ProtectedRoutes redirectPath={vendorPages.VendorLogin.path} />
              <ProtectedRoutes redirectPath={"/login"} />
            }
          >
            {routes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                exact={route.exact}
                element={route.element}
              />
            ))}
            <Route
              path="*"
              element={<Navigate to={vendorPages.VendorHome.path} replace />}
            />
          </Route>
        </Routes>
      </Box>
    </>
  );
}
