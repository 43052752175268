import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import jwtDecode from "jwt-decode";
// import Color from "color";

import {
  authPages,
  // page404,
  publicPages,
  servicesPages,
  vendorPages,
  userPages,
} from "./menu";
import { COLOR_LIGHT_AMOUNT, COLOR_DARK_AMOUNT } from "constants/themeSettings";

// Components
import Aside from "./layout/Aside/Aside";
import Wrapper from "./layout/Wrapper/Wrapper";
import { Box } from "@mui/material";
import { useGetAllSettingsQuery } from "./features/settings/settingsApiSlice";
import LoadingPage from "./pages/LoadingPage";
import { useDispatch, useSelector } from "react-redux";
import { setPageLoading } from "./features/app/appSlice";
import { setCredentials } from "features/auth/authSlice";
import { useGetPortalSettingQuery } from "features/settings/portalSettingApiSlice";
import { LanguageProvider } from "hooks/useLanguage";
import { CurrencyProvider } from "hooks/useCurrency";
import ModalProvider from "contexts/modalContext";

const PALETTE = {
  primary: {
    main: "#D7A65A",
    light: "#e4eaed",
    typography: "#fff",
    gray: "#808080",
  },
  secondary: {
    light: "#ff7961",
    main: "#ffa700",
    dark: "#ba000d",
  },
  tertiary: {
    main: "#B7C1CB",
  },
  body: {
    secondary: "#f5f8fa",
  },
  //added text color here
  text: {
    secondary: "#26282B",
  },
};

// const themeUpdate = (portalSetting, mode) => {
//   createTheme({
//     palette: {
//       primary: {
//         main: portalSetting.primary_color_hex,
//         light: Color(portalSetting.primary_color_hex)
//           .lighten(COLOR_LIGHT_AMOUNT)
//           .hex(),
//         gray: "#808080",
//       },
//       secondary: {
//         main: portalSetting.secondary_color_hex,
//         light: Color(portalSetting.secondary_color_hex)
//           .lighten(COLOR_LIGHT_AMOUNT)
//           .hex(),
//         dark: Color(portalSetting.secondary_color_hex)
//           .darken(COLOR_DARK_AMOUNT)
//           .hex(),
//       },
//       text: {
//         main: portalSetting.secondary_color_hex,
//         light: Color(portalSetting.secondary_color_hex)
//           .lighten(COLOR_LIGHT_AMOUNT)
//           .hex(),
//         dark: Color(portalSetting.secondary_color_hex)
//           .darken(COLOR_DARK_AMOUNT)
//           .hex(),
//       },
//       success: {
//         main: portalSetting.success_color_hex,
//         light: Color(portalSetting.success_color_hex)
//           .lighten(COLOR_LIGHT_AMOUNT)
//           .hex(),
//         dark: Color(portalSetting.success_color_hex)
//           .darken(COLOR_DARK_AMOUNT)
//           .hex(),
//       },
//       info: {
//         main: portalSetting.info_color_hex,
//         light: Color(portalSetting.info_color_hex)
//           .lighten(COLOR_LIGHT_AMOUNT)
//           .hex(),
//         dark: Color(portalSetting.info_color_hex)
//           .darken(COLOR_DARK_AMOUNT)
//           .hex(),
//       },
//       warning: {
//         main: portalSetting.warning_color_hex,
//         light: Color(portalSetting.warning_color_hex)
//           .lighten(COLOR_LIGHT_AMOUNT)
//           .hex(),
//         dark: Color(portalSetting.warning_color_hex)
//           .darken(COLOR_DARK_AMOUNT)
//           .hex(),
//       },
//       error: {
//         main: portalSetting.error_color_hex,
//         light: Color(portalSetting.error_color_hex)
//           .lighten(COLOR_LIGHT_AMOUNT)
//           .hex(),
//         dark: Color(portalSetting.error_color_hex)
//           .darken(COLOR_DARK_AMOUNT)
//           .hex(),
//       },
//       body: {
//         secondary: portalSetting.tertiary_color_hex,
//       },
//       mode,
//     },
//     typography: {
//       fontFamily: ["Montserrat"],
//       fontSize: 12,
//     },
//   });
// };

const App = () => {
  const pageLoading = useSelector((state) => state.app.pageLoading);
  const { data: settings, isLoading: isSettingsLoading } =
    useGetAllSettingsQuery();
  const [mode, setMode] = useState("light");

  const { data: portalSetting, isLoading: isPortalSettingLoading } =
    useGetPortalSettingQuery();

  useEffect(() => {
    if (!isSettingsLoading) {
      if (settings?.data.portal_setting.is_dark_mode) {
        setMode("dark");
      } else {
        setMode("light");
      }
    }
  }, [settings]);

  const dispatch = useDispatch();
  useEffect(() => {
    const timer = setTimeout(() => dispatch(setPageLoading(-1)), 1000);
    // clean up
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    try {
      const access = localStorage?.getItem("access");
      const refresh = localStorage?.getItem("refresh");

      const user_info = jwtDecode(access);

      if (!access || !refresh) {
        return;
      }

      dispatch(
        setCredentials({
          accessToken: access,
          refreshToken: refresh,
          user: user_info.username,
          role: user_info.role_name,
          isAuth: true,
          companyName: user_info.company_name,
          companyType: user_info.company_type,
        })
      );
    } catch (err) {}
  }, []);

  const vendorPagesPath = [];
  Object.keys(vendorPages).forEach((key) =>
    vendorPagesPath.push(vendorPages[key].path)
  );
  const usersPagesPath = [];
  Object.keys(userPages).forEach((key) =>
    usersPagesPath.push(userPages[key].path)
  );

  const serviceProviderPagesPath = [];
  Object.keys(servicesPages).forEach((key) =>
    serviceProviderPagesPath.push(servicesPages[key].path)
  );

  const publicPagesPath = [];
  Object.keys(publicPages).forEach((key) =>
    publicPagesPath.push(publicPages[key].path)
  );

  const withOutAsidePages = [
    authPages.Login.path,
    authPages.RegisterAs.path,
    authPages.ForgotPassword.path,
    authPages.ResetPassword.path,
    authPages.CheckEmail.path,
    authPages.Verify.path,
    authPages.VerifySuccess.path,
    ...vendorPagesPath,
    ...publicPagesPath,
    ...serviceProviderPagesPath,
    ...usersPagesPath,
  ];

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: { ...PALETTE, mode },
        typography: {
          fontFamily: ["Montserrat"],
          fontSize: 12,
        },
      }),
    [mode]
  );
  return (
    <ThemeProvider theme={theme}>
      <ModalProvider>
        <LanguageProvider>
          <CurrencyProvider>
            {pageLoading !== 0 && <LoadingPage />}
            <Box sx={{ display: "flex", height: "100vh", width: "100%" }}>
              <Routes>
                {withOutAsidePages.map((path) => (
                  <Route key={path} path={path} />
                ))}
                <Route path="*" element={<Aside />} />
              </Routes>
              <Wrapper />
            </Box>
          </CurrencyProvider>
        </LanguageProvider>
      </ModalProvider>
    </ThemeProvider>
  );
};

export default App;
