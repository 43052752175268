import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const StyledToolbarWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "50%",
  },
}));

export const StyledInnerToolbarWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    display: "flex",
    direction: "row",
    alignItems: "center",
    width: "100%",
  },
}));
