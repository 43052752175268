import { apiSlice } from "../../app/api/apiSlice";

export const profileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: () => "users/user/getProfile/",
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: ["Profile"],
    }),
    getCompanyProfile:builder.query({
      query: () => "company/profile/",
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: ["Profile"],
    }),

    updateProfile: builder.mutation({
      query: (body) => ({
        url: "/users/user/updateProfile/",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Profile"],
    }),

    updateCompanyProfile: builder.mutation({
      query: (body) => ({
        url: "/company/profile/update/",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Profile"],
    }),
  }),
});

export const {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useUpdateCompanyProfileMutation,
  useGetCompanyProfileQuery
} = profileApiSlice;
