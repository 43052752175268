import { apiSlice } from "app/api/apiSlice";

export const personAndVehicleBadgeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateVehicleBadge: builder.mutation({
      query: (body) => ({
        url: "/portal/event/update/badge/vehicle/",
        method: "PUT",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["badge"],
    }),
    updatePersonBadge: builder.mutation({
      query: (body) => ({
        url: "/portal/event/update/badge/person/",
        method: "PUT",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["badge"],
    }),
  }),
});

export const { useUpdateVehicleBadgeMutation, useUpdatePersonBadgeMutation } =
  personAndVehicleBadgeApiSlice;
