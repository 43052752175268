import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import {
  Avatar,
  Box,
  // Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  // Modal,
  Typography,
} from "@mui/material";
import {
  adminAsidePages,
  // authPages,
  commonPages,
  // InnerPages,
  noAccessReq,
  subscribers,
} from "menu";
import { 
  // NavLink, 
  useNavigate, useLocation } from "react-router-dom";
import { FiPower } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "features/auth/authSlice";
import MultiLevelMenu, { listItemStyles } from "./components/MultiLevelMenu";
import SingleLevelMenu from "./components/SingleLevelMenu";
// import logo from "res/images/event_owl_logo.png";
import { useGetAllUserAccessQuery } from "features/permissions/permissionsApiSlice";
import { toggleMobileNav } from "features/app/appSlice";
import { useGetProfileQuery } from "features/profile/profileApiSlice";
import { useAuth } from "hooks/useAuth";
import { versionName, versionNumber } from "constants/version";
import { baseUrl } from "app/api/apiSlice";
import {
  // checkRoutePermitted,
  filterAsidePages,
} from "helpers/checkPermissions";
import { useGetAllSettingsQuery } from "features/settings/settingsApiSlice";
import { getHomeRoute } from "helpers/homeRoute";
import { useGetActiveSubscriptionPaymentQuery } from "features/subscription/subscriptionApiSlice";

export const HEADER_SIZE = "3.5rem";
export const customIconsStyle = { fontSize: "1.2rem" };

const AsideMobileWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobileNav",
})(({ theme, isMobileNav }) => ({
  position: "relative",
  top: 0,
  left: 0,
  bottom: 0,
  width: 250,
  height: "100vh",
  background: "#fafbfc",

  [theme.breakpoints.up("md")]: {},
}));

const AsideWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobileNav",
})(({ theme, isMobileNav }) => ({
  position: "relative",
  top: 0,
  left: 0,
  bottom: 0,
  width: "250px",
  height: "100vh",
  background: "#fafbfc",

  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const AsideHeaderWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: HEADER_SIZE,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

const AsideBodyWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  marginTop: HEADER_SIZE,
  position: "absolute",
  left: 0,
  top: 0,
  bottom: 0,
  borderRight: `2px solid ${theme.palette.primary.light}`,
}));

const AsideListWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  position: "absolute",
  left: 0,
  top: 0,
  height: "calc(100% - 65px)",
  overflowY: "scroll",
  overflowX: "hidden",
  flex: "1.1",
  display: "flex",
  flexDirection: "column",
  transition: "all .2s",
}));

const AsideFooterWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  height: "70px",
}));

const StyledHrLine = styled("hr")(({ theme }) => ({
  background: theme.palette.primary.light,
  color: theme.palette.primary.light,
  height: "2px",
  border: "2px solid transparent",
}));

export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  fontSize: "12px",
}));

const StyledFooter = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  lineHeight: "0.25em",
  color: theme.palette.text.secondary,
  fontSize: "0.8rem",
}));

const BuildNameWrapper = styled("div")(({ theme }) => ({
  fontSize: "0.65rem",
  fontStyle: "italic",
}));

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: "0.4em 0.5em",
  paddingLeft: "1em",
  borderRadius: "3px",
  color: theme.palette.text.secondary,
  margin: "0",
  transition: "all .2s",

  "& > *": {
    fontWeight: 500,
  },
  "&:hover,&.active": {
    background: theme.palette.primary.light,
    color: theme.palette.text.secondary,
  },
  "&.active": {
    background: theme.palette.primary.light,
  },
  "&.active > *": {
    color: theme.palette.primary.main,
  },
}));

export const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  fontSize: "1rem",
  minWidth: "2.5rem",
  paddingRight: "0",
  marginRight: "0",
  color: theme.palette.text.secondary,
}));

export const ImgLogo = styled("img")({
  height: 34,
  width: 34,
  color: "blue",
  "&:hover": {
    cursor: "pointer",
  },
});

const MenuItem = ({ item }) => {
  const { items, ...rootItem } = item;
  if (items !== undefined && item.items.constructor === Object) {
    // check if dropdown contains items, if not render nothing
    if (Object.values(items).length > 0)
      return <MultiLevelMenu item={{ ...rootItem, items }} />;
    else return <></>;
  } else {
    return <SingleLevelMenu item={item} />;
  }
};

const MenuList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { data, error, isLoading } = useGetAllUserAccessQuery();
  const { data: profile } = useGetProfileQuery();
  const { data: setting } = useGetAllSettingsQuery();
  const { data: subscription, isLoading: isSubscriptionLoading } =
    useGetActiveSubscriptionPaymentQuery();
  const companyName = profile ? profile.company.name : "";
  const [filteredMenu, setFilteredMenu] = useState({});
  const { role } = useAuth();
  const menuListItems = Object.values(filteredMenu);

  useEffect(() => {
    var filtered = filterAsidePages(data ?? [], adminAsidePages, role);
    if (role === "SUPER_ADMIN") {
      filtered = subscribers.items;
    }
    setFilteredMenu(filtered);

    if (
      data &&
      data.length === 0 &&
      !noAccessReq.includes(location.pathname) &&
      !isSubscriptionLoading
    ) {
      if (subscription === null && role === "ADMIN") {
        navigate(commonPages.SubscriptionExpired.path, { replace: true });
      } else {
        navigate("/no-permission", { replace: true });
      }
    }
  }, [data, isSubscriptionLoading, role]);

  useEffect(() => {
    if (data?.length > 0 && location.pathname === adminAsidePages.Home.path) {
      const exists = data.find(({ module_type, permissions }) => {
        if (
          module_type === adminAsidePages.Home.module &&
          permissions.includes("READ")
        ) {
          return true;
        }
        return false;
      });

      if (!exists) {
        const tempPath = getHomeRoute(Object.values(adminAsidePages), data);
        navigate(tempPath);
      }
    }
  }, [data]);

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");

    navigate("/login", { replace: true });
  };

  return (
    <>
      <AsideHeaderWrapper>
        <Avatar
          src={baseUrl + setting?.data?.system_setting?.logo?.image_large_path}
          alt="Company logo"
          sx={{ width: 36, height: 36, ml: 2 }}
        />
        <Typography
          variant={"h6"}
          fontWeight={600}
          fontSize={14}
          sx={{ color: "white", px: 1 }}
        >
          {setting?.data?.system_setting?.name}
        </Typography>
      </AsideHeaderWrapper>
      <AsideBodyWrapper>
        <AsideListWrapper>
          <List
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
                padding: "0em 0.5em",
              }}
            >
              <Box style={{ width: "100%", height: "100%" }} component="div">
                {!isLoading ? (
                  menuListItems.map((menuListItem, key) => {
                    return <MenuItem item={menuListItem} key={key} />;
                  })
                ) : (
                  <div></div>
                )}

                <ListItem disablePadding>
                  <StyledListItemButton onClick={handleLogout}>
                    <StyledListItemIcon>
                      <FiPower />
                    </StyledListItemIcon>
                    <ListItemText
                      primaryTypographyProps={listItemStyles}
                      primary="Logout"
                    />
                  </StyledListItemButton>
                </ListItem>
              </Box>
            </Box>
          </List>
        </AsideListWrapper>
        <AsideFooterWrapper
          sx={{
            px: 2,
          }}
        >
          <StyledHrLine />
          <StyledFooter>
            <p style={{ fontWeight: "600" }}>v{`${versionNumber}`}</p>
            {/* <BuildNameWrapper>Build: {`${versionName}`}</BuildNameWrapper> */}
            <BuildNameWrapper>OwlEvents</BuildNameWrapper>

          </StyledFooter>
        </AsideFooterWrapper>
      </AsideBodyWrapper>
    </>
  );
};

const Aside = () => {
  const dispatch = useDispatch();
  // track menu status
  const isMobileNav = useSelector((state) => state.app.mobileNav);

  return (
    <>
      <Drawer
        anchor="left"
        open={isMobileNav}
        onClose={() => dispatch(toggleMobileNav())}
        sx={{ display: { sm: "block", md: "none" } }}
      >
        <AsideMobileWrapper
          role="presentation"
          sx={{
            width: 250,
          }}
          isMobileNav={isMobileNav}
        >
          <MenuList />
        </AsideMobileWrapper>
      </Drawer>
      <AsideWrapper isMobileNav={isMobileNav}>
        <MenuList />
      </AsideWrapper>
    </>
  );
};

export default Aside;
