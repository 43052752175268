import { Paper } from "@mui/material";
import { styled } from "@mui/system";

export const ModalItemsContainer = styled(Paper)(
  ({ theme, width = "40rem" }) => ({
    position: "relative",
    background: "#fff",
    width: width,
    padding: "2em 3em",
    display: "flex",
    flexDirection: "column",
    overflow: "scroll",
    maxWidth: "90vw",
    maxHeight: "90vh",

    [theme.breakpoints.down("md")]: {
      width: "90vw",
      padding: "1em 1em",
    },
  })
);
