import React from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Divider,
  Paper,
  Typography,
  Box,
  Menu,
  MenuItem,
  // Button,
  ListItemIcon,
} from "@mui/material";
import { styled } from "@mui/system";
import { FiPower, FiUser, 
  // FiUsers, 
  FiHelpCircle } from "react-icons/fi";
import { adminAsidePages, InnerPages, publicPages } from "menu";
import { logout } from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineUser } from "react-icons/ai";
import { MdSettings } from "react-icons/md";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { HiOutlineMenu } from "react-icons/hi";
import { toggleMobileNav } from "../../features/app/appSlice";
import NotificationIndicator from "components/notification/NotificationIndicator";
import NotificationSnippit from "components/notification/NotificationSnippit";
import { HEADER_SIZE } from "layout/Aside/Aside";
import { commonPages } from "menu";
import {
  useGetNotificationsCountQuery,
  useGetNotificationsQuery,
} from "features/notifications/notificationsApiSlice";
import { parseFilterObj } from "helpers/filtering";
import { useGetProfileQuery } from "features/profile/profileApiSlice";

export const HeaderTopWrapper = styled(Paper)(({ theme }) => ({
  paddingLeft: "2rem",
  height: HEADER_SIZE,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  background: theme.palette.primary.main,
  borderRadius: 0,
}));

export const StyledMenuItemIcon = styled(ListItemIcon)(({ theme }) => ({
  fontSize: "1rem",
  // background: "red",
  minWidth: "2.5rem",
  paddingRight: "0",
  marginRight: "0",
  color: theme.palette.primary.main,
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.primary.main,
  "&:hover": {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
}));

export const HeaderTopLeft = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
}));

export const StyledMenuIcon = styled(HiOutlineMenu)(({ theme }) => ({
  display: "none",
  color: "#fff",
  fontSize: "2rem",

  "&:hover": {
    cursor: "pointer",
  },
  [theme.breakpoints.down("md")]: {
    display: "block",
  },
}));

export const HeaderTopRight = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-end",
  justifyContent: "end",
  paddingRight: "1em",
}));

const User = () => {
  const { data: profile, isLoading: isProfileLoading } = useGetProfileQuery();
  const user = profile ? profile.username : "Unknown";

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");

    navigate("/login", { replace: true });
  };
  return (
    <>
      <div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <StyledMenuItem
            onClick={handleClose}
            component={Link}
            to={InnerPages.Profile.path}
            sx={{ fontWeight: 500 }}
          >
            <StyledMenuItemIcon>
              <AiOutlineUser />
            </StyledMenuItemIcon>
            Profile
          </StyledMenuItem>
          <StyledMenuItem
            onClick={handleClose}
            component={Link}
            to={adminAsidePages.Settings.path}
          >
            <StyledMenuItemIcon>
              <MdSettings />
            </StyledMenuItemIcon>
            Settings
          </StyledMenuItem>
          <StyledMenuItem
            onClick={handleClose}
            component={Link}
            to={publicPages.Help.path}
          >
            <StyledMenuItemIcon>
              <FiHelpCircle />
            </StyledMenuItemIcon>
            Help
          </StyledMenuItem>
          <StyledMenuItem onClick={handleLogout}>
            <StyledMenuItemIcon>
              <FiPower />
            </StyledMenuItemIcon>
            Logout
          </StyledMenuItem>
        </Menu>
      </div>
      <Box
        // component={Link}
        // to={InnerPages.Profile.path}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          cursor: "pointer",
          textDecoration: "none",
        }}
      >
        <Typography
          variant={"h6"}
          fontWeight={600}
          fontSize={14}
          sx={{ color: "white" }}
        >
          {user}
        </Typography>
        <Box
          component="div"
          sx={{
            color: "primary.main",
            display: "flex",
            alignItems: "center",
            ml: 0.7,
          }}
        >
          <FiUser
            style={{
              fontSize: "16px",
              color: "#fff",
            }}
          />
          <Divider
            color="#fff"
            textAlign="center"
            flexItem={true}
            sx={{ fontSize: "16px" }}
          />
          <KeyboardArrowDownIcon sx={{ color: "white", fontSize: "16px" }} />
        </Box>
      </Box>
    </>
  );
};

const HeaderTop = () => {
  const dispatch = useDispatch();
  const isMobileNav = useSelector((state) => state.app.mobileNav);

  const { data: notificationsRaw } = useGetNotificationsQuery({
    pagination: { pageIndex: 0, pageSize: 5 },
    searchValue: null,
    filters: parseFilterObj({
      order_by: "date_created",
      order_by_clause: "DESC",
      status: "NOT_OPENED",
    }),
  });
  const notifications = notificationsRaw ? notificationsRaw.data : [];
  const { data: notificationsCountRaw } = useGetNotificationsCountQuery();
  const notificationsCount = notificationsCountRaw
    ? notificationsCountRaw?.not_opened_notifications_count
    : 0;

  return (
    <HeaderTopWrapper elevation={0}>
      <HeaderTopLeft>
        {!isMobileNav && (
          <StyledMenuIcon onClick={() => dispatch(toggleMobileNav())} />
        )}
      </HeaderTopLeft>
      <HeaderTopRight>
        <NotificationSnippit
          data={notifications}
          notificationsPath={commonPages.Notifications.path}
        >
          <NotificationIndicator count={notificationsCount} />
        </NotificationSnippit>

        <User />
      </HeaderTopRight>
    </HeaderTopWrapper>
  );
};

export default HeaderTop;
