import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import { Collapse, TextField } from "@mui/material";

const GlobalSearch = (props) => {
  const {
    setShowSearch,
    showSearch,
    setSearchValue,
    searchValue,
    isCustomComp = false,
    title = "",
    placeholderTitle = "",
    children,
  } = props;

  const handleToggleSearch = () => {
    setShowSearch(!showSearch);
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    queueMicrotask(() => setSearchValue(query));
  };

  if (isCustomComp) {
    return (
      <>
        {children({
          searchValue,
          handleSearch,
          setShowSearch,
          title,
          placeholderTitle,
        })}
      </>
    );
  }

  return (
    <>
      <Collapse in={showSearch} orientation="horizontal">
        <TextField
          id="search"
          name="search"
          label="Search"
          variant="standard"
          onChange={handleSearch}
          value={searchValue}
        />
      </Collapse>

      <Tooltip arrow title={"Search from all data"}>
        <IconButton onClick={handleToggleSearch}>
          {showSearch ? <SearchOffIcon /> : <SearchIcon />}
        </IconButton>
      </Tooltip>
    </>
  );
};

export default GlobalSearch;
