import { styled } from "@mui/system";
import { Box, ListItemIcon, Typography } from "@mui/material";
import { Icon } from "@mui/material";

export const StyledHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: "1rem",
  background: "yellow",
}));

export const StyledHeaderVarient = styled(Box)(({ theme }) => ({
  // marginTop: "2rem",
  display: "flex",
  gap: "6rem",
  alignItems: "center",
  paddingBottom: "1rem",
}));

// export const StyledBackButton = styled(Icon)(({ theme }) => ({
//   marginLeft: 16,
//   color: theme.palette.primary.main,
//   "&:hover": {
//     cursor: "pointer",
//   },
// }));

export const StyledHeaderIcon = styled(ListItemIcon)(({ theme }) => ({
  marginLeft: 16,
  fontSize: "24px",
  color: theme.palette.primary.main,
}));
export const StyledText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  "&:hover": {
    cursor: "pointer",
    color: 'black',

  },
}));