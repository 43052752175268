import { useState } from "react";

export const usePagination = (data = null) => {
  let pageIndex = 0;
  let pageSize = 25;
  if (data) {
    pageIndex = data.pagination.pageIndex;
    pageSize = data.pagination.pageSize;
  }
  const [pagination, setPagination] = useState({
    pageIndex,
    pageSize,
  });

  return [pagination, setPagination];
};
