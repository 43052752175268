import React from "react";
import { Box, Divider, Menu, Typography } from "@mui/material";

import NotificationMenuItem, { StyledMenuItem } from "./NotificationMenuItem";
import { Navigate, useNavigate } from "react-router-dom";

const NotificationSnippit = ({ data, notificationsPath, children }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        id="basic-notification-button"
        aria-controls={open ? "basic-notification-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          textDecoration: "none",
        }}
      >
        {children}
      </Box>

      <Menu
        id="basic-notification-menu"
        aria-labelledby="basic-notification-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ maxWidth: "90vw", width: 450, mt: 1 }}
      >
        <Box sx={{ maxWidth: "90vw", width: 450 }}>
          {data.map((item) => (
            <>
              <NotificationMenuItem
                key={item.id}
                notification={item}
                notificationsPath={notificationsPath}
                handleClick={() => {}}
                allowDelete
              />
              <Divider />
            </>
          ))}
          <StyledMenuItem
            sx={{
              display: "flex",
              justifyContent: "center",
              px: 2,
              py: 2,
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(notificationsPath);
              handleClose();
            }}
          >
            <Typography
              variant="p"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              fontWeight={500}
              fontSize={14}
            >
              {`See All Notifications >`}
            </Typography>
          </StyledMenuItem>
        </Box>
      </Menu>
    </>
  );
};

export default NotificationSnippit;
