import {
  Avatar,
  Box,
  Grid,
  Stack,
  Typography,
  useTheme,
  Fade,
  TextField,
} from "@mui/material";
import ZoomImage from "components/ZoomImage";
import { apiUrl } from "constants/api";
import { useField } from "formik";
import React, { useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import useGetData from "hooks/useGetData";
import { useGetSpacesPublicQuery } from "features/spaces/spacesApiSlice";

import Pagination from "components/Pagination";

const Space = ({ id, space, price, selected = false, setValue, field }) => {
  const theme = useTheme();
  console.log("colors: ", theme);

  return (
    <div>
      <Box
        onClick={() => {
          if (field.value === id) {
            setValue("");
          } else {
            setValue(id);
          }

          selected = !selected;
        }}
        sx={{
          border: `1px solid ${theme.palette.grey["200"]}`,
          borderRadius: "5px",
          cursor: "pointer",
          p: 2,
          "&:hover": {
            background: theme.palette.grey["100"],
          },
          maxHeight: "300px",
          background: selected ? theme.palette.grey["50"] : "transparent",
          position: "relative",
        }}
      >
        <Box
          sx={{
            height: 25,
            width: 25,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            right: 10,
            top: 10,
            background: theme.palette.primary.main,
            borderRadius: "7px",
          }}
        >
          <Fade in={selected}>
            <CheckIcon sx={{ height: 20, width: 20, color: "#fff" }} />
          </Fade>
        </Box>

        <Stack
          direction="row"
          spacing={2}
          sx={{ height: 100, overflowY: "hidden" }}
        >
          <Avatar variant="square" sx={{ width: 100, height: 100 }}>
            <ZoomImage
              src={`${apiUrl}${space?.floor_plan_image?.image_large_path}`}
              width={100}
              height={100}
            />
          </Avatar>
          <Stack spacing={1} width={100}>
            <Typography color={theme.palette.text.secondary} fontWeight={500}>
              {space.name}
            </Typography>
            <Typography color={theme.palette.text.secondary}>
              {/* <Typography sx={{ display: "inline-block", fontWeight: 500 }}>
              $ &nbsp;{price}
            </Typography> */}
            </Typography>
            <Typography color={theme.palette.text.secondary}>
              {space.space_type}
            </Typography>
          </Stack>
        </Stack>
        <Box mt={2} sx={{ height: 50, overflowY: "hidden" }}>
          {" "}
          <Typography variant="body" color={theme.palette.text.secondary}>
            <LocationOnIcon
              sx={{
                height: 16,
                width: 16,
                color: theme.palette.text.secondary,
              }}
            />{" "}
            {space.location.name}
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

const SelectSpaces = ({ name, id }) => {
  const [field, meta, helpers] = useField(name);
  const [searchQuery, setSearchQuery] = useState("");

  const {
    data: spaces,
    setPagination,
    pagination,
    rowCount,
    setShowSearch,
    setSearchValue,
  } = useGetData()(useGetSpacesPublicQuery, {
    event_id: id,
  });
  const handlePageChange = (pageIndex) => {
    setPagination({ pageIndex, pageSize: 8 });
  };

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: 8 });
  }, []);
  const handleSearch = (query) => {
    setSearchQuery(query);
    setShowSearch(true);
    setSearchValue(query);
    setPagination({ pageIndex: 0, pageSize: 8 });
  };
  useEffect(() => {
    if (searchQuery.trim() === "") {
      setShowSearch(false);
      setPagination({ pageIndex: 0, pageSize: 8 });
    }
  }, [searchQuery]);
  return (
    <Box>
      <Grid minHeight={"30rem"} container spacing={2}>
        <Grid item xs={12}>
          <TextField
            onChange={(e) => handleSearch(e.target.value)}
            label={"Search"}
          />
        </Grid>
        {spaces?.map((item) => (
          <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
            <Space
              id={item.id}
              space={item.space}
              price={item.price}
              selected={item.id === field.value}
              setValue={helpers.setValue}
              field={field}
            />
          </Grid>
        ))}
      </Grid>
      {helpers.error && helpers.touched ? (
        <Typography color="error" variant="p2" sx={{ mt: 1 }}>
          {helpers.error}
        </Typography>
      ) : (
        <></>
      )}

      <Pagination
        currentPage={pagination.pageIndex} // Adjust as needed to start from page 1
        totalPages={Math.ceil(rowCount / 8)} // Calculate the total pages based on the totalCount
        onPageChange={handlePageChange}
      />
    </Box>
  );
};

export default SelectSpaces;
