import { apiSlice } from "../../app/api/apiSlice";

export const paymentPlanApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentsAdmin: builder.query({
      query: (query) => {
        const { pagination, searchValue } = query;
        const { pageIndex, pageSize } = pagination;
        const searchQuery = searchValue ? `&query=${searchValue}` : "";

        return {
          url: `/company/payment_plans/?page=${pageIndex}&page_size=${pageSize}${searchQuery}`,
        };
      },
      transformResponse: (response) => {
        const { page_metadata, data } = response.data;

        return {
          data,
          page_metadata,
        };
      },
      providesTags: ["PaymentPlan"],
    }),
    createPaymentPlan: builder.mutation({
      query: (body) => ({
        url: "/company/payment_plan/create/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["PaymentPlan"],
    }),
    createPaymentPlanOption: builder.mutation({
      query: (body) => ({
        url: "/su/payment_plan/option/create/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["PaymentPlan"],
    }),
    updatePaymentPlan: builder.mutation({
      query: (body) => ({
        url: "/company/payment_plan/update/",
        method: "PUT",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["PaymentPlan"],
    }),
    updatePaymentPlanOption: builder.mutation({
      query: (body) => ({
        url: "/su/payment_plan/option/update/",
        method: "PUT",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["PaymentPlan"],
    }),
    deletePaymentPlan: builder.mutation({
      query: (body) => ({
        url: "/company/payment_plan/delete/",
        method: "DELETE",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["PaymentPlan"],
    }),
    deletePaymentPlanOption: builder.mutation({
      query: (body) => ({
        url: "/su/payment_plan/option/delete/",
        method: "DELETE",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["PaymentPlan"],
    }),
    getAllPaymentPlan: builder.query({
      query: (query) => {
        return {
          url: `/company/payment_plans/pub/`,
        };
      },
      transformResponse: (response) => {
        const { page_metadata, data } = response;
        return {
          data,
          page_metadata,
        };
      },
      providesTags: ["PaymentPlan"],
    }),
    getPaymentPlanOptions: builder.query({
      query: (query) => {
        const { pagination, searchValue, filters } = query;
        const { pageIndex, pageSize } = pagination;
        const searchQuery = searchValue ? `&query=${searchValue}` : "";
        return {
          url: `su/payment_plan/options/?page=0&page_size=20${searchQuery}${filters}`,
        };
      },

      transformResponse: (response) => {
        return response.data;
      },
      providesTags: ["PaymentPlan"],
    }),
    getPaymentMethods: builder.query({
      query: (query) => {
        return {
          url: `/company/payment_methods/`,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
    }),
    subscribe: builder.mutation({
      query: (body) => ({
        url: "/company/subscribe/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
    }),
    tokenSubscription: builder.mutation({
      query: (body) => ({
        url: "/company/subscribe/token/",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetAllPaymentPlanQuery,
  useGetPaymentMethodsQuery,
  useSubscribeMutation,
  useGetPaymentsAdminQuery,
  useCreatePaymentPlanMutation,
  useGetPaymentPlanOptionsQuery,
  useUpdatePaymentPlanMutation,
  useCreatePaymentPlanOptionMutation,
  useDeletePaymentPlanOptionMutation,
  useUpdatePaymentPlanOptionMutation,
  useDeletePaymentPlanMutation,
  useTokenSubscriptionMutation,
} = paymentPlanApiSlice;
