import React from "react";
import { Paper, Box, Modal } from "@mui/material";

import {
  StyledAuthServiceProviderBackground,
  StyledLoginContainer,
  StyledLoginItemsContainer,
  StyledOpacityBackground,
  StyledWrapper,
} from "./Styles";

const ServicesProviderWrapper = ({ children }) => {
  return (
    <>
      <StyledAuthServiceProviderBackground />
      <Modal open>
        <StyledWrapper container component="main">
          <StyledLoginContainer
            item
            xs={12}
            sm={6}
            md={5}
            lg={3.7}
            xl={2.5}
            component={Paper}
            elevation={1}
            square
            sx={{ mx: 2 }}
          >
            <StyledLoginItemsContainer sx={{ width: "85%" }}>
              {children}
            </StyledLoginItemsContainer>
          </StyledLoginContainer>
        </StyledWrapper>
      </Modal>
    </>
  );
};

export default ServicesProviderWrapper;
