import { Form, Formik } from "formik";

import {
  SubmitButton,
  FormikTextField,
  FormikPhoneField,
  FormikPasswordField,
} from "../userComponents";
import * as Yup from "yup";
import { subYears } from "date-fns";
import { startOfDay } from "date-fns/esm";
import {
  Typography,
  Stack,
  Card,
  Box,
  Link,
  Grid,
  Button,
} from "@mui/material";
import { useState } from "react";
import { StyledLink } from "pages/auth/Login";
import {
  useSendOtpMutation,
  useResetPasswordMutation,
} from "features/auth/authApiSlice";
import { useNavigate } from "react-router-dom";

const UserForgotPassword = () => {
  const [sendOtp, { isLoading, isSuccess, isError }] = useSendOtpMutation();
  const [resetPassword, { isResetPassLoading, errorData }] =
    useResetPasswordMutation();
  const navigate = useNavigate();

  const [error, setError] = useState("");

  const minDate = subYears(startOfDay(new Date()), 18);
  const [activeStep, setActiveStep] = useState(0);
  const [codeSentTo, setCodeSentTo] = useState("");
  const [useEmail, setUseEmail] = useState(true);
  const forgotPasswordSchema = Yup.object().shape({
    email: useEmail
      ? Yup.string().required("Email is required").email("Enter a valid email")
      : Yup.string().nullable(),
    phone_number: useEmail
      ? Yup.string().nullable()
      : Yup.string().required("Phone number is required"),
    otp:
      activeStep !== 1
        ? Yup.string().nullable()
        : Yup.string()
            .required("OTP is required")
            .min(6, "OTP must be at least 6 digits"),
    // .min(100000, "OTP must be at least 6 digits"),
    password:
      activeStep !== 1
        ? Yup.string().nullable()
        : Yup.string()
            .required("Password is required")
            .min(6, "Password must be at least 6 characters"),
    confirm_password:
      activeStep !== 1
        ? Yup.string().nullable()
        : Yup.string()
            .required("Confirm password is required")
            .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    // setSubmitting(true);
    setError("");
    if (activeStep === 0) {
      const form = new FormData();
      form.append(
        "identifier",
        useEmail ? values?.email : values?.phone_number.replace(/\s/g, "")
      );
      const res = await sendOtp(form);
      if (res?.error) {
        // setErrors(res?.error.data.message);
        // setLoading(false);
        setError(res?.error.data.message);
        throw new Error(res.error.data.message);
      }
      if (!res.error) {
        setActiveStep(1);
        setCodeSentTo(useEmail ? values.email : values.phone_number);
        // Perform validation and submission logic for the first step
        setSubmitting(true);
      }
      // Example: login logic
    } else {
      // setActiveStep(2);
      // // Perform validation and submission logic for the second step
      // setSubmitting(true);
      const form = new FormData();
      // form.append(
      //   "identifier",
      //   useEmail ? values?.email : values?.phone_number.replace(/\s/g, "")
      // );
      form.append("password", values.password);
      form.append("re_password", values.confirm_password);
      form.append("otp_code", values.otp);

      const res = await resetPassword(form);
      if (res?.error) {
        // setErrors(res?.error.data.message);
        // setLoading(false);
        setError(res?.error.data.message);
        throw new Error(res.error.data.message);
      }
      if (!res.error) {
        navigate(`/users-web/login`, { replace: true });
        // Perform validation and submission logic for the first step
        setSubmitting(true);
      }
    }
  };
  const handleBack = () => {
    if (activeStep !== 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <Typography sx={{ color: "black", fontWeight: "900", my: 2 }}>
              Forgot Password
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "end", my: 1 }}>
              <Typography
                textAlign={{ sm: "end" }}
                style={{
                  border: "none",
                  background: "none",
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "black",
                }}
                variant="span"
                onClick={() => setUseEmail(!useEmail)}
              >
                {useEmail ? "Use Phone" : "Use Email"}
              </Typography>
            </Box>
            {useEmail ? (
              <FormikTextField
                fullWidth
                margin="dense"
                label="Email Address"
                name="email"
                id="email"
              />
            ) : (
              <FormikPhoneField
                fullWidth
                margin="dense"
                label="Phone Number"
                name="phone_number"
                id="phone_number"
              />
            )}
          </>
        );
      case 1:
        // OTP Verification Step
        return (
          <>
            <Box sx={{ my: 3 }}>
              <Box sx={{ mb: 1 }}>
                <Typography sx={{ color: "#646566" }} variant="body2">
                  Phone Verification
                </Typography>
                <Typography
                  style={{
                    color: "black",
                    fontWeight: "900",
                    mb: 2,
                  }}
                  variant="h6"
                >
                  Enter Your OTP code
                </Typography>
              </Box>
              <Typography
                style={{
                  color: "#646566",
                }}
                variant="body2"
              >
                Enter the 6-digit code sent to you at {codeSentTo}
              </Typography>
            </Box>
            <FormikTextField
              fullWidth
              margin="dense"
              label="OTP"
              name="otp"
              id="otp"
            />
            <FormikPasswordField
              // required
              fullWidth
              name="password"
              id="password"
              label="Password"
            />
            <FormikPasswordField
              // required
              fullWidth
              name="confirm_password"
              id="confirm_password"
              label="Confirm Password"
            />
          </>
        );

      default:
        return "Unknown step";
    }
  };
  return (
    <>
      {/* <BodyWrapper> */}
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          // {/* <Card */}
          sx={{
            py: 2,
            px: 4,
            minWidth: { xs: "90vw", md: "65vw", lg: "35vw" },
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              width={64}
              height={64}
              src={
                "https://dev.api.event.testserver.awuraplc.org/image/image/system_setting_logo/large/24591df5890f48b3834ed38d50565c15.png"
              }
              alt="Owl Events"
              sx={{ m: 1 }}
            />
          </Stack>

          <Formik
            initialValues={{
              email: "",
              phone_number: "",
              otp: "",
              password: "",
              confirm_password: "",
            }}
            validationSchema={forgotPasswordSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Box component={Form} noValidate width="100%">
                {getStepContent(activeStep)}
                {error && (
                  <Typography sx={{ color: "red" }}>{error}</Typography>
                )}
                <Box
                  sx={{
                    alignSelf: "flex-end",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    my: 2,
                  }}
                >
                  <Grid container alignItems={"center"}>
                    <Grid item xs={6}>
                      <Button onClick={handleBack} disabled={activeStep === 0}>
                        Back
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      {activeStep === 0 && (
                        <SubmitButton
                          text={"Send OTP"}
                          isSubmitting={isLoading}
                        />
                      )}
                      {activeStep === 1 && (
                        <SubmitButton
                          text={"Change Password"}
                          isSubmitting={isResetPassLoading}
                        />
                      )}
                    </Grid>
                  </Grid>

                  {activeStep === 0 && (
                    <StyledLink
                      sx={{
                        fontStyle: "normal",
                        fontFamily: "Comfortaa",
                        fontSize: 16,
                        textDecoration: "none",
                        alignSelf: "flex-start",
                        color: "#DEA449",
                        fontWeight: "900",
                        cursor: "pointer",
                        mt: 1,
                      }}
                      href={`/login`}
                    >
                      Do you have an account? Login
                    </StyledLink>
                  )}
                </Box>
              </Box>
            )}
          </Formik>
        </Card>
        {/* </Card> */}
      </Box>
      {/* </BodyWrapper> */}
    </>
  );
};

export default UserForgotPassword;
