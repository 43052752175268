import { apiSlice } from "../../app/api/apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (query) => {
        const { pagination, searchValue, filters } = query;
        const { pageIndex, pageSize } = pagination;
        const searchQuery = searchValue ? `&query=${searchValue}` : "";
        return {
          url: `/portal/users/Users/?page=${pageIndex}&page_size=${pageSize}${searchQuery}${filters}`,
        };
      },
      transformResponse: (response) => {
        const { page_metadata, data } = response.data;

        const users = data.map((item) => ({
          ...item,
          username: item.username,
          role_name: item.role.name,
          company_name: item?.company?.name,
          full_name: `${item.first_name} ${item.last_name}`,
        }));

        return {
          page_metadata,
          data: users,
        };
      },
      providesTags: ["User"],
    }),
    getSubscribers: builder.query({
      query: (query) => {
        const { pagination, searchValue, filters } = query;
        const { pageIndex, pageSize } = pagination;
        const searchQuery = searchValue ? `&query=${searchValue}` : "";
        return {
          url: `su/users/public/?page=${pageIndex}&page_size=${pageSize}&order_by=date_of_birth&order_by_clause=ASC${searchQuery}${filters}`,
        };
      },
      transformResponse: (response) => {
        const { page_metadata, data } = response.data;

      

        return {
          page_metadata,
          data: data,
        };
      },
      providesTags: ["User"],
    }),

    getShareUsers: builder.query({
      query: (arg) =>
        `/portal/users/getShareEventUsers/?page=0&page_size=10${arg}`,
      transformResponse: (res) => {
        return res.data.data;
      },
    }),

    createUser: builder.mutation({
      query: (body) => ({
        url: "/portal/users/addUser/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["User"],
    }),

    updateUser: builder.mutation({
      query: (userData) => ({
        url: `/portal/users/editUser/${userData.id}/`,
        method: "PUT",
        body: userData,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["User"],
    }),

    deleteUser: builder.mutation({
      query: (body) => ({
        url: `/portal/users/Users/deleteUser/`,
        method: "DELETE",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["User"],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetSubscribersQuery,
  useGetShareUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = userApiSlice;
