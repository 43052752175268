import React, { useState } from "react";
import { 
  Box, 
  Button, 
  // Container
 } from "@mui/material";
import { useModal } from "contexts/modalContext";
import EnhancedDataTable from "components/EnhancedDataTable";
import DefaultSnackbar from "components/DefaultSnackbar";
import useGetData from "hooks/useGetData";
import { useDeleteVendorRequestMutation, useGetVendorEventRequestsQuery } from "features/vendorEvent/vendorEventApiSlice";
import CheckoutModal from "./Checkout/CheckoutModal";
import PaymentIcon from "@mui/icons-material/Payment";
import VendorRequestsHeader from "layout/Header/adminPagesHeader/VendorRequestsHeader";
import { RemoveCircle } from "@mui/icons-material";
import ConfirmDeleteModal from "components/formModals/ConfirmDeleteModal";



const VendorRequests = () => {
  const { showModal, toggleModal } = useModal();
  const [modalData, setModalData] = useState({
    open: false,
    id: "",
    price: "",
    name: "",
  });
  const headCells = [
    {
      id: "event_name",
      numeric: false,
      disablePadding: true,
      label: "Event",
    },
    {
      id: "space_name",
      numeric: false,
      disablePadding: true,
      label: "Space name",
    },
    {
      id: "space_price",
      numeric: true,
      disablePadding: true,
      label: "Price",
    },
    {
      id: "space_type",
      numeric: false,
      disablePadding: true,
      label: "Type",
    },
    {
      id: "space_location_name",
      numeric: false,
      disablePadding: true,
      label: "location",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: true,
      label: "Organizer response",
    },
    {
      id: "order",
      numeric: false,
      disablePadding: false,
      label: "Order ID",
    },
    {
      id: "payment_status",
      numeric: false,
      disablePadding: true,
      label: "Payment status",
    },
    {
      id: "payment",
      numeric: false,
      disablePadding: true,
      label:'Payment',
      Cell: ({ cell, row }) => ( <Button disabled={ row.original.payment?.status === "SUCCESS" ||
      row.original.status === "PENDING" ||
      row.original.status === "REJECTED"} variant="contained"  sx={{ m: 1 }}  onClick= {() => {
        setModalData({
          open: true,
          id: row.id,
          price: row.original.space_price,
          name: row.original.space_name,
        })}}
      >{row.original.payment?.status === "SUCCESS"? 'PAID': ( row.original.status === "PENDING" ? 'PENDING': (row.original.status === "REJECTED"? 'REJECTED':'Complete Payment'))   }</Button>
     
     
    )
     
    },
  ];
  const {
    isLoading,
    pagination,
    setPagination,
    rowCount,
    data,
    searchValue,
    setSearchValue,
    showSearch,
    setShowSearch,
    // refetch,
  } = useGetData(true)(useGetVendorEventRequestsQuery);
  const [snackOptions, setSnackOptions] = useState({
    open: false,
    text: "",
  });
  const [deleteVendorRequest, { isVendorRequestLoading }] = useDeleteVendorRequestMutation();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOptions({
      open: false,
    });
  };

  const deleteHandler = async (id, password) => {
    const formData = new FormData();
    formData.append("vendor_event_request_id", id);
    const res = await deleteVendorRequest(formData);
    if (res?.error) {
      throw new Error(res.error.data.message);
    }
   
  };
  const actions = [
    {
      icon: <></>,
      onClick: (id, row) => {
        toggleModal("modal3", {
          action: "delete",
          id,
        });
      },
      color: () => "red",
      isDisabled: (row) =>
       row.status != "PENDING",

      tooltip: (row) => {
        return "Cancel Request";
      },
    },
  ];

  return (
    <Box sx={{ width: "96%", overflowX: "hidden", py: 4 }}>
      <VendorRequestsHeader />
      <EnhancedDataTable
        headCells={headCells}
        isLoading={isLoading}
        rows={data}
        rowCount={rowCount}
        manualPagination={true}
        pagination={pagination}
        onPaginationChange={setPagination}
        actions={actions}
        // toolbarActions={<ToolbarActions />}
        allowExport={false}
        showIcon={false}
        editIcon={false}
        deleteIcon={false}
        searchProps={{ searchValue, setSearchValue, showSearch, setShowSearch }}
      />

      <CheckoutModal modalData={modalData} setModalData={setModalData} />
      {showModal.modal3 && (
        <ConfirmDeleteModal
          text="Cancel Request? "
          action={deleteHandler}
          onSucceed={() => {
            setSnackOptions({
              open: true,
              text: "Successfully Removed your Request",
            });
          }}
        />
      )}
      <DefaultSnackbar options={snackOptions} handleClose={handleClose} />
    </Box>
  );
};

export default VendorRequests;
