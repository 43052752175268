import { format } from "date-fns";

export const getProperDate = (date) => {
  return format(date, "yyyy-MM-dd");
};

export const getParsedDate = (date) => {
  try {
    return format(date, "PP");
  } catch (error) {
    return "error";
  }
};

export const getParsedTime = (date) => {
  try {
    return format(date, "p");
  } catch (error) {
    return "error";
  }
};
