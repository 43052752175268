import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import ProtectedRoutes from "routes/ProtectedRoutes";
import ServiceProvidersLogin from "pages/serviceProviders/ServiceProvidersLogin";
// import VendorHome from "pages/vendors/VendorHome";
// import VendorEventDetail from "pages/vendors/VendorEventDetail";
// import VendorRequests from "pages/vendors/VendorRequests";
import {
  // vendorPages,
  servicesPages,
  userPages,
} from "menu";
import VendorProfile from "pages/vendors/VendorProfile";
import VendorNotifications from "pages/vendors/VendorNotifications";
// import VendorAppBar from "../VendorRoutes/VendorAppBar";
import Services from "pages/serviceProviders/ServiceProvidersHome";
import ServiceProviderDetail from "pages/serviceProviders/ServiceProviderDetail/ServiceProviderDetail";
import ServiceProviderAppBar from "./ServiceProviderAppBar";
import ServiceProviderRequests from "pages/serviceProviders/ServiceProviderRequest/ServiceProviderRequest";

const routes = [
  {
    path: servicesPages.ServicesHome.path,
    exact: true,
    element: <Services />,
  },
  {
    path: servicesPages.ServicesEventDetail.path,
    exact: true,
    element: <ServiceProviderDetail />,
  },
  {
    path: servicesPages.ServicesRequests.path,
    exact: true,
    element: <ServiceProviderRequests />,
  },
  {
    path: servicesPages.ServicesProfile.path,
    exact: true,
    element: <VendorProfile />,
  },
  {
    path: servicesPages.ServicesNotifications.path,
    exact: true,
    element: <VendorNotifications />,
  },
];

const noAsideRoutes = [servicesPages.ServicesLogin.path];

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function ServicesNav() {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      {!noAsideRoutes.includes(location.pathname) && (
        <ServiceProviderAppBar open={open} setOpen={setOpen} />
      )}

      <Box
        width={"100%"}
        sx={{
          py: !noAsideRoutes.includes(location.pathname) ? 1 : 0,
          px: !noAsideRoutes.includes(location.pathname) ? 2 : 0,
        }}
      >
        {!noAsideRoutes.includes(location.pathname) && <DrawerHeader />}
        <Routes>
          <Route
            path={servicesPages.ServicesLogin.path}
            exact
            element={<ServiceProvidersLogin />}
          />
          <Route
            element={
              <ProtectedRoutes
                redirectPath={servicesPages.ServicesLogin.path}
              />
            }
          >
            {routes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                exact={route.exact}
                element={route.element}
              />
            ))}
            <Route
              path="*"
              element={
                <Navigate to={servicesPages.ServicesLogin.path} replace />
              }
            />
          </Route>
        </Routes>
      </Box>
    </>
  );
}
