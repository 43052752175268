import React from "react";
import Zoom from "react-medium-image-zoom";

const DisplayImage = (props) => {
  const { height, width, src, alt = "Image" } = props;
  return (
    <Zoom zoomMargin={2}>
      <img
        style={{
          margin: 0,
          width,
          height,
          cursor: "pointer",
        }}
        src={src}
        alt={alt}
      />
    </Zoom>
  );
};

export default DisplayImage;
