import { apiSlice } from "app/api/apiSlice";

export const settingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllSettings: builder.query({
      query: () => "/portal/setting/",
      transformResponse: (response) => {
        const { app_setting, portal_setting, system_setting, api_key_setting } =
          response.data;
        return {
          data: {
            app_setting,
            portal_setting,
            system_setting,
            api_key_setting,
          },
        };
      },
      providesTags: ["Settings"],
    }),
    getSettingsWithoutAuth: builder.query({
      query: () => {
        return {
          url: `/setting/public_web/`,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
    //   providesTags: ["Vendors"],
    }),
    updateSettings: builder.mutation({
      query: (body) => ({
        url: "/portal/setting/update/",
        method: "PUT",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Settings"],
    }),
    patchSettings: builder.mutation({
      query: (body) => ({
        url: "/portal/setting/update/",
        method: "PATCH",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Settings"],
    }),
    getApiKey: builder.query({
      query: () => "/portal/setting/api_key/",
      transformResponse: (response) => {
        return response.data.google_map_key;
      },
      providesTags: ["Settings"],
    }),
    getTimezones: builder.query({
      query: () => "/portal/setting/all_timezones/",
      method: "GET",
      transformResponse: (response) => {
        return response.data.timezones;
      },
    }),
    getServerTime: builder.query({
      query: (timestamp) =>
        `/portal/setting/server_time/?timestamp=${timestamp}`,
      method: "GET",
      transformResponse: (response) => {
        return response.data;
      },
    }),
  }),
});

export const {
  useGetAllSettingsQuery,
  useGetSettingsWithoutAuthQuery,
  useUpdateSettingsMutation,
  useGetApiKeyQuery,
  useGetTimezonesQuery,
  useGetServerTimeQuery,
  usePatchSettingsMutation
} = settingsApiSlice;
