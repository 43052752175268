import { companyType } from "constants/companyTypes";
import { setRedirect } from "features/routing/routingSlice";
import jwtDecode from "jwt-decode";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const ProtectedRoutes = ({ redirectPath }) => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  let isLogged = auth.isAuth;

  // check if there is a logged in user, check if it's vendor
  const access = localStorage.getItem("access");
  if (access) {
    try {
      const userData = jwtDecode(access);
      if (userData.company_type === companyType.vendor) {
        isLogged = true;
      } else if (userData.company_type === companyType.service_provider) {
        isLogged = true;
      } else if (userData.username) {
        isLogged = true;
      } else isLogged = false;
    } catch (e) {
      // invalid token
    }
  }

  if (!isLogged) {
    // const error = new Error(`Please log in to access this route`);
    dispatch(
      setRedirect({ redirectPath: location.pathname + location.search })
    );
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

ProtectedRoutes.defaultProps = {
  redirectPath: "/login",
};

export default ProtectedRoutes;
