import {createSlice} from "@reduxjs/toolkit";

const invitationSlice = createSlice({
    name: 'invitations',
    initialState:{
        invitations:[],
        selectedInvitations:[],
        editId:-1 //current selected item for editing
    },
    reducers:{
        addInvitation:(state, action)=>{
            const id = action.payload
            if(state.invitations.indexOf(id) === -1) {
                state.invitations.push(id)
            }
        },
        removeInvitation:(state,action)=>{
            const id = action.payload
            state.invitations = state.invitations.filter(item => item.id !== id)
        },
        setEditId:(state,action)=>{
            state.editId = action.payload
        }
    }
})

export const  {addInvitation, removeInvitation, setEditId} = invitationSlice.actions

// const selectInvitations = state => state.invitations.invitations

// const selectSelectedInvitations = state => state.invitations.selectedInvitations

export const selectEditId = state => state.invitations.editId

export default invitationSlice.reducer