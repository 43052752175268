import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import EventNoteIcon from "@mui/icons-material/EventNote";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

export const side1 = [
  {
    text: "Home",
    icon: <HomeOutlinedIcon />,
    path: "/services-web",
  },
  {
    text: "Requests",
    icon: <EventNoteIcon />,
    path: "/services-web/requests",
  },
];

export const side2 = [
  {
    text: "Profile",
    icon: <PersonOutlineOutlinedIcon />,
    path: "/services-web/profile",
  },
];
