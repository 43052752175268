import { useState, useEffect } from "react";
import { useGetAllUserAccessQuery } from "../features/permissions/permissionsApiSlice";
import { getModuleAccess, initialModuleAccess } from "helpers/permissionUtils";

export const useModuleAccess = (module) => {
  // module access
  const [moduleAccess, setModuleAccess] = useState(initialModuleAccess);
  // get allowed permissions
  const permissions = useGetAllUserAccessQuery();

  useEffect(() => {
    setModuleAccess(getModuleAccess(permissions.data ?? [], module));
  }, [permissions.data]);

  return moduleAccess;
};
