export const parseFilterObj = (filters) => {
  let filtered = "";
  if (filters) {
    const keys = Object.keys(filters);
    keys.forEach((key) => {
      if (filters[key] !== "") filtered += `&${key}=${filters[key]}`;
    });
  }

  return filtered;
};
