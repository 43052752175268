import { CancelSharp } from "@mui/icons-material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import { styled } from "@mui/system";
import {
  DateTimePicker,
  DesktopDatePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useField } from "formik";
import { MuiTelInput } from "mui-tel-input";
import { useEffect, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const StyledChip = styled(Chip)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: "#fcfcfb",
  ".MuiChip-deleteIcon": {
    color: "#fcfcfb",
  },
  "&:hover": {
    background: "transparent",
    color: theme.palette.primary.main,
    ".MuiChip-deleteIcon": {
      color: theme.palette.primary.main,
    },
  },
}));

export const FormikTextField = ({ label, ...props }) => {
  const [field, meta, helpers, required] = useField(props);

  return (
    <TextField
      sx={{ my: 1 }}
      label={label}
      required={required || false}
      {...field}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      {...props}
    />
  );
};

export const FormikTextAreaField = (props) => {
  return <FormikTextField {...props} multiline rows={4} maxRows={4} />;
};

export const FormikPasswordField = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <TextField
      sx={{ my: 1 }}
      label={label}
      {...props}
      {...field}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            style={{ border: "0pc solid transparent" }}
          >
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
};

export const FormikSwitchField = ({ label, checked, onChange, ...props }) => {
  const [field, meta, helpers] = useField(props);

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            {...field}
            checked={checked || field.value}
            onChange={(e) => {
              helpers.setValue(e.target.checked);
              if (onChange) onChange(e); // Call the onChange prop if provided
            }}
            {...props}
          />
        }
        label={label}
      />
    </FormGroup>
  );
};


// export const FormikSwitchField = ({ label, ...props }) => {
//   const [field, meta, helpers] = useField(props);

//   return (
//     <FormGroup>
//       <FormControlLabel
//         control={
//           <Switch
//             checked={field.value}
//             {...props}
//             onChange={(e) => helpers.setValue(e.target.checked)}
//           />
//         }
//         label={label}
//       />
//     </FormGroup>
//   );
// };

export const FormikSelectField = ({ label, options, ...props }) => {
  const [field, meta, helpers] = useField(props);

  return (
    <FormControl
      {...props}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      sx={{ my: 1 }}
    >
      <InputLabel id={props.labelId}>{label}</InputLabel>
      <Select
        label={label}
        {...props}
        {...field}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
      >
        {options.map((item) => (
          <MenuItem
            value={
              typeof item?.value === "undefined"
                ? item?.id
                  ? item?.id
                  : item
                : item.value
            }
          >
            {item?.label || item?.start_date || item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const FormikCheckbox = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);

  return (
    <FormControlLabel
      control={
        <Checkbox
          {...field}
          {...props}
          checked={field.value}
          onChange={(e) => helpers.setValue(e.target.checked)}
        />
      }
      label={label}
    />
  );
};

export const FormikSelectMultipleChipField = (props) => {
  const {
    name,
    label,
    labelId,
    data,
    options,
    disabled = false,
    ...rest
  } = props;

  const [field, meta, helpers] = useField(name);
  const { value: selectedValue } = field;
  const { setValue } = helpers;
  const handleDelete = (item) => () => {
    const newItem = selectedValue.filter((elem) => elem !== item);
    setValue(newItem);
  };

  const findItem = (value) => {
    const item = options.find(
      (item) => item.value === value || item.value === value?.value
    );
    if (item) {
      return item.label;
    }
    return "";
  };

  return (
    <FormControl
      {...rest}
      disabled={disabled}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      sx={{ my: 1 }}
    >
      <InputLabel id={props.labelId}>{label}</InputLabel>
      <Select
        label={label}
        multiple
        value={selectedValue}
        {...props}
        {...field}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => {
              return (
                <StyledChip
                  disabled={disabled}
                  deleteIcon={
                    <CancelSharp
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                  key={value}
                  label={findItem(value)}
                  onDelete={handleDelete(value)}
                  sx={{
                    "text-fill-color": "white",
                  }}
                />
              );
            })}
          </Box>
        )}
        MenuProps={MenuProps}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
      >
        {options.map((item) => (
          <MenuItem value={item?.value || item}>{item?.label || item}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const FormikSelectWithImageField = ({ label, options, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const theme = useTheme();

  return (
    <Box sx={{ my: 1 }}>
      <Typography variant="body" sx={{ mb: 0.5 }}>
        {label}
      </Typography>
      <Grid container spacing={2}>
        {options.map((item, index) => (
          <Grid key={item.value} item xs={"auto"}>
            <Box
              onClick={() => {
                helpers.setValue(item.value);
              }}
              sx={{
                cursor: "pointer",
                border: `${field.value === item.value ? "2px" : "0px"} solid ${
                  theme.palette.primary.main
                }`,
                borderRadius: "5px",
                p: 0.15,
              }}
            >
              <img
                src={item.label}
                alt={item.value}
                style={{
                  objectFit: "cover",
                  width: "auto",
                  height: "5rem",
                }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
      {meta.error && meta.touched ? (
        <Typography variant="p" color="error.main" sx={{ mt: 0.5 }}>
          {meta.error}
        </Typography>
      ) : (
        <></>
      )}
    </Box>
  );
};

export const FormikSelectWithPaginationField = ({
  label,
  options,
  pagination,
  setPagination,
  searchVal,
  setSearchVal,
  isSearch = false,
  rowCount,
  isLoading,
  filterFn,
  ...props
}) => {
  const theme = useTheme();
  const [field, meta, helpers] = useField(props);

  const loadOptions = (search, loaded, { isInit }) => {
    if (!isInit)
      setPagination((prev) => ({ ...prev, pageIndex: prev.pageIndex + 1 }));

    const data = {
      options: isSearch ? filterFn(options, search) : options,
      hasMore: rowCount > (pagination.pageIndex + 1) * pagination.pageSize,
      additional: {},
    };

    return data;
  };

  return (
    <Box sx={{ my: 1 }}>
      <AsyncPaginate
        placeholder={label}
        isLoading={isLoading}
        value={field.value}
        loadOptions={loadOptions}
        onChange={helpers.setValue}
        additional={{
          isInit: true,
        }}
        theme={(themeSelect) => ({
          ...themeSelect,
          colors: {
            ...themeSelect.colors,
            text: theme.palette.text.main,
            primary25: theme.palette.primary.light,
            primary: theme.palette.primary.main,
          },
        })}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,

            borderColor: state.isFocused ? "grey" : "grey",
            boxShadow: "none",
            border: `px solid ${theme.palette.text.mute}`,
            height: 52,
            "&:hover": {
              boxShadow: "none",
              border: `px solid ${theme.palette.text.mute}`,
            },
          }),
        }}
      />
    </Box>
  );
};

export const FormikPhoneField = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props);

  return (
    <MuiTelInput
      sx={{ my: 1 }}
      label={label}
      defaultCountry="ET"
      value={field.value}
      {...field}
      {...props}
      onChange={(value) => helpers.setValue(value)}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
};

export const FormikDateField = ({ name, label, ...props }) => {
  const [field, meta, helpers] = useField(name);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DesktopDatePicker
        sx={{ my: 1 }}
        label={label}
        {...props}
        {...field}
        onChange={(value) => helpers.setValue(value)}
        renderInput={(params) => (
          <TextField
            name={name}
            label={label}
            {...props}
            {...params}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export const FormikDateTimeField = ({ name, label, ...props }) => {
  const [field, meta, helpers] = useField(name);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateTimePicker
        sx={{ my: 1 }}
        label={label}
        {...props}
        {...field}
        onChange={(value) => helpers.setValue(value)}
        renderInput={(params) => (
          <TextField
            name={name}
            label={label}
            {...props}
            {...params}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
          />
        )}
      />
    </LocalizationProvider>
  );
};
export const FormikTimeField = ({ name, label, ...props }) => {
  const [field, meta, helpers] = useField(name);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <TimePicker
        sx={{ my: 1 }}
        label={label}
        {...props}
        {...field}
        onChange={(value) => helpers.setValue(value)}
        renderInput={(params) => (
          <TextField
            name={name}
            label={label}
            {...props}
            {...params}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export const FormikFileField = ({ label, accept, ...props }) => {
  const [field, meta, helpers] = useField(props);
  const [value, setValue] = useState(null);
  useEffect(() => {
    if (field.value) {
      setValue(field.value.name);
    }
  }, []);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Button
          variant="outlined"
          component="label"
          sx={{ my: 1, color: "black" }}
          {...props}
        >
          <AttachFileIcon sx={{ width: "18px", height: "18px" }} /> &nbsp;&nbsp;
          {label}
          <input
            accept={accept}
            type="file"
            hidden
            onChange={(e) => {
              helpers.setValue(e.target.files[0]);
              setValue(e.target.files[0].name);
            }}
          />
        </Button>
        {value && <Typography sx={{ ml: 2 }}>{value}</Typography>}
      </Box>
      {Boolean(meta.error) && (
        <Typography sx={{ color: "error.main" }}>{meta.error}</Typography>
      )}
    </Box>
  );
};

export const SubmitButton = ({
  isSubmitting,
  isDisabled = false,
  text = "Continue",
  ...props
}) => {
  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      disabled={isSubmitting || isDisabled}
      sx={{ my: 1 }}
      {...props}
    >
      {isSubmitting ? <CircularProgress size={24} /> : text}
    </Button>
  );
};
