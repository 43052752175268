// import { alpha, styled } from "@mui/system";
import { unset } from "lodash";
import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MdKeyboardArrowDown } from "react-icons/md";

const StyledToolbarMenu = ({ children, anchorEl, open, handleClose, handleClick, options, disabled, ...rest }) => {
  console.log(options);

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disabled={disabled}
        startIcon={<MdKeyboardArrowDown size={20} color={disabled ? "#a6a6a6" : "black"}/>}
        sx={{ width: "250px", fontWeight: 500, background: disabled ? "#e0e0e0" : "#d7a65a", 
          color: "black", display: "flex", alignItems: "center", 
          gap: "1em", '&:hover': { backgroundColor: disabled ? "#e0e0e0" : '#96743e' }}}
      >
        {/* <MdKeyboardArrowDown size={20} color={disabled ? "#a6a6a6" : "black"}/> */}
        Set Invitation Types
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {options?.map(option =>
          <MenuItem sx={{ width: "250px", background: 'white' }}
            onClick={() => handleClose(option.id)}>{option.name}</MenuItem>
        )}
      </Menu>
    </div>
  );
}

export default StyledToolbarMenu;
