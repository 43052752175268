import { apiSlice } from "app/api/apiSlice";
import { getParsedDate } from "helpers/dateFormat";

export const sharedAttendeesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllSharedAttendees: builder.query({
      query: ({ query, pageIndex, pageSize }) => ({
        url: `/portal/share/attendees/?page=${pageIndex}&page_size=${pageSize}`,
      }),
      transformResponse: (response) => {
        return {
          page_metadata: response.page_metadata,
          data: response.data.map((item) => ({
            id: item.id,
            image: item.attendee.image,
            contact: item.attendee.full_name,
            shared_to: item.shared_to.email,
            shared_by: item.shared_by.email,
            read: item.permissions.includes("READ"),
            update: item.permissions.includes("UPDATE"),
            delete: item.permissions.includes("DELETE"),
            shared_date: getParsedDate(new Date(item.date_created)),
          })),
        };
      },
      providesTags: ["SharedAttendee"],
    }),
    createSharedAttendee: builder.mutation({
      query: (data) => ({
        url: `/portal/share/attendee/manage/`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["SharedAttendee"],
    }),
    deleteSharedAttendee: builder.mutation({
      query: ({ ids, password }) => ({
        url: `/portal/share/attendees/delete/`,
        method: "DELETE",
        body: { attendee_share_ids: ids, password: password },
      }),
      invalidatesTags: ["SharedAttendee"],
    }),
  }),
});

export const {
  useDeleteSharedAttendeeMutation,
  useGetAllSharedAttendeesQuery,
  useCreateSharedAttendeeMutation,
} = sharedAttendeesApiSlice;
