// import { getParsedDate, getParsedTime } from "helpers/dateFormat";
import { apiSlice } from "../../app/api/apiSlice";
import { format } from "date-fns";
import { webSocketUrl } from "constants/api";

export const notificationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: (query) => {
        const { pagination, searchValue, filters } = query;
        const { pageIndex, pageSize } = pagination;
        const searchQuery = searchValue ? `&query=${searchValue}` : "";
        return {
          url: `/notification/?page=${pageIndex}&page_size=${pageSize}${searchQuery}${filters}`,
        };
      },
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        // create a websocket connection when the cache subscription starts
        const access = localStorage?.getItem("access");
        const ws = new WebSocket(
          `${webSocketUrl}/ws/notification/?authorization=${access}`
        );
        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;

          // when data is received from the socket connection to the server,
          // if it is a message and for the appropriate channel,
          // update our query result with the received message
          const listener = (event) => {
            const data = JSON.parse(event.data);
            switch (data.type) {
              case "CREATED_NOTIFICATION":
                updateCachedData((draft) => {
                  draft.page_metadata.length = draft.page_metadata.length + 1;
                  draft.data.unshift({
                    ...data.payload,
                    date_created_raw: data.payload.date_created,
                    date_created: format(
                      new Date(data.payload.date_created),
                      "PPpp"
                    ),
                  });
                });
                break;
              case "UPDATED_NOTIFICATION":
                updateCachedData((draft) => {
                  const index = draft.data.findIndex(
                    (item) => item.id === data.payload.id
                  );
                  if (index !== -1)
                    draft.data[index] = {
                      ...data.payload,
                      date_created_raw: data.payload.date_created,
                      date_created: format(
                        new Date(data.payload.date_created),
                        "PPpp"
                      ),
                    };
                });
                break;
            }
          };

          ws.onmessage = listener;
        } catch (e) {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
          console.error(e.message);
        }
        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved;
        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        ws.close();
      },
      transformResponse: (response) => {
        const { page_metadata, data } = response.data;
        const notifications = data.map((item) => ({
          ...item,
          date_created_raw: item.date_created,
          date_created: format(new Date(item.date_created), "PPpp"),
        }));

        return {
          page_metadata,
          data: notifications,
        };
      },
      providesTags: ["Notification"],
    }),
    getNotificationsCount: builder.query({
      query: () => {
        return {
          url: `/notification/count/not_opened/`,
        };
      },
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        // create a websocket connection when the cache subscription starts
        const access = localStorage?.getItem("access");
        const ws = new WebSocket(
          `${webSocketUrl}/ws/notification/?authorization=${access}`
        );
        try {
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;

          // when data is received from the socket connection to the server,
          // if it is a message and for the appropriate channel,
          // update our query result with the received message
          const listener = (event) => {
            const data = JSON.parse(event.data);
            switch (data.type) {
              case "COUNT_NOT_OPENED_NOTIFICATION":
                updateCachedData((draft) => {
                  draft.not_opened_notifications_count =
                    data.payload.not_opened_notifications_count;
                });
                break;
            }
          };

          ws.onmessage = listener;
        } catch (e) {
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
          console.error(e.message);
        }
        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved;
        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        ws.close();
      },
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: ["Notification"],
    }),
    openNotification: builder.mutation({
      query: (body) => ({
        url: "/notification/open/",
        method: "PUT",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Notification"],
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useGetNotificationsCountQuery,
  useOpenNotificationMutation,
} = notificationsApiSlice;
