import { Box, Grid } from "@mui/material";
import SquareEventCard from "components/Cards/SquareEventCard";
import SquareLoadingEventCard from "components/Cards/SquareEventCard/SquareLoadingEventCard";
import { useGetPublicTownEventsQuery } from "features/publicEvents/publicEventsApiSlice";
import Pagination from "@mui/lab/Pagination";
import { useEffect, useState } from "react";
import { usePagination } from "hooks/usePagination";
import Paper from "@mui/material/Paper";

const EventList = (props) => {
  const [pagination] = usePagination();
  const { data: eventsRaw, isLoading: isEventsLoading } =
    useGetPublicTownEventsQuery({
      pagination,
    });

  const events = eventsRaw ? eventsRaw.data : [];
  const loadingCards = [1, 2, 3, 4, 5, 6, 7, 8];
  const pageMetadata = eventsRaw ? eventsRaw.page_metadata : null;
  const [displayedEvents, setDisplayedEvents] = useState([]);
  const [activePage, setActivePage] = useState(1);

  // Set the number of events to display per page
  const eventsPerPage = 4;

  useEffect(() => {
    if (events.length > 0) {
      // const startIndex = pageMetadata ? pageMetadata.start_index : 0;

      // Calculate the start and end indices based on active page and events per page
      const start = (activePage - 1) * eventsPerPage;
      const end = Math.min(start + eventsPerPage, events.length);

      const currentEvents = events.slice(start, end);

      setDisplayedEvents(currentEvents);
    }
  }, [events, activePage, pagination, pageMetadata]);

  const handlePageChange = (page) => {
    // Set the active page when the user clicks a new page
    setActivePage(page);
  };

  // Calculate the total number of pages
  const pageCount = pageMetadata ? Math.ceil(events.length / eventsPerPage) : 1;

  return (
    <Box sx={{ pb: 4 }}>
      {events.length > 0 ? (
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {isEventsLoading
            ? loadingCards.map((item) => (
                <Grid key={`${item}`} item xs={12} sm={6} md={4} lg={3} xl={2}>
                  <SquareLoadingEventCard />
                </Grid>
              ))
            : displayedEvents.map((event) => (
                <Grid key={event.name} item xs={12} sm={6} md={4} lg={3} xl={2}>
                  <SquareEventCard event={event} />
                </Grid>
              ))}
        </Grid>
      ) : (
        <Box className="flex justify-center align-center">{""}</Box>
      )}

      {
        /* Only display pagination if there is more than one page */
        pageCount > 1 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "50px",
              alignItems: "center",
            }}
          >
            <Paper elevation={8}>
              <Pagination
                count={pageCount}
                page={activePage}
                onChange={(e, page) => handlePageChange(page)}
                // boundaryCount={3}
                color="primary"
              />
            </Paper>
          </div>
        )
      }
    </Box>
  );
};

export default EventList;
