import { Autocomplete, Dialog, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Form, Formik } from "formik";
import { useGetAllEventsQuery } from "features/events/eventsApiSlice";
import { useSendBulkInvitationMutation } from "features/invitations/invitationApiSlice";
import { useState } from "react";
import useGetData from "hooks/useGetData";
import { ModalItemsContainer } from "components/CustomModal/Styles";
import {
  FormikPasswordField,
  FormikTextField,
  SubmitButton,
} from "components/form-components/CustomFormikFields";

const BulkInviteDialog = ({
  open,
  onClose,
  selected,
  text = "Invite Attendees",
  onSucceed,
}) => {
  const [sendInvitations] = useSendBulkInvitationMutation();
  const [errorMessage, setErrorMessage] = useState("");
  const initialValues = {
    send_email: false,
    event_id: "",
    password: "",
  };

  const [eventQuery, setEventQuery] = useState({
    event_type: "PRIVATE",
    status: "UPCOMING,ONGOING",
    query: "",
  });
  const { data: events, isLoading: isEventsLoading } = useGetData()(
    useGetAllEventsQuery,
    eventQuery
  );

  const handleSubmit = async (values) => {
    const data = {
      attendee_ids: selected,
      event_id: values.event.id,
      password: values.password,
    };

    try {
      await sendInvitations(data).unwrap();
      setErrorMessage("");
      onClose();
      onSucceed();
    } catch (err) {
      setErrorMessage(err?.data?.message);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <ModalItemsContainer>
        <IconButton
          onClick={() => onClose()}
          sx={{
            alignSelf: "flex-end",
            position: "absolute",
            top: "0",
            right: "0",
            mb: 8,
          }}
        >
          <Close />
        </IconButton>
        <Typography
          align="center"
          variant="p"
          sx={{ mb: 3, fontSize: 20, color: "red" }}
        >
          {errorMessage}
        </Typography>
        <Typography
          align="center"
          variant="h2"
          color="primary.main"
          sx={{ fontWeight: 700, mb: 1, fontSize: 24 }}
        >
          {text}
        </Typography>

        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <Autocomplete
                renderInput={(params) => (
                  <FormikTextField
                    fullwidth
                    margin="dense"
                    id="event"
                    name="event"
                    label="Event"
                    required
                    {...params}
                  />
                )}
                options={events}
                getOptionLabel={(item) =>
                  `${item?.name}${item?.is_virtual ? " (virtual)" : ""}`
                }
                onChange={(e, v) => {
                  if (v) {
                    setFieldValue("event", v);
                  }
                }}
                onInputChange={(e, n) =>
                  setEventQuery((prev) => ({ ...prev, query: n }))
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
              <FormikPasswordField
                required
                fullWidth
                margin="dense"
                id="password"
                name="password"
                placeholder={"password"}
                label="Password"
              />
              <SubmitButton isSubmitting={isSubmitting} />
            </Form>
          )}
        </Formik>
      </ModalItemsContainer>
    </Dialog>
  );
};

export default BulkInviteDialog;
