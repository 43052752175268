import { apiSlice } from "../../app/api/apiSlice";

export const spacesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSpaces: builder.query({
      query: (query) => {
        const { pagination, searchValue, filters } = query;
        const { pageIndex, pageSize } = pagination;
        const searchQuery = searchValue ? `&query=${searchValue}` : "";

        return {
          url: `/portal/space/?page=${pageIndex}&page_size=${pageSize}${searchQuery}${filters}`,
        };
      },
      transformResponse: (response) => {
        const { page_metadata, data } = response.data;
        const spaces = data.map((item) => ({
          ...item,
        }));
        return {
          page_metadata,
          data: spaces,
        };
      },
      providesTags: ["Space"],
    }),
    getSpacesCategory: builder.query({
      query: (query) => {
        const { pagination } = query;
        const { pageIndex, pageSize } = pagination;

        return {
          url: `/portal/event/space/category/?page=${pageIndex}&page_size=${pageSize}&order_by=date_updated&order_by_clause=DESC`,
        };
      },
      transformResponse: (response) => {
        const { page_metadata, data } = response.data;
        const spacesCategory = data.map((item) => ({
          ...item,
        }));
        return {
          page_metadata,
          data: spacesCategory,
        };
      },
      providesTags: ["Space"],
    }),
    getSpace: builder.query({
      query: (id) => {
        return {
          url: `/portal/space/detail/?event_space_id=${id}`,
        };
      },
      transformResponse: (response) => {
        return response;
      },
      providesTags: ["Space"],
    }),
    getEventSpaces: builder.query({
      query: (query) => {
        const { pagination, searchValue, filters } = query;
        const { pageIndex, pageSize } = pagination;
        const searchQuery = searchValue ? `&query=${searchValue}` : "";

        return {
          url: `/portal/event/space/?page=${pageIndex}&page_size=${pageSize}${searchQuery}${filters}`,
        };
      },
      transformResponse: (response) => {
        const { page_metadata, data } = response.data;
        const spaces = data.map((item) => ({
          ...item,
          name: item.space.name,
          space_type: item.space.space_type,
          location: item.space.location,
          floor_plan_image: item.space.floor_plan_image,
        }));
        return {
          page_metadata,
          data: spaces,
        };
      },
      providesTags: ["Space"],
    }),
    getSpacesPublic: builder.query({
      query: (query) => {
        const { pagination, searchValue, filters } = query;
        const { pageIndex, pageSize } = pagination;
        const searchQuery = searchValue ? `&query=${searchValue}` : "";

        return {
          url: `/portal/public/event/spaces/?page=${pageIndex}&page_size=${pageSize}${searchQuery}${filters}`,
        };
      },
      transformResponse: (response) => {
        const { page_metadata, data } = response.data;

        return {
          page_metadata,
          data,
        };
      },
      providesTags: ["Space"],
    }),
    createSpace: builder.mutation({
      query: (body) => ({
        url: "/portal/space/create/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Space"],
    }),
    createEventSpace: builder.mutation({
      query: (body) => ({
        url: "/portal/event/space/add/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Space"],
    }),
    updateSpace: builder.mutation({
      query: (body) => ({
        url: "/portal/space/update/",
        method: "PUT",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Space"],
    }),
    deleteSpace: builder.mutation({
      query: (body) => ({
        url: "/portal/space/delete/",
        method: "DELETE",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Space"],
    }),
    deleteEventSpace: builder.mutation({
      query: (body) => ({
        url: "/portal/event/space/remove/",
        method: "DELETE",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Space"],
    }),
  }),
});

export const {
  useGetSpacesQuery,
  useGetSpacesCategoryQuery,
  useGetSpacesPublicQuery,
  useGetEventSpacesQuery,
  useGetSpaceQuery,
  useCreateSpaceMutation,
  useCreateEventSpaceMutation,
  useUpdateSpaceMutation,
  useDeleteSpaceMutation,
  useDeleteEventSpaceMutation,
} = spacesApiSlice;
