import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Checkbox,
  Pagination,
  IconButton,
  TableContainer,
  Skeleton,
  // Stack,
  Typography,
  Modal,
  useTheme,
  Icon,
} from "@mui/material";
import SearchBar from "./SearchBar";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import GlobalSearch from "components/GlobalSearch";
import { useOpenNotificationMutation } from "features/notifications/notificationsApiSlice";
import OpenNotificationModal from "components/formModals/notifications/OpenNotificationModal";
import { useState } from "react";
import ControlledCheckbox from "components/widgets/ControlledCheckbox";
import { useEffect } from "react";
import { NOTIFICATION_CATEGORY_OPTIONS_ICBIG } from "./NotificationFilters";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/North";
import { openRedirectLink } from "helpers/redirectLink";
import { useNavigate, useSearchParams } from "react-router-dom";
import { StyledNotificationText } from "components/notification/NotificationMenuItem";
import htmlToFormattedText from "html-to-formatted-text";

const TABLE_TITLES = ["Title", "Subject"];

const NotificationTypography = ({ opened, text }) => {
  return <Typography fontWeight={opened ? 400 : 600}>{text}</Typography>;
};

export const handleOpenNotification = async (ids, openNotification) => {
  const formData = new FormData();
  ids.forEach((id) => formData.append("notification_ids[]", id));
  try {
    const res = await openNotification(formData);
    if (res?.error) {
      throw new Error(res.error.data.message);
    }
  } catch (err) {}
};

const NotificationsTable = (props) => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const openNotificationId = searchParams?.get("open_notification_id");

  const {
    isLoading,
    data,
    rowCount,
    pagination,
    setPagination,
    searchProps,
    orderBy,
    setOrderBy,
  } = props;

  const navigate = useNavigate();

  const [openNotification] = useOpenNotificationMutation();
  const [modalData, setModalData] = useState({ open: false });

  const [checkboxRows, setCheckboxRows] = useState(
    data.length === 0 ? [] : Array(data.length).fill(false)
  );
  useEffect(() => {
    setCheckboxRows(Array(data.length).fill(false));
  }, [data]);

  const [selectedRows, setSelectedRows] = useState([]);

  const loadingRows = [1, 2, 3, 4, 5];

  const handlePageChange = (event, value) => {
    setPagination((prev) => ({ ...prev, pageIndex: value - 1 }));
  };

  const handleRowSelect = (isSelected, id, rowIndex) => {
    let checkRows = checkboxRows;
    checkRows[rowIndex] = isSelected;
    setCheckboxRows(checkRows);

    if (isSelected) {
      setSelectedRows((prev) => [...prev, id]);
      return;
    }
    setSelectedRows((prev) => prev.filter((item) => item !== id));
  };

  const handleMassSelect = (isSelected, rows) => {
    if (isSelected) {
      const ids = rows.map((row) => row.id);
      setSelectedRows(ids);
      setCheckboxRows((prev) => Array(prev.length).fill(true));
      return;
    }
    setSelectedRows([]);
    setCheckboxRows((prev) => Array(prev.length).fill(false));
  };

  const handleRowClick = (row) => {
    row.status !== "OPENED" &&
      handleOpenNotification([row.id], openNotification);
    // row?.redirect_url
    //   ? openRedirectLink(row?.redirect_url, navigate)()
    //   :
    setModalData((prev) => ({
      ...prev,
      open: true,
      row,
    }));
  };

  useEffect(() => {
    if (!openNotificationId) {
      return;
    }
    let notification = data.filter(
      (item) => item.id === parseInt(openNotificationId)
    );
    if (notification.length === 0) {
      return;
    }

    notification = notification[0];

    notification.status !== "OPENED" &&
      handleOpenNotification([notification.id], openNotification);

    notification?.redirect_url
      ? openRedirectLink(notification?.redirect_url, navigate)()
      : setModalData((prev) => ({
          ...prev,
          open: true,
          row: notification,
        }));
  }, [data]);

  const handleMarkRead = async () => {
    await handleOpenNotification(selectedRows, openNotification);
  };

  return (
    <>
      <Card>
        <Box
          display="flex"
          justifyContent=""
          alignItems={"center"}
          sx={{ pt: 4, pb: 2, px: 4 }}
        >
          <GlobalSearch {...searchProps} isCustomComp={true}>
            {(props) => <SearchBar {...props} />}
          </GlobalSearch>
          {selectedRows.length > 0 && (
            <Box ml={1}>
              <Tooltip title="Mark selected as read">
                <IconButton onClick={handleMarkRead}>
                  <MarkEmailReadIcon sx={{ width: 24, height: 24 }} />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
        <PerfectScrollbar>
          <TableContainer component={Box}>
            <Table sx={{ minWidth: 800 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      onChange={(e) => {
                        handleMassSelect(e.target.checked, data);
                      }}
                    />
                  </TableCell>
                  <TableCell></TableCell>
                  {TABLE_TITLES.map((title) => (
                    <TableCell key={title}>{title}</TableCell>
                  ))}
                  <TableCell>
                    <Tooltip
                      title={`sort by ${
                        orderBy === "ASC" ? "newest" : "oldest"
                      }`}
                    >
                      <Box
                        sx={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={() =>
                          setOrderBy((prev) =>
                            prev === "ASC" ? "DESC" : "ASC"
                          )
                        }
                      >
                        Date {orderBy === "ASC" ? <NorthIcon /> : <SouthIcon />}
                      </Box>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading
                  ? loadingRows.map((row) => (
                      <TableRow
                        hover
                        key={row}
                        sx={{ py: 2, cursor: "pointer" }}
                      >
                        <TableCell>
                          <Checkbox />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width={20} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width={100} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width={200} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width={150} />
                        </TableCell>
                      </TableRow>
                    ))
                  : data.map((row, index) => (
                      <TableRow hover key={row.id} sx={{ py: 2 }}>
                        <TableCell>
                          <ControlledCheckbox
                            checked={checkboxRows[index]}
                            handleChange={(e) =>
                              handleRowSelect(e.target.checked, row.id, index)
                            }
                          />
                        </TableCell>
                        <TableCell
                          onClick={() => handleRowClick(row)}
                          sx={{ cursor: "pointer" }}
                        >
                          <Box display={"flex"} alignItems="center">
                            <Tooltip title={row.category.toLowerCase()}>
                              <Icon
                                sx={{
                                  color:
                                    row.status === "OPENED"
                                      ? theme.palette.text.secondary
                                      : theme.palette.text.main,
                                }}
                              >
                                {
                                  NOTIFICATION_CATEGORY_OPTIONS_ICBIG.filter(
                                    (item) => item.value === row.category
                                  )[0].icon
                                }
                              </Icon>
                            </Tooltip>
                          </Box>
                        </TableCell>
                        <TableCell
                          onClick={() => handleRowClick(row)}
                          sx={{ cursor: "pointer" }}
                        >
                          <NotificationTypography
                            opened={row.status === "OPENED"}
                            text={row.title}
                          />
                        </TableCell>
                        <TableCell
                          onClick={() => handleRowClick(row)}
                          sx={{
                            cursor: "pointer",
                            maxWidth: "275px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <StyledNotificationText
                            sx={{
                              fontWeight: row.status === "OPENED" ? 400 : 600,
                              py: 2,
                            }}
                          >
                            {htmlToFormattedText(row.message)}
                          </StyledNotificationText>
                        </TableCell>
                        <TableCell
                          onClick={() => handleRowClick(row)}
                          sx={{ cursor: "pointer" }}
                        >
                          {" "}
                          <NotificationTypography
                            opened={row.status === "OPENED"}
                            text={row.date_created}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </PerfectScrollbar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            py: 2,
          }}
        >
          <Pagination
            count={
              isLoading
                ? loadingRows.length
                : Math.ceil(rowCount / pagination.pageSize)
            }
            page={pagination.pageIndex + 1}
            onChange={handlePageChange}
            siblingCount={0}
            boundaryCount={1}
          />
        </Box>
      </Card>
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={modalData.open}
        onClose={() => setModalData((prev) => ({ ...prev, open: false }))}
      >
        <OpenNotificationModal
          modalData={modalData}
          setModalData={setModalData}
        />
      </Modal>
    </>
  );
};

export default NotificationsTable;
