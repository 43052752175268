import {
  Box,
  // Icon,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import FilterNoneIcon from "@mui/icons-material/FilterNone";

const filterIconSx = { width: 18, height: 18, mr: 1.5 };

export const NOTIFICATION_CATEGORY_OPTIONS = [
  { value: "ALL", label: "All", icon: <FilterNoneIcon sx={filterIconSx} /> },
  {
    value: "WARNING",
    label: "Warning",
    icon: <WarningAmberIcon sx={filterIconSx} />,
  },
  {
    value: "MESSAGE",
    label: "Message",
    icon: <MailOutlineIcon sx={filterIconSx} />,
  },
  {
    value: "NOTIFICATION",
    label: "Notification",
    icon: <NotificationsActiveOutlinedIcon sx={filterIconSx} />,
  },
];

export const NOTIFICATION_CATEGORY_OPTIONS_ICBIG = [
  { value: "ALL", label: "All", icon: <FilterNoneIcon sx={filterIconSx} /> },
  {
    value: "WARNING",
    label: "Warning",
    icon: <WarningAmberIcon />,
  },
  {
    value: "MESSAGE",
    label: "Message",
    icon: <MailOutlineIcon />,
  },
  {
    value: "NOTIFICATION",
    label: "Notification",
    icon: <NotificationsActiveOutlinedIcon />,
  },
];

const NotificationFilters = ({ setCategory }) => {
  const [active, setActive] = useState(0);

  useEffect(() => {
    setCategory(NOTIFICATION_CATEGORY_OPTIONS[active].value);
  }, [active]);

  return (
    <Box>
      <List
        sx={{ display: "flex", flexDirection: { xs: "row", md: "column" } }}
      >
        {NOTIFICATION_CATEGORY_OPTIONS.map((item, index) => (
          <ListItem key={item.value} selected={active === index ? true : false}>
            <ListItemButton
              onClick={() => setActive(index)}
              sx={{ width: { xs: 10, md: "auto" } }}
            >
              {item.icon}
              <Typography sx={{ display: { xs: "none", md: "inline-block" } }}>
                {item.label}
              </Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default NotificationFilters;
