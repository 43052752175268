import { Typography, Box, 
  // useTheme, 
  Stack } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";

// import * as DOMPurify from "dompurify";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { 
  // Navigate, 
  useNavigate } from "react-router-dom";
// import { openRedirectLink } from "helpers/redirectLink";
// import { commonPages } from "menu";
// import { handleOpenNotification } from "pages/shared/Notifications/NotificationsTable";
// import { useOpenNotificationMutation } from "features/notifications/notificationsApiSlice";
import htmlToFormattedText from "html-to-formatted-text";

import "draft-js/dist/Draft.css";

TimeAgo.addDefaultLocale(en);

// Create formatter (English).
const timeAgo = new TimeAgo("en-US");

export const StyledMenuItem = styled(Box)(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.primary.main,
  width: "100%",
  ":hover": {
    background: theme.palette.body.secondary,
  },
}));

export const StyledNotificationText = styled(Typography)(() => ({
  // overflow: "hidden",
  display: "inline-block",
  overflow: "hidden",
  whiteSpace: "nowrap",
  width: "90%",
  textOverflow: "ellipsis",
  maxHeight: "50px",
  paddingTop: 4,
  paddingBottom: 0,
}));

const NotificationMenuItem = (props) => {
  const {
    notification,
    notificationsPath,
    handleClick,
    // center = false,
    // allowDelete = false,
  } = props;

  // const [openNotification] = useOpenNotificationMutation();

  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        cursor: "pointer",
      }}
      onClick={
        // notification?.redirect_url
        //   ? () => {
        //       handleOpenNotification([notification.id], openNotification);
        //       openRedirectLink(notification?.redirect_url, navigate)();
        //     }
        //   :
        () => {
          navigate(
            `${notificationsPath}?open_notification_id=${notification.id}`
          );
        }
      }
    >
      <StyledMenuItem
        sx={{
          px: 2,
          py: 2,
        }}
        onClick={handleClick}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            width: "100%",
          }}
        >
          <Typography
            variant="p"
            fontWeight={600}
            fontSize={14}
            overflow="hidden"
            whiteSpace="nowrap"
            width={"70%"}
            textOverflow="ellipsis"
          >
            {notification?.title || ""}
          </Typography>
          <Typography
            variant="p"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            fontWeight={500}
            fontSize={14}
            sx={{ width: "30%" }}
          >
            {timeAgo.format(new Date(notification?.date_created))}
          </Typography>
        </Stack>

        <StyledNotificationText fontSize={12}>
          {htmlToFormattedText(notification.message)}
        </StyledNotificationText>
      </StyledMenuItem>
    </Box>
  );
};

export default NotificationMenuItem;
