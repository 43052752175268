import { createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../../app/api/apiSlice";

const initial = {
  user: null,
  accessToken: null,
  refreshToken: null,
  role: null,
  isAuth: false,
  company: {
    id: null,
    name: null,
    companyType: null,
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState: {
    ...initial,
  },
  reducers: {
    setCredentials: (state, action) => {
      const {
        user,
        accessToken,
        refreshToken,
        role,
        isAuth = false,
        companyName = null,
        companyType = null,
      } = action.payload;
      state.user = user;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.role = role;
      state.isAuth = isAuth;
      state.company.name = companyName;
      state.company.companyType = companyType;
    },
    setCompany: (state, action) => {
      const { id, name, companyType } = action.payload;
      state.company = { id, name, companyType };
    },
    logout: (state, action) => {
      const syncLogout = () => {
        const performLogout = async () => {
          let accessToken = window.localStorage?.getItem("access");
          let refreshToken = window.localStorage?.getItem("refresh");
          await fetch(`${baseUrl}/api/logout_token/`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${accessToken}`,
            },
            method: "POST",
            body: JSON.stringify({
              refresh_token: refreshToken,
            }),
          });
        };
        performLogout();
      };
      syncLogout();

      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.role = null;
      state.isAuth = false;
      state.company = {
        id: null,
        name: null,
        companyType: null,
      };
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
    },
  },
});

export const { setCredentials, logout } = authSlice.actions;
export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentAccessToken = (state) => state.auth.accessToken;
export const selectCurrentRefreshToken = (state) => state.auth.refreshToken;
export const selectCurrentRole = (state) => state.auth.role;
export const selectIsAuth = (state) => state.auth.isAuth;

export const authMiddleware = (store) => (next) => (action) => {
  if (setCredentials.match(action)) {
    // Note: localStorage expects a string
    // localStorage.setItem('isAuthenticated', 'true');
  } else if (logout.match(action)) {
    // localStorage.setItem('isAuthenticated', 'false');
  }
  return next(action);
};
