import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logout, setCredentials } from "../../features/auth/authSlice";
import jwtDecode from "jwt-decode";
import { apiUrl } from "constants/api";

const noAuthEndpoints = [
  "login",
  "registerCompany",
  "getAllPaymentPlan",
  "getPaymentMethods",
  "textVerify",
  "emailVerify",
  "getMultipleTickets",
];

export const baseUrl = apiUrl;

const isTokenExpired = (token) => {
  try {
    const { exp } = jwtDecode(token);
    return Date.now() >= exp * 1000;
  } catch (err) {
    return true;
  }
};

const baseQuery = fetchBaseQuery({
  baseUrl,
  credentials: "include",
  prepareHeaders: (headers, { getState, endpoint }) => {
    const accessToken = window.localStorage?.getItem("access");
    if (
      accessToken &&
      !noAuthEndpoints.includes(endpoint) &&
      !isTokenExpired(accessToken)
    ) {
      headers.set("Authorization", `JWT ${accessToken}`);
    } else {
      headers.delete("Authorization");
    }
    return headers;
  },
});

let isRefreshing = false;
let refreshPromise = null;

const execRefreshToken = async (api) => {
  const refreshToken = window.localStorage?.getItem("refresh");

  if (!refreshToken) {
    api.dispatch(logout());
    throw new Error("No refresh token available");
  }

  const refreshResultRaw = await fetch(
    `${baseUrl.replace(/\/+$/, "")}/api/token/refresh/`,
    {
      cache: "no-cache",
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify({ refresh: refreshToken }),
    }
  );

  if (!refreshResultRaw.ok) {
    api.dispatch(logout());
    throw new Error("Failed to refresh token");
  }

  const refreshResult = await refreshResultRaw.json();
  const { access, refresh } = refreshResult;

  window.localStorage.setItem("access", access);
  window.localStorage.setItem("refresh", refresh);

  const user = api.getState().auth.user;

  await api.dispatch(
    setCredentials({
      accessToken: access,
      refreshToken: refresh,
      user,
    })
  );

  return access;
};

const getRefreshToken = async (api) => {
  if (!isRefreshing) {
    isRefreshing = true;
    refreshPromise = execRefreshToken(api).finally(() => {
      isRefreshing = false;
      refreshPromise = null;
    });
  }
  return refreshPromise;
};

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401 || result?.error?.originalStatus === 401) {
    try {
      const newToken = await getRefreshToken(api);
      if (newToken) {
        // Retry the original query with the new token
        result = await baseQuery(args, api, extraOptions);
      }
    } catch (error) {
      api.dispatch(logout());
      return { error: { status: 401, statusText: "Unauthorized" } };
    }
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "Security",
    "Event",
    "SharedEvents",
    "EventRequest",
    "Invitation",
    "Role",
    "User",
    "Attendance",
    "Attendee",
    "SharedAttendee",
    "EmailTemplate",
    "Permission",
    "TicketBundle",
    "ModuleAccess",
    "Company",
    "ContactGroup",
    "Tos",
    "CampaignTemplate",
    "Campaign",
    "Vendor",
    "Organization",
    "EventTicket",
    "EventSchedule",
    "PaymentPlan",
    "Notification",
    "Space",
    "VendorEvent",
    "Ads",
    "Bank",
    "Promo",
    "Sponsor",
    "SponsorType",
    "EventSponsor",
    "EventLocation",
    "Auth",
    "Module",
    "Category",
    "Payment",
    "TicketSeller",
    "Order",
    "AppSetting",
    "WebSetting",
    "PortalSetting",
  ],
  endpoints: (builder) => ({}),
});
