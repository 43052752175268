import React from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";

import { ModalItemsContainer } from "components/CustomModal/Styles";

import * as DOMPurify from "dompurify";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { StyledLink } from "pages/auth/Login";
TimeAgo.addDefaultLocale(en);

// Create formatter (English).
const timeAgo = new TimeAgo("en-US");

const OpenNotificationModal = (props) => {
  const { modalData, setModalData } = props;
  const theme = useTheme();

  return (
    <ModalItemsContainer>
      <Stack direction={"row"} justifyContent="space-between">
        <Typography variant="h5" fontWeight={500} sx={{ width: "70%" }}>
          {modalData.row.title}
        </Typography>
        <Typography
          variant="h6"
          fontWeight={400}
          textAlign="center"
          sx={{ width: "20%" }}
        >
          {timeAgo.format(new Date(modalData.row.date_created_raw))}
        </Typography>
      </Stack>

      <Box
        sx={{
          mt: 4,
          border: `2px solid ${theme.palette.grey["500"]}`,
          borderRadius: "3px",
          p: 2,
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(modalData.row.message),
          }}
        />
      </Box>
      {modalData.row.redirect_url && (
        <StyledLink
          href={modalData.row.redirect_url}
          sx={{ cursor: "pointer", fontSize: { xs: 16, md: 12 } }}
        >
          Go to page
        </StyledLink>
      )}
    </ModalItemsContainer>
  );
};

export default OpenNotificationModal;
