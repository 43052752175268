import React, { useState } from "react";
import { 
  Box, 
  // Container
 } from "@mui/material";
import { useModal } from "contexts/modalContext";
import EnhancedDataTable from "components/EnhancedDataTable";
import DefaultSnackbar from "components/DefaultSnackbar";
import useGetData from "hooks/useGetData";
import { useGetVendorEventRequestsQuery } from "features/vendorEvent/vendorEventApiSlice";
// import CheckoutModal from "./Checkout/CheckoutModal";
import PaymentIcon from "@mui/icons-material/Payment";
// import VendorRequestsHeader from "layout/Header/adminPagesHeader/VendorRequestsHeader";
import CheckoutModal from "pages/vendors/VendorRequests/Checkout/CheckoutModal";
import ServiceProviderRequestsHeader from "./ServiceProviderRequestHeader";

const headCells = [
  {
    id: "event_name",
    numeric: false,
    disablePadding: true,
    label: "Event",
  },
  {
    id: "space_name",
    numeric: false,
    disablePadding: true,
    label: "Space name",
  },
  {
    id: "space_price",
    numeric: true,
    disablePadding: true,
    label: "Price",
  },
  {
    id: "space_type",
    numeric: false,
    disablePadding: true,
    label: "Type",
  },
  {
    id: "space_location_name",
    numeric: false,
    disablePadding: true,
    label: "location",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Organizer response",
  },
  {
    id: "order",
    numeric: false,
    disablePadding: false,
    label: "Order ID",
  },
  {
    id: "payment_status",
    numeric: false,
    disablePadding: true,
    label: "Payment status",
  },
];

const ServiceProviderRequests = () => {
  // const { showModal, toggleModal } = useModal();
  const [modalData, setModalData] = useState({
    open: false,
    id: "",
    price: "",
    name: "",
  });

  const {
    isLoading,
    pagination,
    setPagination,
    rowCount,
    data,
    searchValue,
    setSearchValue,
    showSearch,
    setShowSearch,
    refetch,
  } = useGetData(true)(useGetVendorEventRequestsQuery);

  const [snackOptions, setSnackOptions] = useState({
    open: false,
    text: "",
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOptions({
      open: false,
    });
  };

  const actions = [
    {
      icon: <PaymentIcon />,
      onClick: (id, row) => {
        setModalData({
          open: true,
          id: id,
          price: row.space_price,
          name: row.space_name,
        });
      },
      color: () => "green",
      isDisabled: (row) =>
        row.payment?.status === "SUCCESS" ||
        row.status === "PENDING" ||
        row.status === "REJECTED",
      tooltip: (row) => {
        if (row.status === "PENDING") {
          return "Waiting for organizer response";
        }
        if (row.status === "REJECTED") {
          return "Organizer rejected your request";
        }
        if (row.payment?.status === "SUCCESS") {
          return "Already paid";
        }

        return "Complete payment";
      },
    },
  ];

  return (
    <Box sx={{ width: "96%", overflowX: "hidden", py: 4 }}>
      <ServiceProviderRequestsHeader
        subHeader={"Requests to participate on events as a service provider"}
        header={"Service Requests"}
        showAllTime={true}
      />
      <EnhancedDataTable
        headCells={headCells}
        isLoading={isLoading}
        rows={data}
        rowCount={rowCount}
        manualPagination={true}
        pagination={pagination}
        onPaginationChange={setPagination}
        actions={actions}
        // toolbarActions={<ToolbarActions />}
        allowExport={false}
        showIcon={false}
        editIcon={false}
        deleteIcon={false}
        searchProps={{ searchValue, setSearchValue, showSearch, setShowSearch }}
      />

      <CheckoutModal modalData={modalData} setModalData={setModalData} />

      <DefaultSnackbar options={snackOptions} handleClose={handleClose} />
    </Box>
  );
};

export default ServiceProviderRequests;
