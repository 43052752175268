import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";


const StyledHeader = styled(Box)(({}) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const VendorRequestsHeader = () => {
  return (
    <StyledHeader sx={{ pb: 2, color: "primary.main" }}>
      <Stack spacing={0.5}>
        <Stack
          direction="row"
          spacing={1}
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          <Typography>Requests to participate on events as a vendor</Typography>
        </Stack>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            alignItems: "center",
          }}
        >
          <Typography
            variant="h1"
            fontWeight={700}
            fontSize={26}
            sx={{ alignSelf: "flex-end" }}
            color="primary.main"
          >
            Vendor requests
          </Typography>
        </Box>
      </Stack>
      <Box>
        <Typography
          variant="body"
          fontSize={12}
          fontWeight={700}
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          All time
        </Typography>
      </Box>
    </StyledHeader>
  );
};

export default VendorRequestsHeader;
