import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import useLocalStorage from "hooks/useLocalStorage";

import { useModal } from "contexts/modalContext";
import EnhancedDataTable from "components/EnhancedDataTable";
import StyledToolbarButton from "components/styled-components/StyledToolbarButton";
import AddIcon from "@mui/icons-material/Add";
import BulkInviteDialog from "components/formModals/Invitaions/BulkInviteDialog";
import ImportAttendeesDialog from "components/formModals/ImportAttendeesDialog";
import { useModuleAccess } from "hooks/useModuleAccess";
import AssignUser from "components/formModals/AssignUserModal";

import DefaultSnackbar from "components/DefaultSnackbar";
import { useSearchParams } from "react-router-dom";
import { useGetOrdersQuery } from "features/orders/ordersApiSlice";

import useGetData from "hooks/useGetData";
const headCells = [
  {
    id: "ticket_name",
    numeric: false,
    disablePadding: true,
    label: "Ticket",
  },
  {
    id: "quantity",
    numeric: true,
    disablePadding: true,
    label: "Quantity",
    maxSize: 14,
  },
  // {
  //   id: "event_schedule",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Event Schedule",
  //   Cell: ({ cell }) => {
  //     const { start_date, start_time, end_time, name } = cell.getValue() || {};
  //     return (
  //       <div>
  //         <div>Name: {name}</div>
  //         <div>
  //           Start: {start_date} {start_time}
  //         </div>
  //         <div>End: {end_time}</div>
  //       </div>
  //     );
  //   },
  // },
  {
    id: "ticket_price",
    numeric: true,
    disablePadding: true,
    label: "Ticket price",
    maxSize: 14,
  },

  {
    id: "order_id",
    numeric: false,
    disablePadding: true,
    label: "System order ID",
  },
  {
    id: "buyer_name",
    numeric: false,
    disablePadding: true,
    label: "Buyer",
  },
  {
    id: "buyer_phone",
    numeric: false,
    disablePadding: true,
    label: "Phone",
  },
  {
    id: "buyer_email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "promo_code",
    numeric: false,
    disablePadding: true,
    label: "Promo",
    Cell: ({ cell }) => (
      <>
        {cell.getValue() &&
          `${cell.getValue().code} (${cell.getValue().discount_rate * 100}%)`}
      </>
    ),
  },
  {
    id: "payment_method",
    numeric: false,
    disablePadding: true,
    label: "Payment method",
    maxSize: 14,
  },
  {
    id: "payment_order_id",
    numeric: false,
    disablePadding: true,
    label: "Payment order ID",
  },
  {
    id: "payment_status",
    numeric: false,
    disablePadding: true,
    label: "Status",
    maxSize: 14,
  },
  {
    id: "order_type",
    numeric: false,
    disablePadding: true,
    label: "Order type",
  },
  {
    id: "date_created_parsed",
    numeric: false,
    disablePadding: true,
    label: "Date",
  },
];

const ToolbarActions = ({ disabled, onClick }) => {
  const { toggleModal } = useModal();
  const invitationAccess = useModuleAccess("INVITATION");
  // const navigate = useNavigate();

  return (
    <Box sx={{ display: "flex", gap: "1rem", p: "0.5rem", flexWrap: "wrap" }}>
      {" "}
      {invitationAccess.create && (
        <StyledToolbarButton
          disabled={disabled}
          onClick={onClick}
          startIcon={<AddIcon />}
        >
          <Typography variant="p">Invite Selected</Typography>
        </StyledToolbarButton>
      )}
    </Box>
  );
};

const ScheduleOrder = () => {
  let [searchParams] = useSearchParams();
  let eventId = searchParams?.get("event_id");
  let ScheduleId = searchParams?.get("scheduleId");
  console.log(eventId);
  console.log(ScheduleId);

  const { showModal, toggleModal } = useModal();
  const [accessToken] = useLocalStorage("access", "");

  const {
    isLoading,
    pagination,
    setPagination,
    rowCount,
    data: orders,
    searchValue,
    setSearchValue,
    showSearch,
    setShowSearch,
    refetch,
  } = useGetData(true)(
    useGetOrdersQuery,
    // showSuccessful
    {
      event_id: eventId,
      group_by_system_order_id: false,
      event_schedule_id: ScheduleId,
      payment_status: "SUCCESS",
    }
    // : {
    //     payment_status: "SUCCESS",
    //     event_id: eventId,
    //     group_by_system_order_id: false,
    //   }
  );
  console.log(orders);
  const [importDialog, setImportDialog] = useState(false);

  const [selected, setSelected] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const sendToSelectedDisabled = selected.length === 0;
  const dialogText = `Invite ${selected.length} attendee${
    selected.length === 1 ? "" : "s"
  }`;

  const [snackOptions, setSnackOptions] = useState({
    open: false,
    text: "",
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOptions({
      open: false,
    });
  };

  const parseDeleteBody = (items, password) => {
    const data = { attendee_ids: items, password };
    return data;
  };

  const onDeleteIconClicked = (id) => {
    toggleModal("modal3", {
      action: "delete",
      id,
    });
  };

  // module access
  const moduleAccess = useModuleAccess("CONTACT");

  return (
    <Box>
      <EnhancedDataTable
        headCells={headCells}
        rows={orders}
        rowCount={rowCount}
        manualPagination={true}
        pagination={pagination}
        onPaginationChange={setPagination}
        // isRowDisabled={true}
        editDisabled={true}
        deleteDisabled={true}
        isLoading={isLoading}
        tableType=""
        // toolbarActions={
        //   <ToolbarActions
        //     disabled={sendToSelectedDisabled}
        //     onClick={() => setDialogOpen(true)}
        //   />
        // }
        onSelect={(e) => {
          setSelected(e);
        }}
        editIcon={moduleAccess.update}
        deleteIcon={moduleAccess.delete}
        searchProps={{ searchValue, setSearchValue, showSearch, setShowSearch }}
      />
      {/* {showModal.modal1 && (
        <AddAttendeeModal
          onSucced={() => {
            setSnackOptions({
              open: true,
              text: "Successfully Created Attendee",
            });
          }}
        />
      )} */}
      {/* {showModal.modal3 && (
        <ConfirmDeleteModal
          text="Delete Attendee?"
          action={deleteHandler}
          onSucceed={() => {
            setSnackOptions({
              open: true,
              text: "Successfully Deleted Attendee",
            });
          }}
        />
      )} */}
      <BulkInviteDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        selected={selected}
        text={dialogText}
        onSucceed={() => {
          setSnackOptions({
            open: true,
            text: "Successfully Invited Attendees",
          });
        }}
      />
      {importDialog && (
        <ImportAttendeesDialog
          open={importDialog}
          onClose={() => setImportDialog(false)}
          onImport={() => {
            setSnackOptions({
              open: true,
              text: "Successfully Imported Attendees",
            });
          }}
        />
      )}

      {showModal.modal2 && (
        <AssignUser
          onSucceed={() => {
            setSnackOptions({
              open: true,
              text: "Contact shared successfully",
            });
          }}
        />
      )}

      <DefaultSnackbar options={snackOptions} handleClose={handleClose} />
    </Box>
  );
};

export default ScheduleOrder;
