import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import React from "react";
import { useNavigate } from "react-router-dom";
import ShareIcon from "@mui/icons-material/Share";

const StyledSharedEventsHeader = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "end",
  paddingBottom: "1rem",
}));

const StyledBackButton = styled(KeyboardBackspaceIcon)(({ theme }) => ({
  marginLeft: 16,
  color: theme.palette.primary.main,
  "&:hover": {
    cursor: "pointer",
  },
  marginBottom: "10px",
  fontSize: 30,
}));

const StyledShareIcon = styled(ShareIcon)(({ theme }) => ({
  marginLeft: 16,
  fontSize: 24,
  color: theme.palette.primary.main,
  "&:hover": {
    cursor: "pointer",
  },
}));

const SharedEventsHeader = () => {
  const navigate = useNavigate();
  return (
    <StyledSharedEventsHeader>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          marginTop: "20px",
        }}
      >
        <StyledBackButton onClick={() => navigate(-1)} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            alignItems: "center",
          }}
        >
          <Typography
            ml={2}
            variant="h1"
            fontWeight={700}
            fontSize={22}
            color="primary.main"
          >
            Shared Events
          </Typography>
          <StyledShareIcon />
        </Box>
      </Box>
    </StyledSharedEventsHeader>
  );
};

export default SharedEventsHeader;
