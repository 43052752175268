export const initialModuleAccess = {
  read: false,
  create: false,
  update: false,
  delete: false,
};

export const getViewableModules = (perms, access) => {
  return perms
    ? perms
        .filter((perm) => perm.permissions.includes(access))
        .map((perm) => perm.module_type)
    : [];
};

export const getModuleAccess = (perms, module_type) => {
  const moduleAccess = { ...initialModuleAccess };
  const filtered = perms.filter((perm) => perm.module_type === module_type);
  if (filtered.length === 0) return moduleAccess;

  filtered[0].permissions.forEach((access) => {
    switch (access) {
      case "READ":
        moduleAccess.read = true;
        break;
      case "CREATE":
        moduleAccess.create = true;
        break;
      case "UPDATE":
        moduleAccess.update = true;
        break;
      case "DELETE":
        moduleAccess.delete = true;
        break;
      default:
        break;
    }
  });

  return moduleAccess;
};
