import { useNavigate } from "react-router-dom";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LanguageIcon from "@mui/icons-material/Language";
import { Box, Divider, IconButton, Menu, Typography } from "@mui/material";
import NotificationIndicator from "components/notification/NotificationIndicator";
import NotificationSnippit from "components/notification/NotificationSnippit";
import {
  useGetNotificationsCountQuery,
  useGetNotificationsQuery,
} from "features/notifications/notificationsApiSlice";
import { useGetProfileQuery } from "features/profile/profileApiSlice";
import { parseFilterObj } from "helpers/filtering";
import { useLanguage } from "hooks/useLanguage";
import { useState } from "react";
import { FiPower, FiUser } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../features/auth/authSlice";
import { vendorPages, userPages, adminAsidePages } from "../../menu";
import { StyledMenuItem, StyledMenuItemIcon } from "./HeaderTop";

const User = () => {
  const { data: profile, isLoading: isProfileLoading } = useGetProfileQuery();
  const user = profile ? profile.username : "Unknown";
  const { language, changeLanguage } = useLanguage();
  const languages = [
    { name: "Amharic", code: "text_am" },
    { name: "English", code: "text_en" },
    { name: "KiSwahili", code: "text_sw" },
    { name: "French", code: "text_fr" },
    { name: "Arabic", code: "text_ar" },
  ];

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [userMenu, setUserMenu] = useState(null);
  const [langMenu, setLangMenu] = useState(null);

  const openUserMenu = Boolean(userMenu);
  const openLangMenu = Boolean(langMenu);
  const handleUserClick = (event) => {
    setUserMenu(event.currentTarget);
  };
  const handleUserClose = () => {
    setUserMenu(null);
  };

  const handleLangClick = (event) => {
    setLangMenu(event.currentTarget);
  };
  const handleLangClose = () => {
    setLangMenu(null);
  };

  const handleLogout = () => {
    // dispatch(logout());
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    handleLangClose();
    handleUserClose();
    window.location.replace("/");
    // navigate("/", { replace: true });
    // navigate(userPages.UserLogin.path, { replace: true });
  };

  const handleProfile = () => {
    handleUserClose();
    navigate(userPages.UserProfile.path, { replace: true });
  };

  const handleLanguageChange = (value) => {
    changeLanguage(value);
    handleLangClose();
  };
  return (
    <>
      <Box className="flex m-auto">
        <IconButton
          id="language-button"
          aria-controls={openLangMenu ? "language-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openLangMenu ? "true" : undefined}
          onClick={handleLangClick}
        >
          <LanguageIcon sx={{ color: "white" }} />
        </IconButton>
        <Menu
          id="language-menu"
          anchorEl={langMenu}
          open={openLangMenu}
          onClose={handleLangClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          // anchorOrigin={{
          //   vertical: "bottom",
          //   horizontal: "right",
          // }}
        >
          {languages.map((language) => {
            return (
              <StyledMenuItem
                onClick={() => {
                  handleLanguageChange(language.code);
                  handleLangClose();
                }}
              >
                <Typography>{language.name}</Typography>
              </StyledMenuItem>
            );
          })}
        </Menu>
      </Box>
      <div>
        <Menu
          id="basic-menu"
          anchorEl={userMenu}
          open={openUserMenu}
          onClose={handleUserClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <StyledMenuItem onClick={handleProfile}>
            <StyledMenuItemIcon>
              <FiUser />
            </StyledMenuItemIcon>
            Profile
          </StyledMenuItem>
          <StyledMenuItem onClick={handleLogout}>
            <StyledMenuItemIcon>
              <FiPower />
            </StyledMenuItemIcon>
            Logout
          </StyledMenuItem>
        </Menu>
      </div>
      <Box
        id="basic-button"
        aria-controls={openUserMenu ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openUserMenu ? "true" : undefined}
        onClick={handleUserClick}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          cursor: "pointer",
          textDecoration: "none",
        }}
      >
        <Typography
          variant={"h6"}
          fontWeight={600}
          fontSize={14}
          sx={{ color: "white" }}
        >
          {user}
        </Typography>
        <Box
          component="div"
          sx={{
            color: "primary.main",
            display: "flex",
            alignItems: "center",
            ml: 0.7,
          }}
        >
          <FiUser
            style={{
              fontSize: "16px",
              color: "#fff",
            }}
          />
          <Divider
            color="#fff"
            textAlign="center"
            flexItem={true}
            sx={{ fontSize: "16px" }}
          />
          <KeyboardArrowDownIcon sx={{ color: "white", fontSize: "16px" }} />
        </Box>
      </Box>
    </>
  );
};

const HeaderTopUsers = () => {
  // const dispatch = useDispatch();
  // const isMobileNav = useSelector((state) => state.app.mobileNav);

  return (
    <Box display="flex" justifyContent={"flex-end"}>
      <User />
    </Box>
  );
};

export default HeaderTopUsers;
