import { apiSlice } from "../../app/api/apiSlice";

export const vendorApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVendorEventRequests: builder.query({
      query: (query) => {
        const { pagination, searchValue } = query;
        const { pageIndex, pageSize } = pagination;
        const searchQuery = searchValue ? `&query=${searchValue}` : "";
        return {
          url: `/vendor/requests/?order_by=date_updated&order_by_clause=DESC&page=${pageIndex}&page_size=${pageSize}${searchQuery}`,
        };
      },
      transformResponse: (response) => {
        const { page_metadata, data } = response.data;

        const items = data.map((item) => ({
          ...item,
          price: item.payment?.price,
          order: item.payment?.order_id,
          payment_status: item.payment?.status,
          event_name: item.event_space.event.name,
          space_name: item.event_space.space.name,
          space_price: item.event_space.price,
          space_type: item.event_space.space.space_type,
          space_location_name: item.event_space.space.location_name,
        }));

        return {
          data: items,
          page_metadata,
        };
      },
      providesTags: ["VendorEvent"],
    }),
    applyEvent: builder.mutation({
      query: (body) => ({
        url: "/vendor/request/event/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["VendorEvent"],
    }),
    deleteVendorRequest: builder.mutation({
      query: (body) => ({
        url: "/vendor/request/delete/",
        method: "DELETE",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["VendorEvent"],
    }),
    vendorSpacePay: builder.mutation({
      query: (body) => ({
        url: "/vendor/pay/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["VendorEvent"],
    }),
  }),
});

export const {
  useGetVendorEventRequestsQuery,
  useDeleteVendorRequestMutation,
  useApplyEventMutation,
  useVendorSpacePayMutation,
} = vendorApiSlice;
