import { Box, Skeleton } from "@mui/material";
import React from "react";
import { StyledEventSquareCardWrapper } from "./Styles";

const SquareLoadingEventCard = ({ event }) => {
  return (
    <StyledEventSquareCardWrapper>
      <Skeleton variant="rectangular" width="100%" height={"220px"} />
      <Box sx={{ mt: 1, p: 1 }}>
        <Skeleton variant="text" width="180px" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Skeleton variant="text" width="180px" />
          <Skeleton variant="text" width="40px" />
        </Box>
      </Box>
    </StyledEventSquareCardWrapper>
  );
};

export default SquareLoadingEventCard;
