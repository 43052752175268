import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Modal,
  // TextField,
  Typography,
} from "@mui/material";
import {
  FormikTextField,
  SubmitButton,
} from "components/form-components/CustomFormikFields";
import { FormikDateField, FormikPhoneField } from "../userComponents";
import { Form, Formik } from "formik";
import KeyIcon from "@mui/icons-material/Key";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { useUpdateProfileMutation } from "features/profile/profileApiSlice";
// import EditPassword from "./EditPasswordModal";
import EditPasswordModal from "./EditPasswordModal";
import { subYears } from "date-fns";
import { startOfDay } from "date-fns/esm";
import EditUserEmail from "./editEmailModal";
import EditUserPhone from "./editPhoneModal";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteAccountModal from "./deleteAccount";
import moment from "moment";

export const UserDetails = (props) => {
  const minDate = subYears(startOfDay(new Date()), 18);

  const { user, setSnackOptions } = props;
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [emailEditModal, setEmailEditModal] = useState("");
  const [phoneEditModal, setPhoneEditModal] = useState("");
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);
  const initialValues = {
    username: user?.username,
    first_name: user?.first_name,
    email: user?.email,
    phone_number: user?.phone ? user?.phone : "",
    last_name: user?.last_name,
    date_of_birth: user?.date_of_birth,
  };

  const [update, { isLoading: isUpdateLoading }] = useUpdateProfileMutation();

  const handleSubmit = async (values) => {
    const formData = new FormData();
    if (values.first_name !== "")
      formData.append("first_name", values.first_name);
    if (values.last_name !== "") formData.append("last_name", values.last_name);
    if (values.username !== "") formData.append("username", values.username);
    const date = moment(values.date_of_birth);
    const formattedDate = date.format("YYYY-MM-DD");
    formData.append("date_of_birth", formattedDate);
    try {
      setError(null);
      const res = await update(formData);
      if (res?.error) {
        throw new Error(res.error.data.message);
      }
      setSnackOptions({
        open: true,
        text: "Successfully updated profile details.",
      });
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(props) => (
          <Form>
            <Card>
              <CardHeader
                subheader="User profile edit section"
                title="User Profile"
              />
              <Divider />
              <CardContent>
                <Typography
                  align="center"
                  variant="p"
                  sx={{ mb: 3, color: "red" }}
                >
                  {error}
                </Typography>
                <Grid container spacing={1}>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FormikTextField
                      fullWidth
                      label="Email"
                      name="email"
                      disabled={true}
                    />
                    <Button
                      sx={{ textTransform: "none" }}
                      onClick={() =>
                        setEmailEditModal(user?.email ? "Edit" : "Add")
                      }
                    >
                      {user?.email ? "Edit" : "Add"}{" "}
                      {user?.email ? (
                        <EditIcon color="primary" sx={{ ml: 1 }} />
                      ) : (
                        <AddIcon color="primary" sx={{ ml: 1 }} />
                      )}
                    </Button>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <FormikPhoneField
                      fullWidth
                      margin="dense"
                      label="Phone Number"
                      name="phone_number"
                      id="phone_number"
                      disabled={true}
                    />
                    <Button
                      sx={{ textTransform: "none" }}
                      onClick={() =>
                        setPhoneEditModal(user?.phone ? "Edit" : "Add")
                      }
                    >
                      {user?.phone ? "Edit" : "Add"}{" "}
                      {user?.phone ? (
                        <EditIcon color="primary" sx={{ ml: 1 }} />
                      ) : (
                        <AddIcon color="primary" sx={{ ml: 1 }} />
                      )}
                    </Button>
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormikTextField
                      fullWidth
                      label="First name"
                      name="first_name"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormikTextField
                      fullWidth
                      label="Last name"
                      name="last_name"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormikTextField
                      fullWidth
                      label="Username"
                      name="username"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormikDateField
                      fullWidth
                      margin="dense"
                      minDate={minDate}
                      label={
                        <Typography
                          // color={colors.background.tertiary}
                          fontFamily={"Comfortaa"}
                          fontSize={"14px"}
                          fontWeight={"500"}
                        >
                          Date of Birth
                        </Typography>
                      }
                      name="date_of_birth"
                      id="date_of_birth"
                      required
                    />
                  </Grid>

                  <Grid
                    item
                    md={6}
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      sx={{ textTransform: "none" }}
                      onClick={() => setOpenModal((prev) => !prev)}
                    >
                      Change Password <KeyIcon color="primary" sx={{ ml: 1 }} />
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 1,
                }}
              >
                <Grid container justifyContent={"flex-end"}>
                  <Grid item md={4} xs={12}>
                    <SubmitButton
                      text="Change"
                      isSubmitting={isUpdateLoading}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Divider />
              <CardContent>
                <Typography
                  align="center"
                  variant="p"
                  sx={{ mb: 3, color: "red" }}
                >
                  {error}
                </Typography>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    <Button
                      sx={{ textTransform: "none" }}
                      onClick={() => setDeleteAccountModal((prev) => !prev)}
                      variant="outlined"
                    >
                      <Typography variant="h5">Delete Account </Typography>
                      <DeleteIcon color="red" sx={{ ml: 1, color: "red" }} />
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Form>
        )}
      </Formik>
      <Divider />
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <EditPasswordModal setSnackOptions={setSnackOptions} />
      </Modal>
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={emailEditModal}
        onClose={() => setEmailEditModal("")}
      >
        <EditUserEmail mode={emailEditModal} />
      </Modal>
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={phoneEditModal}
        onClose={() => setPhoneEditModal("")}
      >
        <EditUserPhone mode={phoneEditModal} />
      </Modal>
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={deleteAccountModal}
        onClose={() => setDeleteAccountModal(false)}
      >
        <DeleteAccountModal setSnackOptions={setSnackOptions} />
      </Modal>
    </>
  );
};
