import { authPages } from "../../menu";
import React, { 
  // lazy, useCallback, 
  useEffect, 
  // useState 
} from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import authContents
// , { adminContents } 
from "../../routes/contentRoutes";
import jwtDecode from "jwt-decode";
import { companyType } from "constants/companyTypes";
import { useDispatch } from "react-redux";
import { setRedirect } from "features/routing/routingSlice";

const AuthContentRoutes = () => {
  let { isAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // check if there is a logged in user, check if it's organizer
  const access = localStorage.getItem("access");
  if (access) {
    try {
      const userData = jwtDecode(access);
      if (userData.company_type === companyType.organizer) {
        isAuth = true;
      } else isAuth = false;
    } catch (e) {
      // invalid token
    }
  }

  const authContentsPath = authContents.map((content) => content.path);

  useEffect(() => {
    if (!isAuth) {
      // to being redirect in auth pages
      if (authContentsPath.indexOf(location.pathname) !== -1) {
        return;
      }
      const paths = location.pathname.split("/");
      if (paths.includes(authPages.ResetPassword.path.split("/")[1])) {
        return;
      }
      dispatch(
        setRedirect({ redirectPath: location.pathname + location.search })
      );
      navigate("/login");
    }
  }, [isAuth, location.pathname]);

  return (
    <>
      <Routes>
        {authContents.map((content) => (
          <Route key={content.path} {...content} />
        ))}
      </Routes>
    </>
  );
};

export default AuthContentRoutes;
