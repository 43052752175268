import { usePagination } from "./usePagination";
import { useEffect, useMemo, useState } from "react";
import useLocalStorage from "./useLocalStorage";
import useDebounce from "./useDebounce";
import { parseFilterObj } from "helpers/filtering";

const useGetDataAxios = (query, filters = null, pagination = null) => {
  // access
  const [accessToken] = useLocalStorage("access", "");

  // search values
  const [searchValue, setSearchValue] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchValue, 500);

  // loading indicator
  const [queryLoading, setQueryLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  // pagination
  const [queryPagination, setQueryPagination] = usePagination({
    pagination: pagination || { pageIndex: 0, pageSize: 25 },
  });
  const [searchPagination, setSearchPagination] = usePagination({
    pagination: pagination || { pageIndex: 0, pageSize: 25 },
  });

  //   row count
  const [rowCountQuery, setRowCountQuery] = useState(null);
  const [rowCountSearch, setRowCountSearch] = useState(null);

  // data
  const [queryData, setQueryData] = useState([]);
  const [searchData, setSearchData] = useState([]);

  // query
  const fetchSearch = async () => {
    setSearchLoading(true);
    const res = await query(
      accessToken,
      searchPagination,
      searchValue,
      filters
    );
    setSearchLoading(false);
    setSearchData(res.data);
    setRowCountSearch(res?.page_metadata?.length);
  };

  const fetchQuery = async () => {
    setQueryLoading(true);
    const res = await query(accessToken, queryPagination, null, filters);
    setQueryLoading(false);
    setQueryData(res.data);
    setRowCountQuery(res?.page_metadata?.length);
  };

  useEffect(() => {
    if (!showSearch || debouncedSearchTerm === "") {
      fetchQuery();
    } else if (debouncedSearchTerm !== "") {
      fetchSearch();
    }
  }, [debouncedSearchTerm, queryPagination, searchPagination]);

  //   fetch memo
  // const fetch = useMemo(() => {
  //   if (!showSearch || searchValue === "") {
  //     return fetchQuery;
  //   } else {
  //     return fetchSearch;
  //   }
  // }, [showSearch, debouncedSearchTerm]);

  return {
    isLoading:
      !showSearch || debouncedSearchTerm === "" ? queryLoading : searchLoading,
    pagination:
      !showSearch || debouncedSearchTerm === ""
        ? queryPagination
        : searchPagination,
    setPagination:
      !showSearch || debouncedSearchTerm === ""
        ? setQueryPagination
        : setSearchPagination,
    rowCount:
      !showSearch || debouncedSearchTerm === ""
        ? rowCountQuery
        : rowCountSearch,
    data: !showSearch || debouncedSearchTerm === "" ? queryData : searchData,
    searchValue,
    setSearchValue,
    showSearch,
    setShowSearch,
    fetch: !showSearch || debouncedSearchTerm === "" ? fetchQuery : fetchSearch,
  };
};

const useGetDataRtk = (useQuery, filters = null, pagination = null) => {
  // search values
  const [searchValue, setSearchValue] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchValue, 500);

  // pagination
  const [queryPagination, setQueryPagination] = usePagination({
    pagination: pagination || { pageIndex: 0, pageSize: 25 },
  });
  const [searchPagination, setSearchPagination] = usePagination({
    pagination: pagination || { pageIndex: 0, pageSize: 25 },
  });

  const parsedFilters = parseFilterObj(filters);
  console.log('pagination',parsedFilters);

  // fetch data
  const {
    data: rawData,
    isLoading,
    refetch,
  } = useQuery({
    pagination:
      !showSearch || debouncedSearchTerm === ""
        ? queryPagination
        : searchPagination,
    searchValue: showSearch ? debouncedSearchTerm : "",
    filters: parsedFilters,
  });
  console.log('pagination2',rawData);

  const data = rawData ? rawData.data : [];
  const rowCount = rawData ? rawData.page_metadata.length : null;

  return {
    isLoading,
    pagination:
      !showSearch || debouncedSearchTerm === ""
        ? queryPagination
        : searchPagination,
    setPagination:
      !showSearch || debouncedSearchTerm === ""
        ? setQueryPagination
        : setSearchPagination,
    rowCount,
    data,
    searchValue,
    setSearchValue,
    showSearch,
    setShowSearch,
    refetch,
  };
};

const useGetData = (isRtk = true) => {
  if (isRtk) {
    return useGetDataRtk;
  }
  return useGetDataAxios;
};

export default useGetData;
