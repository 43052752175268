import { Close } from "@mui/icons-material";
import { Dialog, IconButton, Typography } from "@mui/material";
import { ModalItemsContainer } from "components/CustomModal/Styles";
import {
  FormikPasswordField,
  FormikTextField,
  SubmitButton,
} from "components/form-components/CustomFormikFields";
import { useImportAttendeesMutation } from "features/Attendees/attendeesApiSlice";
import { Form, Formik } from "formik";
import { useState } from "react";

const ImportAttendeesDialog = ({ open, onClose, onImport }) => {
  const [errorMessage, setErrorMessage] = useState("");

  const [importAttendees, { isLoading: isImportLoading }] =
    useImportAttendeesMutation();

  const handleSubmit = async ({ attendee_data, password }) => {
    const formData = new FormData();
    formData.append("attendee_data", attendee_data);
    formData.append("password", password);
    try {
      const res = await importAttendees(formData);
      if (res?.error) {
        throw new Error(res.error?.data?.message);
      }
      setErrorMessage("");
      onImport();
      onClose();
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <ModalItemsContainer>
        <IconButton
          onClick={() => onClose()}
          sx={{
            alignSelf: "flex-end",
            position: "absolute",
            top: "0",
            right: "0",
            mb: 8,
          }}
        >
          <Close />
        </IconButton>
        <Typography
          align="center"
          variant="p"
          sx={{ mb: 3, fontSize: 20, color: "red" }}
        >
          {errorMessage}
        </Typography>
        <Typography
          align="center"
          variant="h2"
          sx={{ fontWeight: 700, mb: 1, fontSize: 24, color: "primary.main" }}
        >
          Import Contacts
        </Typography>

        <Formik initialValues={{}} onSubmit={handleSubmit}>
          {({ setFieldValue, isSubmitting }) => (
            <Form>
              <FormikTextField
                fullWidth
                margin="dense"
                type="file"
                name="file"
                label=" "
                onChange={(event) => {
                  const selectedFile = event.currentTarget.files[0];

                  setFieldValue("attendee_data", selectedFile);
                  const allowedExtensions = /(\.csv)$/i; // Regular expression to match .csv extension

                  if (
                    selectedFile &&
                    !allowedExtensions.test(selectedFile.name)
                  ) {
                    // Handle error for invalid file type
                    setErrorMessage("Please select a .csv file.");

                    // Reset the input field
                    event.currentTarget.value = null;
                  } else {
                    setFieldValue("attendee_data", selectedFile);
                    setErrorMessage(null);
                  }
                }}
              />
              <FormikPasswordField
                required
                fullWidth
                margin="dense"
                id="password"
                name="password"
                label={"password"}
              />
              <SubmitButton text="Import" isSubmitting={isImportLoading} />
            </Form>
          )}
        </Formik>
      </ModalItemsContainer>
    </Dialog>
  );
};

export default ImportAttendeesDialog;
