import { Box } from "@mui/material";
import Notifications from "pages/shared/Notifications";
import React from "react";

const VendorNotifications = () => {
  return (
    <Box sx={{ width: "100%", py: 2, px: 1 }}>
      <Notifications />
    </Box>
  );
};

export default VendorNotifications;
