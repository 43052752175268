// import { alpha, styled } from "@mui/system";
import { Button } from "@mui/material";

const StyledToolbarButton = ({ children, ...rest }) => {
  return (
    <Button
      variant="contained"
      sx={{ width: "250px", fontWeight: 500, ...rest.sx }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default StyledToolbarButton;
