import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { adminContents } from "routes/contentRoutes";
import { styled } from "@mui/system";
import { Box,Grid } from "@mui/material";
import NotAllowed from "pages/auth/NotAllowed";
import { useGetAllUserAccessQuery } from "features/permissions/permissionsApiSlice";
import { checkRoutePermitted } from "helpers/checkPermissions";
import { adminAsidePages, superAdminPages } from "menu";
import { setPageLoading } from "features/app/appSlice";
import { useDispatch } from "react-redux";
import { filterAsidePages } from "helpers/checkPermissions";


const ContentWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  marginTop: "9.5rem",
  overflowY: "scroll",
}));

const ContentRoutes = () => {
  const contents = adminContents;
  const { role } = useAuth();
  const { data, error, isLoading } = useGetAllUserAccessQuery();
  const [filteredMenu, setFilteredMenu] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (!isLoading)
      setFilteredMenu(filterAsidePages(data ?? [], adminAsidePages, role));
  }, [data, isLoading]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (isLoading) dispatch(setPageLoading(1));
    else dispatch(setPageLoading(-1));
    // clean up
  }, [isLoading]);

  useEffect(() => {
    if (error) {
    if (error.status === "FETCH_ERROR") {
        setErrorMessage("The server is currently unavailable. ");
      }
       else {
        setErrorMessage("An unknown error occurred.");
      }
    }
 
  }, [error]);

  const menuListItems = Object.values(filteredMenu);

  return (
    <>
      <Routes>
        {contents.map(({ element, ...content }) => {
          if (checkRoutePermitted(menuListItems, content.path)) {
            return (
              <Route
                key={content.path}
                {...content}
                element={<ContentWrapper>{element}</ContentWrapper>}
              />
            );
          } else {
            return role === "SUPER_ADMIN" &&
              superAdminPages.includes(content.path) ? (
              <Route
                key={content.path}
                {...content}
                element={<ContentWrapper>{element}</ContentWrapper>}
              />
            ) : (
       
                   <Route
                key={content.path}
                path={content.path}
                exact
                element={<NotAllowed errorMessage={errorMessage} />}
              />
              
           
            );
          }
        })}
      </Routes>
    </>
  );
};

export default ContentRoutes;
