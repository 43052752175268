// import { ErrorOutline } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
// import { colors } from "constants/themeConstants";
// import { FallbackProps } from "react-error-boundary";
// import sorryOwl1 from "../../res/images/sorryOwl1.png";
import sorryOwl2 from "../../res/images/sorryOwl2.jpg";
// import { useEffect, useState } from "react
import SubtitlesOffIcon from '@mui/icons-material/SubtitlesOff';
export default function ErrorPage(props) {
  const { error, resetErrorBoundary } = props;

  const errCode = error?.originalStatus ? error.originalStatus : 203;
  // var errorMessage =
  //   "Something went wrong. Please try again. ";
  // var errorMessage =
  //   "Looks like somethomg went wrong 🤔, Refresh and try again";
  // if ((errCode >= 400) & (errCode < 500)) {
  //   errorMessage =
  //     "Sorry, we're facing some technical issues 🐛 Try again later";
  // }
  // if (errCode >= 500) {
  //   errorMessage = "Owl our engineers are patching up the server 🪛";
  // }
  // if (error.message.includes("blocked")) {
  //   errorMessage =
  //     "Disable that Ad blocker, Refresh the page and try again! 🎉";
  // }
  // if (error.toString().includes("ChunkLoadError")) {
  //   errorMessage =
  //     "Disable that Ad blocker, Refresh the page and try again! 🎉";
  // }
  var errorMessage =
  "Something went wrong. Please try again. ";
  if ((errCode >= 400) & (errCode < 500)) {
    errorMessage =
    "Sorry, OwlEvents isn't working right now";
  } else if (errCode >= 500) {
    errorMessage = "Sorry, Internal Server Error";  }


  return (
    <Box
      className="flex items-center justify-center h-screen"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100dvh"
    >
      <div className="text-center">
        {/* <img
          className="mx-[auto] md:mt-5 md:mb-5"
          width={"120rem"}
          src={
            errCode > 500 ||
            error.toString().includes("ChunkLoadError") ||
            error.message.includes("blocked")
              ? sorryOwl2
              : sorryOwl1
          }
          sx={{ display: { xs: "none", md: "flex" } }}
        /> */}
        <SubtitlesOffIcon sx={{  width:"100px", color:"#DEA449",height:"100px"}}/>
        {/* {!html ? ( */}
        <Typography
          mx={"25rem"}
          my={"1rem"}
          fontFamily={"Campton"}
          fontSize={"2rem"}
          fontWeight={900}
          color="#DEA449"
          
        >
          oops!
        </Typography>
        <Typography
          mx={"25rem"}
          my={"1rem"}
          fontFamily={"Campton"}
          fontSize={"1.4rem"}
          fontWeight={300}
          color="#DEA449"
        >
          {errorMessage}
        </Typography>

        <Button
          variant="text"
          style={{
            color: "#DEA449",
            padding: 10,
            margin: "0.5rem",
            fontSize: "1.2rem",
            fontFamily: "Campton",
            backgroundColor: "#F2F2F2",
            textTransform: "none",
          }}
          onClick={resetErrorBoundary}
        >
          Refresh
        </Button>
      </div>
    </Box>
  );
}
