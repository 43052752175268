import { Box, Typography,useTheme } from "@mui/material";
// import { useState } from "react";
// import CategoryIcon from "@mui/icons-material/Category";
import SnippitHeader from './Snippit'
import EventsList from "./EventsList";
import TownEventsList from "./TownEventsList";
// import EventsCategoriesHeader from "./EventsCategoriesHeader";
import { useGetPublicEventsQuery } from "features/publicEvents/publicEventsApiSlice";
import { useGetPublicTownEventsQuery } from "features/publicEvents/publicEventsApiSlice";
import { usePagination } from "hooks/usePagination";
const Events = () => {
  // const [currentCat, setCurrentCat] = useState("Entertainment");
  const [pagination] = usePagination();
  const { data: eventsRaw } =
    useGetPublicEventsQuery({
      pagination,
    });
;  const events = eventsRaw
    ? eventsRaw.data.filter((item) => item?.allow_vendors)
    : [];
    const { data: eventsRaws } =
    useGetPublicTownEventsQuery({
        pagination,
      })
    const townevents = eventsRaws? eventsRaws.data.length:[]
// const theme = useTheme();
  return (
    <>
      <Box sx={{ px: 1, py: 2 }}>
        <Box sx={{ mt: 1 }}>
          {/* <EventsCategoriesHeader setCurrentCat={setCurrentCat} /> */}
         {events.length > 0 &&<> 
           <Box textAlign={"center"}>
           <SnippitHeader
                title={" Apply to these Events "}
                title2={""}
            />
            </Box>
          <hr/>
          <SnippitHeader
                title={"Events requiring "}
                title2={" Exibitors"}
            />
          </>}
        </Box>
        <EventsList />
        {/* town events */}
        {townevents > 0 &&<><SnippitHeader
                title={"Check More Events"}
                title2={" In Your Town"}
            />
          </>}
        <TownEventsList/>
        
      </Box>
    </>
  );
};

export default Events;
