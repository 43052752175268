import StorefrontIcon from "@mui/icons-material/Storefront";
import { Box, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { baseUrl } from "app/api/apiSlice";
import { useLanguage } from "hooks/useLanguage";
import { FeaturedBody, FeaturedTitle } from "../FeaturedEventCard/Styles";
import { StyledEventSquareCardWrapper, StyledSquareCardImg } from "./Styles";

const apiUrl = baseUrl;

const SquareEventCard = ({ event }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { language } = useLanguage();

  return (
    <StyledEventSquareCardWrapper>
      <Box
        sx={{
          width: "100%",
          maxHeight: "220px",
          overflow: "hidden",
          position: "relative",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        }}
      >
        <StyledSquareCardImg
          src={`${apiUrl}${event?.image?.image_medium_path}`}
          alt="event images"
          onClick={() => navigate(`/vendors-web/event/?event_id=${event.id}`)}
        />
        <Box sx={{ position: "absolute", top: 20, right: 20 }}>
          {event.allow_vendors && (
            <StorefrontIcon color="primary" sx={{ width: 24, height: 24 }} />
          )}
        </Box>
      </Box>
      <Box sx={{ mt: 2, px: 1, pb: 1 }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <FeaturedTitle>{event.name[language]}</FeaturedTitle>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <FeaturedBody
            overflow="hidden"
            whiteSpace="nowrap"
            width={"200px"}
            textOverflow="ellipsis"
            color={theme.palette.text.secondary}
            sx={{ mr: 1 }}
          >
            {event.location.name}
          </FeaturedBody>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            {event?.tickets.length > 0 && (
              <>
                <FeaturedBody
                  sx={{ color: "primary.main", fontWeight: 500, mr: 0.5 }}
                >
                  ETB
                </FeaturedBody>
                <FeaturedBody sx={{ fontWeight: 500 }}>
                  {Math.min(...event?.tickets.map((item) => item.price))}
                </FeaturedBody>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </StyledEventSquareCardWrapper>
  );
};

export default SquareEventCard;
