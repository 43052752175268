import { Box, Skeleton } from "@mui/material";
import React from "react";
import { StyledEventSquareCardWrapper } from "./Styles";

const SquareLoadingEventCard = ({ event }) => {
  const hrSkeletonStyle = {
    backgroundColor: "lightgray",
    height: "2px", 
    width: "100%",
  };
  return (
    <StyledEventSquareCardWrapper>
      <Skeleton variant="text" width="70%" /> 
      <Box sx={hrSkeletonStyle} />
      <Skeleton variant="text" width="20%" /> 
      <Box
        sx={{
          width: "100%",
          maxHeight: "220px",
          overflow: "hidden",
          position: "relative",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        }}
      >
        <Skeleton variant="rectangular" width="100%" height="160px" /> {/* Use a Skeleton component for the image */}
        <Box sx={{ position: "absolute", top: 20, right: 20 }}>
          {" "}
        </Box>
      </Box>
      <Box sx={{ mt: 2, px: 1, pb: 1 }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Skeleton variant="text" width="100%" /> {/* Use a Skeleton component for the event title */}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Skeleton variant="text" width="70%" /> {/* Use a Skeleton component for the location */}
          <Skeleton variant="text" width="20%" /> {/* Use a Skeleton component for the price */}
        </Box>
      </Box>
    </StyledEventSquareCardWrapper>
  );
};

export default SquareLoadingEventCard;
