
import { Typography,styled } from "@mui/material";

const StyledHeaderText = styled(Typography)(({ theme }) => ({
    marginLeft: 2,
    fontWeight: 700,
    fontSize: 26,
    alignSelf: "flex-end",
    color: theme.palette.primary.main,
  }))
  
  export default StyledHeaderText