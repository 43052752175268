import { styled } from "@mui/system";
import {
  Autocomplete,
  IconButton,
  Modal,
  Paper,
  Typography,
  Grid,
  Stack,
} from "@mui/material";
import { useModal } from "../../contexts/modalContext";
import { Close } from "@mui/icons-material";
import { Form, Formik } from "formik";
import { useState } from "react";
import CustomCheckbox from "../form-components/CustomCheckbox";
import { useGetShareUsersQuery } from "../../features/User/userApiSlice";
import { useCreateSharedAttendeeMutation } from "features/Attendees/sharedAttendeesApiSlice";
import {
  FormikTextField,
  SubmitButton,
} from "components/form-components/CustomFormikFields";

const StyledModalItemsContainer = styled(Paper)(({}) => ({
  position: "relative",
  background: "#fff",
  width: "40rem",
  padding: "3rem",
  display: "flex",
  flexDirection: "column",
  overflow: "scroll",
  maxWidth: "90vw",
  maxHeight: "90vh",
}));

const initialValues = {
  shared_to_id: [],
  update: false,
  delete: false,
};

const AssignUser = (props) => {
  const { showModal, toggleModal, modalPayload } = useModal();
  const [errorMessage, setErrorMessage] = useState("");
  const [createSharedAttendee] = useCreateSharedAttendeeMutation();

  const onSubmit = async (values) => {
    const formData = new FormData();
    formData.append("attendee_id", modalPayload.id);
    formData.append("shared_to_id", values.shared_to_id[0]);

    const permissions = ["READ"];
    values.update && permissions.push("UPDATE");
    values.delete && permissions.push("DELETE");
    permissions.forEach((p) => formData.append("permissions[]", p));

    createSharedAttendee(formData)
      .then((res) => {
        if (res.data) {
          props.onSucceed();
          toggleModal("modal2");
        } else {
          setErrorMessage(res.error.data.message);
        }
      })
      .catch(() => {});
  };
  const [query, setQuery] = useState("");

  const { data: users = [] } = useGetShareUsersQuery(query);

  const onInputChange = async (q) => {
    setQuery(`&username=${q}`);
  };

  return (
    <Modal
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      open={showModal.modal2}
      onClose={() => toggleModal("modal2")}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledModalItemsContainer>
        <Typography
          align="center"
          variant="h2"
          sx={{ fontWeight: 700, mb: 3, fontSize: 24, color: "primary.main" }}
        >
          Share Contact
        </Typography>
        <IconButton
          onClick={() => toggleModal("modal2")}
          sx={{
            alignSelf: "flex-end",
            position: "absolute",
            top: "0",
            right: "0",
            mb: 8,
          }}
        >
          <Close />
        </IconButton>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <Grid item xs={12}>
                <Typography
                  align="center"
                  variant="p"
                  sx={{
                    mb: errorMessage === "" ? 0 : 3,
                    fontSize: 20,
                    color: "red",
                  }}
                >
                  {errorMessage}
                </Typography>
              </Grid>
              <Autocomplete
                multiple
                renderInput={(params) => (
                  <FormikTextField
                    fullWidth
                    margin="dense"
                    id="full_name"
                    name="full_name"
                    {...params}
                    label="User"
                  />
                )}
                options={users}
                getOptionLabel={(item) => item.username}
                onChange={(e, v) => {
                  setFieldValue(
                    "shared_to_id",
                    v.map((v) => v.id)
                  );
                }}
                onInputChange={(e, n) => onInputChange(n)}
              />
              <Stack direction="row" spacing={4}>
                <CustomCheckbox name="update" label="Update" />
                <CustomCheckbox name="delete" label="Delete" />
              </Stack>

              <SubmitButton isSubmitting={isSubmitting} text={"Share"} />
            </Form>
          )}
        </Formik>
      </StyledModalItemsContainer>
    </Modal>
  );
};

export default AssignUser;
