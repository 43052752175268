import { Box } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import VendorRoutes from "routes/VendorRoutes";
import ServiceProviderRoutes from "routes/ServiceProviderRoutes";
import UsersRoute from "routes/UserRoutes";
// import ModalProvider from "../../contexts/modalContext";
import AuthContentRoutes from "../Content/AuthContentRoutes";
import Content from "../Content/Content";
import ContentRoutes from "../Content/ContentRoutes";
import HeaderRoutes from "../Header/HeaderRoutes";

const WrapperBox = styled(Box)(({ theme }) => ({
  flex: "6",
  background: theme.palette.body.secondary,
  height: "100vh",
  width: "70vw",
  position: "relative",

  [theme.breakpoints.down("md")]: {
    width: "40vw",
  },
}));

const Wrapper = () => {
  const path = window.location.pathname;

  return (
    <>
      {path.startsWith("/vendors-web") ? (
        <VendorRoutes />
      ) : path.startsWith("/services-web") ? (
        <ServiceProviderRoutes />
      ) : path.startsWith("/users-web") ? (
        <UsersRoute />
      ) : (
        <WrapperBox>
          <HeaderRoutes />
          <Content>
            <AuthContentRoutes />
            <ContentRoutes />
          </Content>
        </WrapperBox>
      )}
    </>
  );
};

export default Wrapper;
