import { format } from "date-fns";
import { apiSlice } from "../../app/api/apiSlice";

export const ordersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: (query) => {
        const { pagination, searchValue, filters } = query;
        const { pageIndex, pageSize } = pagination;
        const searchQuery = searchValue ? `&query=${searchValue}` : "";
        return {
          url: `/portal/event/orders/?page=${pageIndex}&page_size=${pageSize}${searchQuery}${filters}`,
        };
      },
      transformResponse: (response) => {
        const { page_metadata, data } = response.data;
        const orders = data.map((item) => ({
          ...item,
          ticket_name: item.ticket?.name,
          order_id: item.payment?.system_order_id,
          quantity: item.payment?.quantity,
          buyer_name: item.buyer?.full_name,
          buyer_phone: item.buyer?.phone,
          buyer_email: item.buyer?.email,
          payment_method: item.payment?.payment_method_identifier,
          payment_order_id: item.payment?.order_id,
          payment_status: item.payment?.status,
          date_created_parsed: format(new Date(item.date_created), "PPpp"),
        }));

        return {
          page_metadata,
          data: orders,
        };
      },
      providesTags: ["Order"],
    }),

    createOrder: builder.mutation({
      query: (body) => ({
        url: "/portal/event/order/create/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Order"],
    }),

    resendOrder: builder.mutation({
      query: (body) => ({
        url: "/portal/event/order/resend_ticket/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Order"],
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useCreateOrderMutation,
  useResendOrderMutation,
} = ordersApiSlice;
