import { useLoginMutation } from "features/auth/authApiSlice";
import { logout, setCredentials } from "features/auth/authSlice";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
//material imports
import { Box, Button, Link, Stack, Typography } from "@mui/material";
import { alpha, styled } from "@mui/system";

//component import
import { apiSlice, baseUrl } from "app/api/apiSlice";
import {
  FormikPasswordField,
  FormikTextField,
  SubmitButton,
} from "components/form-components/CustomFormikFields";
import Toast from "components/Toast";
import LoginWrapper from "components/wrappers/LoginWrapper";
import { publicWebUrl } from "constants/api";
import { APP_NAME } from "constants/names";
import { versionName, versionNumber } from "constants/version";
import { setRedirect } from "features/routing/routingSlice";
import { useGetSettingsWithoutAuthQuery } from "features/settings/settingsApiSlice";
import jwtDecode from "jwt-decode";
import { adminAsidePages, authPages, vendorPages, userPages } from "menu";

export const loginSchema = Yup.object().shape({
  identifier: Yup.mixed().required("Email or Phone Number is required"),

  password: Yup.string()
    .required("Password is required")
    .min(6, "Password is too short - should be 6 chars minimum"),
});

export const StyledLink = styled(Link)(({ theme }) => ({
  fontSize: "10px",
  fontWeight: "bold",
  fontStyle: "italic",
  marginTop: "1em",
  alignSelf: "flex-end",
}));

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "backgroundColor",
})(({ theme, backgroundColor }) => ({
  backgroundColor: backgroundColor
    ? backgroundColor
    : theme.palette.primary.main,
  color: "#fff",
  fontWeight: "500",
  transition: "all .2s",
  "&:hover": {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.5),
  },
  "&:disabled": {
    backgroundColor: backgroundColor
      ? backgroundColor
      : theme.palette.primary.light,
    color: "white",
  },
}));

const Login = () => {
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [login, { isLoading, error }] = useLoginMutation();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(apiSlice.util.resetApiState());
    dispatch(logout());
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
  };
  useEffect(() => {
    handleLogout();
  }, []);

  const redirectPath = useSelector((state) => state.routing.redirectPath);

  const navigateFn = (role, company_type) => {
    let navUrl;
    if (company_type === "VENDOR") {
      navUrl = vendorPages.VendorHome.path;
      if (redirectPath && role === "VENDOR") {
        for (const page in vendorPages) {
          if (vendorPages.hasOwnProperty(page)) {
            if (vendorPages[page].path === redirectPath) {
              navUrl = redirectPath;
              break;
            }
          }
        }

        dispatch(setRedirect({ redirectPath: null }));
        window.location.replace(navUrl);
        return;
      }
      dispatch(setRedirect({ redirectPath: null }));

      window.location.replace(navUrl);
      return;
    }

    if (role === "ADMIN" || role === "SUPER_ADMIN") {
      navUrl = adminAsidePages.Home.path;
    }
    // if (role === "ADMIN") {
    //   navUrl = vendorPages.VendorHome.path;
    // }
    if (role === "PUBLIC_USER") {
      navUrl = userPages.UserHome.path;
    }
    if (redirectPath && role === "PUBLIC_USER") {
      for (const page in userPages) {
        if (userPages.hasOwnProperty(page)) {
          if (userPages[page].path === redirectPath) {
            navUrl = redirectPath;
            break;
          }
        }
      }
      dispatch(setRedirect({ redirectPath: null }));

      window.location.replace(navUrl);
      return;
    }

    if (redirectPath) {
      navUrl = redirectPath;
    }

    dispatch(setRedirect({ redirectPath: null }));

    window.location.replace(navUrl);
    // window.location.replace(navUrl);
    // navigate(navUrl, { replace: true });
  };

  //validate and submit
  const handleSubmit = async (values) => {
    try {
      const userData = await login({
        identifier: values.identifier,
        password: values.password,
      }).unwrap();

      const user_info = jwtDecode(userData.token.access);

      console.log(user_info, "the user the use the use the usethe use ");
      // return;
      //saving refresh token to local storage
      localStorage.setItem("access", userData.token.access);
      localStorage.setItem("refresh", userData.token.refresh);
      dispatch(
        setCredentials({
          accessToken: userData.token.access,
          refreshToken: userData.token.refresh,
          user: user_info.username,
          role: user_info.role_name,
          isAuth: true,
          companyName: user_info?.company_name
            ? user_info?.company_name
            : "public",
          companyType: user_info?.company_type
            ? user_info?.company_name
            : "public",
        })
      );

      navigateFn(user_info.role_name, user_info?.company_type);
    } catch (err) {
      if (!err?.status) {
        // isLoading: true until timeout occurs
        setErrMsg("No Server Response");
      } else if (err.status >= 400) {
        setErrMsg(err.data.message);
      } else {
        setErrMsg("Login Failed");
      }
    }
  };
  const { data: setting } = useGetSettingsWithoutAuthQuery();
  return (
    <LoginWrapper>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <img
            width={64}
            height={64}
            src={baseUrl + setting?.logo?.image_large_path}
            alt="Owl Events"
            sx={{ m: 1 }}

            // sx={{ width: 64, height: 64, m: 1 }}
          />
        </Stack>
        <Typography variant="h6" fontWeight={600}>
          {/* Login as an Event Organizer */}
          Login
        </Typography>
      </Box>
      {error && errMsg && <Toast message={errMsg} />}
      <Formik
        initialValues={{
          identifier: "",
          password: "",
        }}
        validationSchema={loginSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Box component={Form} noValidate width="100%" sx={{ mt: 1 }}>
            <FormikTextField
              required
              fullWidth
              name="identifier"
              id="identifier"
              label="Email or Phone Number"
              // placeholder="example@gmail.com"
            />
            <FormikPasswordField
              required
              fullWidth
              name="password"
              id="password"
              label="Password"
            />
            <Box
              sx={{
                alignSelf: "flex-end",
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <SubmitButton text={"Login"} isSubmitting={isLoading} />

              <StyledLink
                href={`/registerAs`}
                // href={`${publicWebUrl}/companies/register`}
                sx={{
                  fontStyle: "normal",
                  fontFamily: "Comfortaa",
                  fontSize: 16,
                  textDecoration: "none",
                  alignSelf: "flex-start",
                }}
              >
                <Typography variant="h6" fontWeight={600}>
                  Don't have an account? Register
                </Typography>
              </StyledLink>
              <StyledLink href={authPages.ForgotPassword.path} sx={{ mt: 0 }}>
                <Typography variant="h6" fontWeight={600}></Typography>
                Forgot your password?
              </StyledLink>
              {/* <StyledLink href={vendorPages.VendorLogin.path}>
                <Typography variant="body" fontWeight={600}>
                  Login to vendors portal
                </Typography>
              </StyledLink> */}
              {/* <StyledLink href={servicesPages.ServicesLogin.path}>
                Login to service providers portal
              </StyledLink> */}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 4,
                }}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="center"
                >
                  {APP_NAME} v{`${versionNumber}`} {`${versionName}`}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="center"
                >
                  Powered by AWURA
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Formik>
    </LoginWrapper>
  );
};

export default Login;
