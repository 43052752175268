import { useTheme } from "@mui/material";
import { Link } from "react-router-dom";

const CustomLink = ({ children, ...props }) => {
  const theme = useTheme();
  return (
    <Link
      to={props.to}
      style={{ textDecoration: "none", color: theme.palette.text.primary }}
    >
      {children}
    </Link>
  );
};
export default CustomLink;
