import * as React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import jwtDecode from "jwt-decode";

import { useDispatch, useSelector } from "react-redux";
import { Stack, useTheme } from "@mui/material";
import { Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import { apiSlice, baseUrl } from "app/api/apiSlice";
import {
  FormikPasswordField,
  FormikTextField,
  SubmitButton,
} from "components/form-components/CustomFormikFields";
import Toast from "components/Toast";
import VendorWrapper from "components/wrappers/LoginWrapper/VendorWrapper";
import { publicWebUrl } from "constants/api";
import { APP_NAME } from "constants/names";
import { versionName, versionNumber } from "constants/version";
import { useLoginMutation } from "features/auth/authApiSlice";
import { logout, setCredentials } from "features/auth/authSlice";
import { setRedirect } from "features/routing/routingSlice";
import { useGetSettingsWithoutAuthQuery } from "features/settings/settingsApiSlice";
import { userLoginFn } from "helpers/userLoginFn";
import { authPages, userPages, vendorPages } from "menu";
import { loginSchema, StyledLink } from "pages/auth/Login";
import * as yup from "yup";

// const validationSchema = yup.object({
//   identifier: yup.mixed().required("Email or Phone Number is required"),
//   password: yup
//     .string("Enter your password")
//     .min(6, "Min length must be 6")
//     .required("Password is required"),
// });

// const initialValues = {
//   identifier: "",
//   password: "",
// };

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // const from = location.state?.from?.pathname || vendorPages.VendorHome.path;
  const [errorText, setErrorText] = React.useState(null);
  // const theme = useTheme();
  const [login, { isLoading }] = useLoginMutation();

  const redirectPath = useSelector((state) => state.routing.redirectPath);
  const handleLogout = () => {
    dispatch(apiSlice.util.resetApiState());
    dispatch(logout());
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
  };
  React.useEffect(() => {
    handleLogout();
  }, []);

  const navigateFn = (url) => {
    // let navUrl = userPages.UserProfile.path;
    let navUrl = url;

    if (redirectPath) {
      navUrl = redirectPath;
    }
    dispatch(setRedirect({ redirectPath: null }));
    window.location.replace(url);
    // navigate(url, { replace: true });
  };

  // const handleSubmit = async (values, { setSubmitting, setErrors }) => {
  //   // setSubmitting(true);
  //   // // login
  //   // loginFn({
  //   //   login,
  //   //   values,
  //   //   dispatch,
  //   //   setCredentials,
  //   //   navigate: navigateFn,
  //   //   setErrorText,
  //   //   setSubmitting,
  //   // });
  // };
  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      // const userData = await login({
      //   identifier: values.identifier,
      //   password: values.password,
      // }).unwrap();
      // console.log(userData, "the user data$$&&%^&^*^ #@$#^");
      // const user_info = jwtDecode(userData.token.access);
      // console.log(user_info, "Decoded data $*&&(*)()( %^*&67");
      // //saving refresh token to local storage
      // localStorage.setItem("access", userData.token.access);
      // localStorage.setItem("refresh", userData.token.refresh);
      // dispatch(
      //   setCredentials({
      //     accessToken: userData.token.access,
      //     refreshToken: userData.token.refresh,
      //     user: user_info.username,
      //     role: user_info.role_name,
      //     isAuth: true,
      //     // companyName: user_info.company_name,
      //     // companyType: user_info.company_type,
      //   })
      // );
      // // window.location.replace("/users-web/profile");

      // navigateFn();
      userLoginFn({
        login,
        values,
        dispatch,
        setCredentials,
        navigate: navigateFn,
        setErrorText,
        setSubmitting,
      });
    } catch (err) {
      if (!err?.status) {
        // isLoading: true until timeout occurs
        setErrorText("No Server Response");
      } else if (err.status >= 400) {
        setErrorText(err.data.message);
      } else {
        setErrorText("Login Failed");
      }
    }
  };
  const { data: setting } = useGetSettingsWithoutAuthQuery();
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <Box */}
      <Card
        sx={{
          width: { xs: "95%", md: "50%", lg: "40%" },
          px: 5,
          py: 3,
          margin: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <img
              width={64}
              height={64}
              src={baseUrl + setting?.logo?.image_large_path}
              alt="Owl Events"
              sx={{ m: 1 }}
            />
          </Stack>
          <Typography variant="h6" fontWeight={600}>
            Login as a User
          </Typography>
        </Box>
        {errorText && <Toast message={errorText} />}
        <Formik
          initialValues={{
            identifier: "",
            password: "",
          }}
          validationSchema={loginSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Box component={Form} noValidate width="100%">
              <FormikTextField
                required
                fullWidth
                name="identifier"
                id="identifier"
                label="Email or Phone Number"
                // placeholder="example@gmail.com"
              />
              <FormikPasswordField
                required
                fullWidth
                name="password"
                id="password"
                label="Password"
              />
              <Box
                sx={{
                  alignSelf: "flex-end",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <SubmitButton text={"Login"} isSubmitting={isLoading} />
                <StyledLink
                  href={`/users-web/register`}
                  sx={{
                    fontStyle: "normal",
                    fontFamily: "Comfortaa",
                    fontSize: 16,
                    textDecoration: "none",
                    alignSelf: "flex-start",
                  }}
                >
                  Don't have an account? Register
                </StyledLink>
                <StyledLink href={"/users-web/forgot-password"} sx={{ mt: 0 }}>
                  Forgot your password?
                </StyledLink>
                <StyledLink href={authPages.Login.path}>
                  {/* Login to event organizers portal */}
                  Login to users portal
                </StyledLink>
                {/* <StyledLink href={servicesPages.ServicesLogin.path}>
                Login to service provider portal
              </StyledLink> */}
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 4,
                  }}
                >
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center"
                  >
                    {APP_NAME} v{`${versionNumber}`} {`${versionName}`}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center"
                  >
                    Powered by AWURA
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Formik>
      </Card>
    </Box>
  );
};

export default Login;
