const { ArrowBack, ArrowForward } = require("@mui/icons-material");
const { Button, Typography } = require("@mui/material");

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Button
          type="submit"
          variant="contained"
          sx={{ m: 1 }}
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 0}
        >
          <ArrowBack />
        </Button>
        <span>
          <Typography>
            Page {currentPage + 1} of {totalPages}
          </Typography>
        </span>
        <Button
          type="submit"
          variant="contained"
          sx={{ m: 1 }}
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage + 1 === totalPages}
        >
          <ArrowForward />
        </Button>
      </div>
    );
  };
  export default Pagination