import React, { useState } from "react";
import FormStep from "./FormStep";
import MuiCustomStepper from "./MuiCustomStepper";
import * as yup from "yup";
import PaymentForm from "./PaymentForm";
import Review from "./Review";
import { useNavigate } from "react-router-dom";
import DefaultSnackbar from "components/DefaultSnackbar";
import { useVendorSpacePayMutation } from "features/vendorEvent/vendorEventApiSlice";

const validationSchema = yup.object({
  pMethod: yup
    .string("Choose a payment method")
    .required("payment method is required"),
});

const Checkout = ({ id, price, name, setModalData }) => {
  const [error, setError] = useState(null);
  const [snackOptions, setSnackOptions] = useState({ open: false, text: "" });
  const navigate = useNavigate();
  const [snapshot, setSnapshot] = useState({
    price: price,
    quantity: 1,
    pMethod: "",
  });
  const [isSubmittingg, setIsSubmitting]= useState(false)
  const [create] = useVendorSpacePayMutation();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOptions({
      open: false,
    });
  };

  const handleSubmit = async (values, setSubmitting) => {
    // setError(null);

    try {
      
      // const formData = new FormData();

      // formData.append("vendor_event_request_id", id);
      // formData.append("payment_method_identifier", values.pMethod);
      // console.log('jhsekfbksef',formData)
      setIsSubmitting(true)

      const res = await create({"vendor_event_request_id": id,"payment_method_identifier":values.pMethod});

      setIsSubmitting(false)
      setSubmitting(false);
      if (res?.error) {
        if (res.error.originalStatus === 401) {
          throw new Error("Please login first to make a payment.");
        }
        throw new Error(res.error.data.message);
      }
      setSnackOptions({ open: true, text: "Successfully Placed an order." });
      setTimeout(
        () => window.location.replace(res.data.data.checkout_url),
        2000
      );
    } catch (e) {
      setError(e.message);
    }
  };

  return (
    <>
      <MuiCustomStepper
        desc={`${name} space payment`}
        title="Checkout"
        onBack={() => setModalData((prev) => ({ ...prev, open: false }))}
        snapshot={snapshot}
        setSnapshot={setSnapshot}
        isSubmitting={isSubmittingg}
        error={error}
      >
        <FormStep validationSchema={validationSchema} stepName="Payment info">
          <PaymentForm price={price} />
        </FormStep>
        <FormStep
          validationSchema={validationSchema}
          stepName="Review"
          onSubmit={handleSubmit}
        >
          <Review snapshot={{ ...snapshot, reason: `${name} space payment` }} />
        </FormStep>
      </MuiCustomStepper>
      <DefaultSnackbar options={snackOptions} handleClose={handleClose} />
    </>
  );
};

export default Checkout;
