import React, { useState, useEffect } from "react";
import {
  // Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

// import { useDeleteAccountMutation } from "features/auth/authApiSlice";
import { useGetProfileQuery } from "features/profile/profileApiSlice";
import { StyledButton } from "pages/auth/Login";
import {
  useGetActiveSubscriptionPaymentQuery,
  // useGetSubscriptionPaymentHistoryQuery,
  // useSendCheckoutURLMutation,
  // useSendUnsubscribeMutation,
  useSendUnsubscribeRequestMutation,
} from "features/subscription/subscriptionApiSlice";
import { useNavigate } from "react-router-dom";
import UpdateSubscriptionModal from "components/formModals/settings/UpdateSubscriptionModal";
import CancelSubscriptionModal from "components/formModals/settings/CancelSubscriptionModal";
import ConfirmPasswordDialog from "components/formModals/ConfirmPasswordDialog";
// import RefundPolicy from "components/formModals/settings/RefundPolicy";

const Subscription = () => {
  // const { data: profile, isLoading: isProfileLoading } = useGetProfileQuery();
  const { data: subscription, isLoading: isSubscriptionLoading } =
    useGetActiveSubscriptionPaymentQuery();
  const [unsubscribeOTP, { isLoading: isUnsubscribeOTPLoading }] =
    useSendUnsubscribeRequestMutation();

  const [date, setDate] = useState("");
  // const [checkoutURL, setCheckoutURL] = useState();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const [otpActive, setOtpActive] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openCancel, setOpenCancel] = useState(false);
  const handleOpenCancel = () => {
    setOpenPassword(true);
  };
  const handleRequestOtp = async () => {
    const res = await unsubscribeOTP({
      payment_plan_id: subscription?.payment_plan?.id,
      payment_method_identifier:
        subscription?.payment?.payment_method_identifier,
    });
    if (res?.error) {
      setOtpActive(true);
    }
    setOpenCancel(true);
  };
  const handleCloseCancel = () => setOpenCancel(false);

  useEffect(() => {
    const formattedDate = subscription?.ends_time;
    const format = new Date(formattedDate);
    setDate(format.toString().split("T")[0]);
  }, [subscription]);

  return (
    <Card sx={{ mt: 1 }}>
      <CardHeader title="Subscription" />
      <Divider />
      <CardContent>
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          gap={2}
        >
          <Grid display="flex" flexDirection={"column"} gap={2} item>
            <Typography variant="h5" fontWeight={600}>
              Subscription Plan:{" "}
              <Box component="span" color={"primary.main"}>
                {subscription?.payment_plan?.name}
              </Box>
            </Typography>
            <Typography varient="p">
              Duration:{" "}
              <Box component="span" color={"primary.main"} fontWeight={"600"}>
                {subscription?.payment_plan?.duration}{" "}
                {subscription?.payment_plan?.duration_type}
              </Box>
            </Typography>
          </Grid>
          <Grid item display="flex" gap={2}>
            <Button
              varient="outlined"
              style={{
                border: "#C84848 1px solid",
                height: "fit-content",
                color: "#C84848",
              }}
              onClick={handleOpenCancel}
            >
              Cancel Subscription
            </Button>
            <StyledButton
              style={{ height: "fit-content" }}
              onClick={handleOpen}
            >
              Upgrade Subscription
            </StyledButton>
          </Grid>
        </Box>
        <Divider sx={{ marginTop: "1rem" }} />
        <Grid
          mt={2}
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <Typography>
            Your next payment is{" "}
            <Box component="span" color={"primary.main"} fontWeight={"600"}>
              {subscription?.payment_plan?.price} ETB
            </Box>
            , to be charged on{" "}
            <Box component="span" color={"primary.main"} fontWeight={"600"}>
              {subscription?.ends_time.split("T")[0]}
            </Box>
          </Typography>
          <StyledButton
            variant="contained"
            onClick={() => navigate("/subscriptionPaymentHistory")}
          >
            Subscription Payment History
          </StyledButton>
        </Grid>
        <UpdateSubscriptionModal
          open={open}
          handleClose={handleClose}
          subscription={subscription}
        />
        <ConfirmPasswordDialog
          open={openPassword}
          onClose={() => setOpenPassword(false)}
          text="You are about to remove your subscription. This will result in the deactivation of your account"
          action={handleOpenCancel}
          onSucceed={() => {
            setOpenPassword(false);
            handleRequestOtp();
          }}
        />
        {/* <RefundPolicy
          open={openCancel}
          setOpenPassword={setOpenPassword}
          setHasAccepted={setHasAccepted}
          handleCloseCancel={handleCloseCancel}
        /> */}
        <CancelSubscriptionModal
          otpActive={otpActive}
          open={openCancel}
          handleClose={handleCloseCancel}
        />
      </CardContent>
    </Card>
  );
};

export default Subscription;
