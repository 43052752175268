import {
      // ArrowDownward,
      // ArrowDropDown,
      // ArrowDropDownOutlined,
      KeyboardArrowDown,
      // KeyboardArrowUp,
    } from "@mui/icons-material";
    import { Box, Typography, useTheme,styled } from "@mui/material";
    import React, { useEffect, useState } from "react";
    // import { useNavigate } from "react-router-dom";
    
    const StyledTitleYellow = styled("span")({
      color: "#DEA449",
      fontSize: "28px",
      fontFamily: "Campton",
      fontWeight: "medium",
      textAlign: "start",
    });
    const SnippitHeader = ({
      title,
      title2 ,
    }) => {
      // const theme = useTheme();
      // const navigate = useNavigate();
      const [windowDimension, setWindowDimension] = useState(null);
    
      useEffect(() => {
        setWindowDimension(window.innerWidth);
      }, []);
    
      useEffect(() => {
        function handleResize() {
          setWindowDimension(window.innerWidth);
        }
    
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);
    
      // const isMobile = windowDimension <= 640;
    
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            // justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
            // ml:8,
            // mr:5
          }}
        >
          <Typography
            fontFamily="Campton"
            fontSize={{ xs: "25px", md: "30px" }}
            color="#333333"
            fontWeight={300}
          >
            {title}
            <StyledTitleYellow sx={{ fontSize: { xs: "25px", md: "30px" } }}>
              {title2}
            </StyledTitleYellow>
          </Typography>
          <KeyboardArrowDown/>
         {/* { showMore?<KeyboardArrowUp
          onClick={showLess}
          sx={{ color: "#333333", ml: 1 }}
        /> :<KeyboardArrowDown
            onClick={handleClick}
            sx={{ color: "#333333", ml: 1 }}
          />} */}
          {/* {(seeAll && !isMobile)  && (
            <Typography
            
              variant="p2"
              sx={{
                color: "primary.main",
                cursor: "pointer",
                transition: "0.5s ease",
                "&:hover": { color: "text.primary" },
                pr: 4,
              }}
              onClick={() => navigate(seeAll)}
            >
              See All
            </Typography>
          )} */}
        </Box>
      );
    };
    
    export default SnippitHeader;
    