const { Typography } = require("@mui/material");
const { styled } = require("@mui/system");

export const FeaturedTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "1rem",
  fontWeight: 600,
}));

export const FeaturedSubTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "0.9rem",
  fontWeight: 400,
}));

export const FeaturedBody = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "0.75rem",
  fontWeight: 300,
}));

export const FeaturedCardImage = styled("img")({
  borderRadius: "10px",
  objectFit: "cover",
  width: "100%",
  height: "265px",
});
