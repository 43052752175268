import { createSlice } from "@reduxjs/toolkit";

const initial = {
  redirectPath: null,
};

const routingSlice = createSlice({
  name: "routing",
  initialState: {
    ...initial,
  },
  reducers: {
    setRedirect: (state, action) => {
      const { redirectPath } = action.payload;
      state.redirectPath = redirectPath;
    },
  },
});

export const { setRedirect } = routingSlice.actions;
export default routingSlice.reducer;
