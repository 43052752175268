import { Box, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import QRCode from "react-qr-code";
import VehiclePass from "../../../res/images/VehiclePassVVIP.jpg";
import VehiclePassBadge from "../../../res/images/VehiclePassBadge.jpg";
import { StyledBalckTypo } from "../attendeecard/Card";

import AttendeeImg from "../../../res/images/attendee.png";

import moment from "moment";

import { baseUrl } from "../../../app/api/apiSlice";

const StyledContainer = styled(Box)(({}) => ({
  width: "1004px",
  height: "694px",
  padding: 15,
}));

const StyledImage = styled("img")(({ theme }) => ({
  width: 200,
  height: 200,
  border: `3px solid ${theme.palette.primary.main}`,
  borderRadius: "50%",
}));

export const EmptyCard = (props) => {
  return <StyledContainer></StyledContainer>;
};

function AttendeeCard({ name, bgImg, ticket, sponsors, invitation, event }) {
  return (
    <StyledContainer
      sx={{
        backgroundImage: !bgImg ? `url(${VehiclePassBadge})` : `url(${bgImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% auto",
      }}
    >
      <Box ml={6} mt={6}>
        <StyledBalckTypo variant="h3" fontWeight={500}>
          {event?.name}
        </StyledBalckTypo>
        {event?.start_date === event?.end_date ? (
          <StyledBalckTypo fontSize={14}>
            {moment(event?.start_date).format("MMM DD")}
          </StyledBalckTypo>
        ) : (
          <StyledBalckTypo>
            {" "}
            {moment(event?.start_date).format("MMM DD")} -{" "}
            {moment(event?.end_date).format("MMM DD")}
          </StyledBalckTypo>
        )}
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <StyledImage
          src={
            invitation?.image
              ? baseUrl + invitation?.image.image_medium_path
              : AttendeeImg
          }
          alt=""
        />
      </Box>
      <Typography
        style={{
          textShadow:
            "-1px 1px 0 #000, 1px 1px 0 #000,1px -1px 0 #000, -1px -1px 0 #000",
        }}
        fontWeight={700}
        fontSize={34}
        color="#fff"
        ml={8}
      >
        {invitation?.is_vip ? "VIP" : "REGULAR"}
      </Typography>

      <Box sx={{ display: "flex" }} pb={10}>
        {ticket != null && (
          <QRCode
            size={256}
            style={{
              height: "auto",
              maxWidth: "90%",
              marginLeft: "43px",
              width: "200px",
              backgroundColor: "#fff",
              padding: 10,
              border: "1px solid #000",
            }}
            value={ticket}
            viewBox={`0 0 256 256`}
          />
        )}
        <Box pt={2} pl={2}>
          {[invitation?.name].map((item) => (
            <StyledBalckTypo pb={3} fontWeight={600} variant="h3" fontSize={28}>
              {item}
            </StyledBalckTypo>
          ))}
        </Box>
      </Box>
      <StyledBalckTypo textAlign="center" mb={"15px"}>
        {event?.location?.name}
      </StyledBalckTypo>
    </StyledContainer>
  );
}

export default AttendeeCard;
