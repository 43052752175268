import {
  // Box,
  // Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { InnerPages } from "menu";
import { StyledButton } from "pages/auth/Login";
import { useNavigate } from "react-router-dom";

const ProfilePayment = (props) => {
  // const { setSnackOptions } = props;

  const navigate = useNavigate();

  return (
    <Card sx={{ mt: 1 }}>
      <CardHeader title="Payment" subheader="Company payment details" />
      <Divider />
      <CardContent>
        <Grid container spacing={1}>
          <Grid
            item
            md={8} 
            xs={12}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography fontWeight={400} >
              Show payment history
            </Typography>
          </Grid>

          <Grid
            item
            md={4}
            xs={12}
            display="flex"
          >
             <StyledButton
             fullWidth
              style={{ height: "fit-content",width: "100%" }}
              onClick={() => {
                navigate(InnerPages.PaymentHistory.path);
              }}
            >
 Show history            </StyledButton>
            {/* <Button
              variant="outlined"
              color="primary"
             
            >
             
            </Button> */}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProfilePayment;
