import { apiSlice } from "../../app/api/apiSlice";

export const permissionsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllPermissions: builder.query({
      query: (query) => {
        const { pagination, searchValue } = query;
        const { pageIndex, pageSize } = pagination;
        const searchQuery = searchValue ? `&query=${searchValue}` : "";
        return {
          url: `/portal/permission/role/?page=${pageIndex}&page_size=${pageSize}${searchQuery}`,
        };
      },
      transformResponse: (response) => {
        const { page_metadata, data } = response;
        const permissions = data.map((item) => ({
          id: `${item.module_type}${item.role_id}`,
          role_name: item.role_name,
          role_id: item.role_id,
          module_type: item.module_type,
          permissions: item.permissions,
          read: item.permissions.includes("READ"),
          delete: item.permissions.includes("DELETE"),
          update: item.permissions.includes("UPDATE"),
          create: item.permissions.includes("CREATE"),
        }));
        return {
          data: permissions,
          page_metadata,
        };
      },
      providesTags: ["Permission"],
    }),
    getPermisionTypes: builder.query({
      query: (arg) => ({
        url: "/portal/permission/type/",
      }),
      transformResponse(response) {
        return response;
      },
    }),
    getAllUserAccess: builder.query({
      query: (arg) => ({
        url: "/portal/permission/role/user/",
      }),
      transformResponse(response) {
        return response;
      },
      providesTags: ["ModuleAccess"],
    }),
    createPermission: builder.mutation({
      query: (body) => ({
        url: "/portal/permission/role/manage/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Permission"],
    }),
    deletePermission: builder.mutation({
      query: (body) => ({
        url: "/portal/permission/role/delete/",
        method: "DELETE",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Permission"],
    }),
  }),
});

export const {
  useGetAllPermissionsQuery,
  useGetPermisionTypesQuery,
  useGetAllUserAccessQuery,
  useCreatePermissionMutation,
  useDeletePermissionMutation,
} = permissionsApiSlice;
