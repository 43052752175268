import { Box } from "@mui/material";
import Profile from "pages/adminPages/Profile/index";
import React from "react";

const VendorProfile = () => {
  return (
    <Box
      sx={{
        width: "100%",
        px: 1,
        mt: 2,
      }}
    >
      <Profile />
    </Box>
  );
};

export default VendorProfile;
