import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendarOutlined";
import { useNavigate, useSearchParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const StyledBackButton = styled(KeyboardBackspaceIcon)(({ theme }) => ({
  marginLeft: 16,
  color: theme.palette.primary.main,
  "&:hover": {
    cursor: "pointer",
  },
  marginBottom: 2,
}));

const ScheduleOrderHeader = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const Schedulename = searchParams?.get("schedule-name");
  const startdate = searchParams?.get("start_date");
  const starttime = searchParams?.get("start_time");
  const endtime = searchParams?.get("end_time");
  console.log(startdate);
  console.log(starttime);
  console.log(endtime);

  const StyledHeader = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "1rem",
    paddingRight: "10px",
  }));

  const StyledAtendeesIcon = styled(PermContactCalendarIcon)(({ theme }) => ({
    marginLeft: 16,
    fontSize: 24,
    color: theme.palette.primary.main,
    "&:hover": {
      cursor: "pointer",
    },
  }));

  return (
    <StyledHeader>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "center",
          marginTop: 5,
        }}
      >
        <StyledBackButton onClick={() => navigate(-1)} />
        <Typography
          ml={2}
          variant="h1"
          fontWeight={700}
          fontSize={26}
          sx={{ alignSelf: "flex-end" }}
          color="primary.main"
        >
          {Schedulename ? Schedulename : "Schedule Orders"}
        </Typography>
        <Typography
          ml={2}
          variant="h1"
          fontWeight={500}
          fontSize={14}
          sx={{ alignSelf: "flex-end" }}
          color="primary.main"
        >
          Start Date: {startdate ? startdate : "Start Date"}
        </Typography>{" "}
        <Typography
          ml={2}
          variant="h1"
          fontWeight={500}
          fontSize={14}
          sx={{ alignSelf: "flex-end" }}
          color="primary.main"
        >
          Start Time: {starttime ? starttime : "Start Time"}
        </Typography>{" "}
        <Typography
          ml={2}
          variant="h1"
          fontWeight={500}
          fontSize={14}
          sx={{ alignSelf: "flex-end" }}
          color="primary.main"
        >
          End Time: {endtime ? endtime : "End Time"}
        </Typography>
      </Box>
      {/* <StyledAtendeesIcon /> */}
    </StyledHeader>
  );
};

export default ScheduleOrderHeader;
