import * as React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useDispatch, useSelector } from "react-redux";
// import { login } from "features/auth/authSlice";
import {
  Stack,
  // useTheme
} from "@mui/material";
import { Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import { apiSlice, baseUrl } from "app/api/apiSlice";
import {
  FormikPasswordField,
  FormikTextField,
  SubmitButton,
} from "components/form-components/CustomFormikFields";
import Toast from "components/Toast";
import ServicesProviderWrapper from "components/wrappers/LoginWrapper/ServicesProviderWrapper";
import { publicWebUrl } from "constants/api";
import { APP_NAME } from "constants/names";
import { versionName, versionNumber } from "constants/version";
import { useLoginMutation } from "features/auth/authApiSlice";
import { logout, setCredentials } from "features/auth/authSlice";
import { setRedirect } from "features/routing/routingSlice";
import { useGetSettingsWithoutAuthQuery } from "features/settings/settingsApiSlice";
import jwtDecode from "jwt-decode";
import { authPages, servicesPages, vendorPages } from "menu";
import { loginSchema, StyledLink } from "pages/auth/Login";
// import * as yup from "yup";

// const validationSchema = yup.object({
//   email: yup
//     .string("Enter your email")
//     .email("Enter a valid email")
//     .required("Email is required"),
//   password: yup
//     .string("Enter your password")
//     .min(6, "Min length must be 6")
//     .required("Password is required"),
// });

// const initialValues = {
//   email: "",
//   password: "",
// };

const ServiceProvidersLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // const from = location.state?.from?.pathname || vendorPages.VendorHome.path;
  // const [errorText, setErrorText] = React.useState(null);
  // const theme = useTheme();
  const [login, { isLoading, error }] = useLoginMutation();

  const redirectPath = useSelector((state) => state.routing.redirectPath);
  const { data: setting } = useGetSettingsWithoutAuthQuery();

  const [errMsg, setErrMsg] = React.useState("");

  const handleLogout = () => {
    dispatch(apiSlice.util.resetApiState());
    dispatch(logout());
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
  };
  React.useEffect(() => {
    handleLogout();
  }, []);

  const navigateFn = () => {
    let navUrl = servicesPages.ServicesHome.path;
    if (redirectPath) {
      navUrl = redirectPath;
    }
    dispatch(setRedirect({ redirectPath: null }));
    navigate(navUrl, { replace: true });
  };

  const handleSubmit = async (values) => {
    try {
      const userData = await login({
        email: values.email,
        password: values.password,
      }).unwrap();

      const user_info = jwtDecode(userData.token.access);
      //saving refresh token to local storage

      localStorage.setItem("access", userData.token.access);
      localStorage.setItem("refresh", userData.token.refresh);

      dispatch(
        setCredentials({
          accessToken: userData.token.access,
          refreshToken: userData.token.refresh,
          user: user_info.username,
          role: user_info.role_name,
          isAuth: true,
          companyName: user_info.company_name,
          companyType: user_info.company_type,
        })
      );

      navigateFn();
    } catch (err) {
      if (!err?.status) {
        // isLoading: true until timeout occurs
        setErrMsg("No Server Response");
      } else if (err.status >= 400) {
        setErrMsg(err.data.message);
      } else {
        setErrMsg("Login Failed");
      }
    }
  };

  return (
    <ServicesProviderWrapper>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <img
            width={64}
            height={64}
            src={baseUrl + setting?.logo?.image_large_path}
            alt="Owl Events"
            sx={{ m: 1 }}
          />
        </Stack>
        <Typography variant="h6" fontWeight={600}>
          Login as a service provider
        </Typography>
      </Box>
      {error && errMsg && <Toast message={errMsg} />}
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={loginSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Box component={Form} noValidate width="100%">
            <FormikTextField
              required
              fullWidth
              name="email"
              id="email"
              label="Email"
              placeholder="example@gmail.com"
            />
            <FormikPasswordField
              required
              fullWidth
              name="password"
              id="password"
              label="Password"
            />
            <Box
              sx={{
                alignSelf: "flex-end",
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <SubmitButton text={"Login"} isSubmitting={isLoading} />
              <StyledLink
                href={`${publicWebUrl}/service_provider/register`}
                sx={{
                  fontStyle: "normal",
                  fontSize: 16,
                  textDecoration: "none",
                  alignSelf: "flex-start",
                }}
              >
                Don't have an account? Register
              </StyledLink>
              <StyledLink href={authPages.ForgotPassword.path} sx={{ mt: 0 }}>
                Forgot your password?
              </StyledLink>
              <StyledLink href={authPages.Login.path}>
                {/* Login to event organizers portal */}
                Login to users portal
              </StyledLink>
              {/* <StyledLink href={vendorPages.VendorLogin.path}>
                Login to vendors portal
              </StyledLink> */}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 4,
                }}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="center"
                >
                  {APP_NAME} v{`${versionNumber}`} {`${versionName}`}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="center"
                >
                  Powered by AWURA
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Formik>
    </ServicesProviderWrapper>
  );
};

export default ServiceProvidersLogin;
