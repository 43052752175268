import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { APP_NAME } from "constants/names";

const containerStyle = {
  position: "fixed",
  zIndex: 10000,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  background: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};

const LoadingPage = () => {
  return (
    <Box style={containerStyle}>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <CircularProgress />

        <Box sx={{ ml: 3 }}>
          <Typography
            color="primary.main"
            variant="h5"
            sx={{ fontWeight: "bold", letterSpacing: "1px" }}
          >
            {APP_NAME}
          </Typography>
          <Typography color="primary.main" variant="p" sx={{ mt: 1 }}>
            Hold on, We are preparing your content.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default LoadingPage;
