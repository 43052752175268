import sorryOwl2 from "../../../res/images/sorryOwl2.jpg";
import { Typography } from "@mui/material";

export default function NoEventsFound() {
  return (
    <div className="flex items-center justify-center h-screen align-center">
      <div className="text-center">
        <img
          className="mx-[auto] md:mt-5 md:mb-5"
          width={"120rem"}
          src={sorryOwl2}
          sx={{ display: { xs: "none", md: "flex" } }}
        />
        {/* {!html ? ( */}
        <Typography
          mx={"25rem"}
          my={"1rem"}
          fontFamily={"Campton"}
          fontSize={"1.5rem"}
          fontWeight={400}
          color="#DEA449"
        >
          No Tickets available
        </Typography>
      </div>
    </div>
  );
}
