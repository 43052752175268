import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import KeyIcon from "@mui/icons-material/Key";

const StyledHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: "1rem",
  paddingRight: "10px",
}));

const StyledRolesIcon = styled(KeyIcon)(({ theme }) => ({
  marginLeft: 16,
  fontSize: 24,
  color: theme.palette.primary.main,
  "&:hover": {
    cursor: "pointer",
  },
}));

const RolesHeader = () => {
  return (
    <StyledHeader>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "center",
          marginTop: 5,
        }}
      >
        <Typography
          ml={2}
          variant="h1"
          fontWeight={700}
          fontSize={26}
          sx={{ alignSelf: "flex-end" }}
          color="primary.main"
        >
          Roles
        </Typography>
        <StyledRolesIcon />
      </Box>
    </StyledHeader>
  );
};

export default RolesHeader;
