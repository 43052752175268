import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Modal,
  // TextField,
  Typography,
} from "@mui/material";
import {
  FormikTextField,
  SubmitButton,
} from "components/form-components/CustomFormikFields";
import { Form, Formik } from "formik";
import KeyIcon from "@mui/icons-material/Key";
import { useUpdateProfileMutation } from "features/profile/profileApiSlice";
// import EditPassword from "./EditPasswordModal";
import EditPasswordModal from "./EditPasswordModal";

export const ProfileDetails = (props) => {
  const { user, setSnackOptions } = props;
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const initialValues = {
    username: user?.username,
    first_name: user?.first_name,
    last_name: user?.last_name,
  };

  const [update, { isLoading: isUpdateLoading }] = useUpdateProfileMutation();

  const handleSubmit = async (values) => {
    const formData = new FormData();
    if (values.first_name !== "")
      formData.append("first_name", values.first_name);
    if (values.last_name !== "") formData.append("last_name", values.last_name);
    if (values.username !== "") formData.append("username", values.username);

    try {
      setError(null);
      const res = await update(formData);
      if (res?.error) {
        throw new Error(res.error.data.message);
      }
      setSnackOptions({
        open: true,
        text: "Successfully updated profile details.",
      });
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(props) => (
          <Form>
            <Card>
              <CardHeader
                subheader="User profile edit section"
                title="User Profile"
              />
              <Divider />
              <CardContent>
                <Typography
                  align="center"
                  variant="p"
                  sx={{ mb: 3, color: "red" }}
                >
                  {error}
                </Typography>
                <Grid container spacing={1}>
                  <Grid item md={6} xs={12}>
                    <FormikTextField
                      fullWidth
                      label="First name"
                      name="first_name"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormikTextField
                      fullWidth
                      label="Last name"
                      name="last_name"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormikTextField
                      fullWidth
                      label="Username"
                      name="username"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      sx={{ textTransform: "none" }}
                      onClick={() => setOpenModal((prev) => !prev)}
                    >
                      Change Password <KeyIcon color="primary" sx={{ ml: 1 }} />
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 1,
                }}
              >
                <Grid container justifyContent={"flex-end"}>
                  <Grid item md={4} xs={12}>
                    <SubmitButton
                      text="Save Details"
                      isSubmitting={isUpdateLoading}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Form>
        )}
      </Formik>
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <EditPasswordModal setSnackOptions={setSnackOptions} />
      </Modal>
    </>
  );
};
