import { useState } from "react";
import {
  // Box,
  Collapse,
  List,
  ListItem,
  ListItemText,
  // MenuItem,
} from "@mui/material";
import {
  StyledListItemButton,
  StyledListItemIcon,
  // StyledListItemText,
} from "../Aside";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { NavLink } from "react-router-dom";
import { toggleMobileNav } from "../../../features/app/appSlice";
import { useSelector, useDispatch } from "react-redux";

export const listItemStyles = { fontSize: "0.75rem", fontWeight: 500 };

const MultiLevelMenu = ({ item }) => {
  const dispatch = useDispatch();
  const isMobileNav = useSelector((state) => state.app.mobileNav);

  const { items: children } = item;
  const items = Object.values(children);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <ListItem disablePadding>
        <StyledListItemButton onClick={handleClick}>
          <StyledListItemIcon>{item.icon}</StyledListItemIcon>
          <ListItemText
            primaryTypographyProps={listItemStyles}
            primary={item.text}
          />
          {open ? (
            <ExpandLess style={{ fontSize: `18px` }} />
          ) : (
            <ExpandMore style={{ fontSize: `18px` }} />
          )}
        </StyledListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map((child, key) => (
            <ListItem key={key} disablePadding sx={{ paddingLeft: 2 }}>
              <StyledListItemButton
                component={NavLink}
                activeClassName="active"
                to={child.path}
                onClick={() => isMobileNav && dispatch(toggleMobileNav())}
              >
                <StyledListItemIcon>{child.icon}</StyledListItemIcon>
                <ListItemText
                  primaryTypographyProps={listItemStyles}
                  primary={child.text}
                />
              </StyledListItemButton>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default MultiLevelMenu;
