import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Menu,
  MenuItem,
  IconButton,
  Icon,
  Box,
} from "@mui/material";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material/";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        // color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const TableActionsMenu = (props) => {
  const {
    actions,
    row,
    isRowDisabled,
    showIcon,
    editIcon,
    deleteIcon,
    onView,
    onEdit,
    onDelete,
    viewTooltip,
    editTooltip,
    deleteTooltip,
    isEditDisabled,
    isDeleteDisabled,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (actions.length === 0 && !onView && !onEdit && !onDelete) {
    return <></>;
  }

  return (
    <div>
      <Box id="row-menu" sx={{ px: 2, py: 1 }}>
        <IconButton
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          <MoreVertRoundedIcon sx={{ fontSize: 24 }} />
        </IconButton>
      </Box>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {actions.map((item) => (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              item.onClick(row.original.id, row.original);
              handleClose();
            }}
            disabled={
              item.isDisabled !== undefined
                ? item.isDisabled(row.original, isRowDisabled)
                : isRowDisabled !== undefined
                ? isRowDisabled(row)
                : false
            }
            disableRipple
          >
            <Icon
              sx={{
                color: item.color
                  ? item.color(row.original, isRowDisabled)
                  : "",
              }}
            >
              {item.icon}
            </Icon>
            {item.tooltip ? item.tooltip(row.original, isRowDisabled) : ""}
          </MenuItem>
        ))}

        {showIcon && (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              if (onView) onView(row.original.id, row.original);
              handleClose();
            }}
            disableRipple
          >
            <Icon sx={{ color: "orange" }}>
              <VisibilityIcon />
            </Icon>
            {viewTooltip ? viewTooltip : "View"}
          </MenuItem>
        )}

        {editIcon && (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              if (onEdit) onEdit(row.original.id, row.original);
              handleClose();
            }}
            disabled={isEditDisabled(row.original)}
            disableRipple
          >
            <Icon sx={{ color: "green" }}>
              <EditIcon />
            </Icon>
            {editTooltip
              ? typeof editTooltip === "function"
                ? editTooltip(row.original)
                : editTooltip
              : "Edit"}
          </MenuItem>
        )}

        {deleteIcon && (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              if (onDelete) onDelete(row.original.id, row.original);
              handleClose();
            }}
            disabled={isDeleteDisabled(row.original)}
            disableRipple
          >
            <Icon sx={{ color: "red" }}>
              <DeleteIcon />
            </Icon>
            {deleteTooltip
              ? typeof deleteTooltip === "function"
                ? deleteTooltip(row.original)
                : deleteTooltip
              : "Delete"}
          </MenuItem>
        )}
      </StyledMenu>
    </div>
  );
};

export default TableActionsMenu;
