import {
  // Avatar,
  Box,
  // Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  useTheme,
} from "@mui/material";
import { baseUrl } from "app/api/apiSlice";
// import AttachFileIcon from "@mui/icons-material/AttachFile";
import * as Yup from "yup";
import {
  // FormikFileField,
  FormikTextAreaField,
  FormikTextField,
  SubmitButton,
} from "components/form-components/CustomFormikFields";
import  verified  from "res/images/verified.png";
import FormikImagePicker from "components/form-components/formik/FormikImagePicker";
import { useGetCompanyProfileQuery, useUpdateCompanyProfileMutation } from "features/profile/profileApiSlice";
import { Form, Formik } from "formik";
import { useAuth } from "hooks/useAuth";
import DefaultImage from "res/images/default_image.png";
// import * as Yup from "yup";

export const CompanyProfile = (props) => {
  const { company, setSnackOptions } = props;
  const { role } = useAuth();
  // const validationSchema = Yup.object({
  //   bio: Yup.string().max(500),
  // });
  const initialValues = {
    logo_file: "",
    bio:company.bio? company.bio : '',
    youtube_link: company.youtube_link?company.youtube_link:'',
    twitter_link: company.twitter_link?company.twitter_link:'',
    facebook_link: company.facebook_link?company.facebook_link:'',
    linkedin_link: company.linkedin_link?company.linkedin_link:'',
    instagram_link:company.instagram_link?company.instagram_link:'',
    business_licence: "",
  };
  const { data: profile, isLoading: isProfileLoading } = useGetCompanyProfileQuery();
// const theme= useTheme();
  const [update, { isLoading: isUpdateLoading }] =
    useUpdateCompanyProfileMutation();
  const handleSubmit = async (values) => {
    const formData = new FormData();
    values.logo_file !== "" && formData.append("logo_file", values.logo_file);
    values.bio !== "" && formData.append("bio", values.bio);
    values.twitter_link !== "" && formData.append("twitter_link", values.twitter_link);
    values.instagram_link !== "" && formData.append("instagram_link", values.instagram_link);
    values.facebook_link !== "" && formData.append("facebook_link", values.facebook_link);
    values.linkedin_link !== "" && formData.append("linkedin_link", values.linkedin_link);
    values.youtube_link !== "" && formData.append("youtube_link", values.youtube_link);

    values.business_licence !== "" &&
      formData.append("business_licence", values.business_licence);

    try {
      const res = await update(formData);
      if (res?.error) {
        throw new Error(res.error.data?.message || res.error.data.detail);
      }
      setSnackOptions({
        open: true,
        text: "Successfully updated company profile",
      });
    } catch (err) {
      setSnackOptions({
        open: true,
        text:
          err.message === "" || err.message === null
            ? "Something went wrong while updating"
            : err.message,
        severity: "error",
      });
    }
  };
  const fileSize = (size) => {
    return size <= 256 * 1024; // 256 KB in bytes
  };
  const validationSchema = Yup.object().shape({
    logo_file: Yup.mixed()
      .required('An image is required')
      .test('fileSize', 'The file is too large (max 256 KB)', value => {
        return value && value.size <= 256 * 1024; // 256 KB
      })
      .test('fileType', 'Unsupported file format', value => {
        return value && ['image/jpg', 'image/jpeg', 'image/png'].includes(value.type);
      }),
  });

  return (
    <Box sx={{ position: "relative" }}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}   validationSchema={validationSchema}>
        {({ values }) => (
          <Form>
            <Card>
              <CardContent>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <FormikImagePicker
                    height={'auto'}
                    width={80}
                    borderRadius="100px"
                    initialImage={
                      company?.logo
                        ? `${baseUrl}${company.logo?.image_large_path}`
                        : DefaultImage
                    }
                    name="logo_file"
                    acceptType={["png", "jpg"]}
                    enableCrop={true}
                    aspectRatio={4 / 4}
                    disabled={role !== "ADMIN" && role !== "SUPER_ADMIN"}
                  />
{/* {true && <img height={40} width={40} src={verified}></img>}
                  <Typography color="textPrimary" textAlign={'center'} gutterBottom variant="h5">
                    {company?.name}
                  </Typography> */}
 {company?.is_verified && <img height={40} width={40} src={verified} alt="Verified" />}      
 <Typography color="textPrimary" textAlign={'center'} fontWeight={'500'} fontSize={'1.2rem'}>
        {company?.name}
      </Typography>
     
                  <Typography color="textSecondary" textAlign={'center'} variant="body2">
                    Company profile
                  </Typography>
                </Box>
                {role === "ADMIN" && (
                 <Box border={'1px solid'} color="primary" borderRadius={2} borderColor={'primary.main'} mx={5} py={1} my={2} sx={{color: "primary" , display: "flex", flexDirection: "column", alignItems: "center",}}>
                 <a style={{textDecoration:'none', color: "primary" }} href={baseUrl+ profile?.business_licence} download={`${profile?.name} Business License`}>
                 
                
                 {/* <AttachFileIcon  color="primary.main" sx={{ width: "18px", height: "18px",display:'inline' }} /> */}
               <Typography display={'inline'} color={'primary.main'}>{'Download Business License'}</Typography>
                  
                </a>
            
              
             </Box> 
                  // <FormikFileField
                  //   sx={{
                  //     mb: 0,
                  //     mt: 1,
                  //   }}
                  //   fullWidth
                  //   accept={"application/pdf"}
                  //   margin="dense"
                  //   id="business_licence"
                  //   name="business_licence"
                  //   label="Business licence"
                    
                  //   disabled
                  // />
                )}
              </CardContent>
              <Box px={3}>
              <FormikTextAreaField
                      fullWidth

                      label="Bio"
                      name="bio"
                    />
                      <FormikTextField
                      fullWidth

                      label="Twitter Link"
                      name="twitter_link"
                    />
                     <FormikTextField
                      fullWidth

                      label="Linkedin Link"
                      name="linkedin_link"
                    />
                     <FormikTextField
                      fullWidth

                      label="Facebook Link"
                      name="facebook_link"
                    />
                     <FormikTextField
                      fullWidth

                      label="Instagram Link"
                      name="instagram_link"
                    />
                     <FormikTextField
                      fullWidth

                      label="Youtube Link"
                      name="youtube_link"
                    />
              </Box>
             
              <Divider />
              <CardActions sx={{ display: "flex", flexDirection: "column" }}>
                {role === "ADMIN" && (
                  <>
                    <SubmitButton
                      text="Update"
                      isSubmitting={isUpdateLoading}
                      variant="text"
                      sx={{ my: 0 }}
                    />
                  </>
                )}
              </CardActions>
            </Card>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
