import jwtDecode from "jwt-decode";

export const loginFn = async ({
  login,
  values,
  dispatch,
  setCredentials,
  navigate,
  setErrorText,
  setSubmitting,
}) => {
  try {
    const userData = await login({
      identifier: values.identifier,
      password: values.password,
    }).unwrap();

    const user_info = jwtDecode(userData.token.access);
    //saving refresh token to local storage

    localStorage.setItem("access", userData.token.access);
    localStorage.setItem("refresh", userData.token.refresh);

    dispatch(
      setCredentials({
        accessToken: userData.token.access,
        refreshToken: userData.token.refresh,
        user: user_info.username,
        role: user_info.role_name,
        isAuth: true,
        companyName: user_info.company_name,
        companyType: user_info.company_type,
      })
    );
    navigate();
  } catch (err) {
    if (!err?.status) {
      // isLoading: true until timeout occurs
      setErrorText("No Server Response");
    } else if (err.status >= 400) {
      setErrorText(err.data.message);
    } else {
      setErrorText("Login Failed");
    }
  } finally {
    setSubmitting(false);
  }
};
