import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import {
  // Box,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
// import Toast from "components/Toast";
import {
  FormikPasswordField,
  SubmitButton,
} from "components/form-components/CustomFormikFields";
import { useUpdatePasswordMutation } from "features/auth/authApiSlice";
import { ModalItemsContainer } from "components/CustomModal/Styles";

const initialValues = {
  oldPassword: "",
  newPassword: "",
  confirm_password: "",
};

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required("This field is required"),
  newPassword: Yup.string().required("This field is required"),
  confirm_password: Yup.string().when("newPassword", {
    is: (val) => val && val.length > 0,
    then: Yup.string().oneOf(
      [Yup.ref("newPassword")],
      "Both password need to be the same"
    ),
  }),
});

export default function EditPasswordModal({ setSnackOptions }) {
  const [error, setError] = useState(null);
  const [updated, setUpdated] = useState(false);

  const [update, { isLoading: isUpdateLoading }] = useUpdatePasswordMutation();

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append("oldPassword", values.oldPassword);
    formData.append("newPassword", values.newPassword);
    formData.append("confirmPassword", values.confirm_password);
    try {
      let res = await update(formData);
      if (res?.error) {
        throw new Error(res.error.data.message);
      }
      setError(null);
      setSnackOptions({ open: true, text: "Password updated successfully." });
    } catch (err) {
      setError(err.message);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setUpdated(false);
    }, 2000);
  });
  return (
    <ModalItemsContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <Typography
              variant="h1"
              fontWeight={700}
              fontSize={26}
              sx={{ textAlign: "center", mt: 1, mb: 2 }}
              color="primary.main"
            >
              Reset Password
            </Typography>
            <Typography align="center" variant="p" sx={{ mb: 3, color: "red" }}>
              {error}
            </Typography>
            <FormikPasswordField
              fullWidth
              margin="dense"
              label="Old Password"
              name="oldPassword"
              id="oldPassword"
            />
            <FormikPasswordField
              fullWidth
              margin="dense"
              label="New Password"
              name="newPassword"
              id="newPassword"
            />
            <FormikPasswordField
              fullWidth
              margin="dense"
              label="Re-type New Password"
              name="confirm_password"
              id="confirm_password"
            />
            <SubmitButton text="Reset" isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
    </ModalItemsContainer>
  );
}
