import { portalUrl } from "constants/api";

export const openRedirectLink = (url, navigate) => {
  const portal = new URL(portalUrl);
  const portalHost = portal.hostname;

  const redirect = new URL(url);
  const redirectHost = redirect.hostname;

  if (portalHost !== redirectHost) {
    return () => window.location.replace(url);
  }

  return () => navigate(redirect.pathname + redirect.search);
};
