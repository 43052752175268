import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { QRCode } from "react-qr-code";
import { getParsedDate, getParsedTime } from "./ticketUtil";

const TicketItem = ({ name, start, end, type, code }) => {
  const theme = useTheme();

  return (
    <Card
      elevation={3}
      sx={{ borderLeft: `5px solid ${theme.palette.primary.main}` }}
    >
      <CardContent>
        <Grid container sx={{ px: 1 }}>
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="overline"
                fontWeight={600}
                sx={{ color: "grey.800" }}
              >
                {name}
              </Typography>

              <Typography
                variant="body2"
                fontSize={12}
                fontWeight={600}
                sx={{
                  px: 1,
                  py: 0,
                  backgroundColor: "success.main",
                  borderRadius: 2,
                  color: "#fff",
                  height: 20,
                }}
              >
                {type}
              </Typography>
            </Box>
            <Typography
              variant="body2"
              fontSize={12}
              fontWeight={600}
              color="grey.500"
            >
              Ticket code: {code}
            </Typography>
            <Divider sx={{ my: 1.5 }} />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Typography
                  variant="body2"
                  sx={{ fontSize: 12, fontWeight: 600, color: "grey.400" }}
                >
                  Date
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontSize: 18, fontWeight: 600, color: "grey.800" }}
                >
                  {getParsedDate(new Date(start))}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  sx={{ fontSize: 12, fontWeight: 600, color: "grey.400" }}
                >
                  Time
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontSize: 18, fontWeight: 600, color: "grey.800" }}
                >
                  {getParsedTime(new Date(start))}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 2,
            }}
          >
            <QRCode
              size={256}
              style={{
                height: "auto",
                maxWidth: "90%",
                width: "100%",
                backgroundColor: "#fff",
              }}
              value={code}
              viewBox={`0 0 256 256`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TicketItem;
