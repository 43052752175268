const { Box } = require("@mui/material");
const { styled } = require("@mui/system");

export const StyledDateTextTitle = styled("span")({
  color: "#DEA449",
  fontSize: "10px",
  fontFamily: "Comfortaa",
  fontWeight: 400,
});
export const StyledDateTextBody = styled("span")({
  fontFamily: "Campton",
  fontWeight: 400,
  textAlign: "start",
  overflowWrap: "break-word",
});
export const StyledEventSquareCardWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.background.dark,
  borderRadius: "10px",
  height: "100%",
  maxWidth: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  maxWidth: "360px",
}));

export const StyledSquareCardImg = styled("img")({
  borderTopLeftRadius: "10px",
  borderTopRightRadius: "10px",
  objectFit: "cover",
  cursor: "pointer",
  width: "100%",
  height: "160px",
  transition: "transform 0.25s",
  "&:hover": {
    transform: "scale(1.1)",
  },
});
