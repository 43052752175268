import { Box, Grid } from "@mui/material";
import DefaultSnackbar from "components/DefaultSnackbar";
import PageLoadingIndicator from "components/widgets/PageLoadingIndicator";
import { ADMIN_ONLY } from "constants/accessHelpers";
import { useGetProfileQuery } from "features/profile/profileApiSlice";
import { useAuth } from "hooks/useAuth";
import { useState } from "react";
import { CompanyProfile } from "./CompanyProfile";
import { ProfileDetails } from "./ProfileDetails";
import ProfilePayment from "./ProfilePayment";
import Subscription from "./Subscription";

const Profile = () => {
  const { role } = useAuth();
  const [snackOptions, setSnackOptions] = useState({
    open: false,
    text: "",
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOptions((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const { data: profile, isLoading: isProfileLoading } = useGetProfileQuery();
  if (isProfileLoading) {
    return <PageLoadingIndicator />;
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pb: 4,
          px: 2,
          maxWidth: "lg",
        }}
      >
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={12}>
            <CompanyProfile
              company={profile?.company}
              setSnackOptions={setSnackOptions}
            />
          </Grid>
          <Grid item lg={8} md={6} xs={12}>
            <ProfileDetails user={profile} setSnackOptions={setSnackOptions} />
            {ADMIN_ONLY.includes(role) && (
              <>
                <ProfilePayment setSnackOptions={setSnackOptions} />
                {/* <DangerZone setSnackOptions={setSnackOptions} /> */}
                <Subscription />
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      <DefaultSnackbar options={snackOptions} handleClose={handleClose} />
    </>
  );
};

export default Profile;
