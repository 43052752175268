import { Box } from "@mui/material";
// import { useState } from "react";
// import CategoryIcon from "@mui/icons-material/Category";
import EventsList from "./EventsList";

const Events = () => {
  return (
    <>
      <Box sx={{ px: 1, py: 2 }}>
        <Box sx={{ mt: 1 }}></Box>
        <EventsList />
      </Box>
    </>
  );
};

export default Events;
