import { createContext, useContext, useEffect, useState } from "react";

const CurrencyContext = createContext();
export const useCurrency = () => {
  return useContext(CurrencyContext);
};

export const CurrencyProvider = ({ children }) => {
  const [currency, setCurrency] = useState("ETB");
  useEffect(() => {
    if (localStorage.getItem("preferredCurrency") != null) {
      setCurrency(localStorage.getItem("preferredCurrency"));
    } else setCurrency("ETB");
  }, []);

  const changeCurrency = (newCurrency) => {
    setCurrency(newCurrency);
    localStorage.setItem("preferredCurrency", newCurrency);
  };

  return (
    <CurrencyContext.Provider value={{ currency, changeCurrency }}>
      {children}
    </CurrencyContext.Provider>
  );
};
