import { Modal, Paper } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import Checkout from ".";

const StyledModalItemsContainer = styled(Paper)(({ theme }) => ({
  position: "absolute",
  background: theme.palette.background.main,
  width: "80vw",
  padding: "3rem",
  display: "flex",
  flexDirection: "column",
  overflowY: "scroll",
  maxHeight: "90vh",
  maxWidth: "90vw",
}));

const CheckoutModal = ({ modalData, setModalData }) => {
  const { open, id, price, name } = modalData;
  return (
    <Modal
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      open={open}
      onClose={() => setModalData({ open: false, id, price, name })}
    >
      <StyledModalItemsContainer>
        <Checkout
          id={id}
          price={price}
          name={name}
          setModalData={setModalData}
        />
      </StyledModalItemsContainer>
    </Modal>
  );
};

export default CheckoutModal;
