import { Form, Formik, useFormikContext } from "formik";
import { styled } from "@mui/system";

import {
  SubmitButton,
  FormikTextField,
  FormikPhoneField,
  FormikPasswordField,
} from "../userComponents";
import * as Yup from "yup";
import { subYears } from "date-fns";
import { startOfDay } from "date-fns/esm";
import {
  Typography,
  Stack,
  Card,
  Box,
  Link,
  Grid,
  Button,
} from "@mui/material";
import { useState } from "react";
import { ModalItemsContainer } from "components/CustomModal/Styles";
import {
  useEmailVerifyMutation,
  useUpdateEmailMutation,
} from "features/auth/authApiSlice";
const EditUserEmail = ({ setModalOpened, mode }) => {
  const minDate = subYears(startOfDay(new Date()), 18);
  const [activeStep, setActiveStep] = useState(0);
  const [codeSentTo, setCodeSentTo] = useState("");
  const [verifyEmail, { isLoading: verifyEmailLoading }] =
    useEmailVerifyMutation();
  const [updateEmail, { isLoading: updateEmailLoading }] =
    useUpdateEmailMutation();
  const [error, setError] = useState("");
  const [useEmail, setUseEmail] = useState(true);
  const formikValue = useFormikContext();
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Enter a valid email"),
    otp:
      activeStep !== 1
        ? Yup.string().nullable()
        : Yup.string()
            .required("OTP is required")
            .min(6, "OTP must be at least 6 digits"),
    // .min(100000, "OTP must be at least 6 digits"),
  });

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    // setSubmitting(true);
    setError("");
    if (activeStep === 0) {
      const form = new FormData();
      form.append("email", values?.email);
      const res = await verifyEmail(form);
      if (!res?.error) {
        setActiveStep(1);
      }
      if (res?.error) {
        // setErrors(res?.error.data.message);
        // setLoading(false);
        setError(res?.error.data.message);
        throw new Error(res.error.data.message);
      }

      setCodeSentTo(values.email);
      // Perform validation and submission logic for the first step
      setSubmitting(true);
    } else {
      const form = new FormData();
      form.append("email", values?.email);
      form.append("code", values?.otp);

      const res = await updateEmail(form);
      if (!res?.error) {
        window.location.reload();
      }
      if (res?.error) {
        // setErrors(res?.error.data.message);
        // setLoading(false);
        setError(res?.error.data.message);
        throw new Error(res.error.data.message);
      }
      setSubmitting(true);
    }
  };
  const handleBack = () => {
    if (activeStep !== 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <Typography sx={{ color: "black", fontWeight: "900", my: 2 }}>
              {mode} Email
            </Typography>

            <FormikTextField
              fullWidth
              margin="dense"
              label="Email Address"
              name="email"
              id="email"
            />
          </>
        );
      case 1:
        // OTP Verification Step
        return (
          <>
            <Box sx={{ my: 3 }}>
              <Box sx={{ mb: 1 }}>
                <Typography sx={{ color: "#646566" }} variant="body2">
                  Email Verification
                </Typography>
                <Typography
                  style={{
                    color: "black",
                    fontWeight: "900",
                    mb: 2,
                  }}
                  variant="h6"
                >
                  Enter Your OTP code
                </Typography>
              </Box>
              <Typography
                style={{
                  color: "#646566",
                }}
                variant="body2"
              >
                Enter the 6-digit code sent to you at {codeSentTo}
              </Typography>
            </Box>
            <FormikTextField
              fullWidth
              margin="dense"
              label="OTP"
              name="otp"
              id="otp"
            />
          </>
        );

      default:
        return "Unknown step";
    }
  };
  return (
    <>
      {/* <BodyWrapper> */}
      <ModalItemsContainer>
        {" "}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // height: "80vh",
            // width: "100%",
          }}
        >
          {/* <Card */}
          <Box
            sx={{
              py: 2,
              px: 2,
              minWidth: { xs: "90vw", md: "65vw", lg: "35vw" },
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                width={64}
                height={64}
                src={
                  "https://dev.api.event.testserver.awuraplc.org/image/image/system_setting_logo/large/24591df5890f48b3834ed38d50565c15.png"
                }
                alt="Owl Events"
                sx={{ m: 1 }}
              />
            </Stack>

            <Formik
              initialValues={{
                email: "",
                phone_number: "",
                otp: "",
                user_name: "",
                date_of_birth: "",
                password: "",
                confirm_password: "",
              }}
              validationSchema={loginSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Box component={Form} noValidate width="100%">
                  {getStepContent(activeStep)}
                  {error && (
                    <Typography sx={{ color: "red" }}>{error}</Typography>
                  )}
                  <Box
                    sx={{
                      alignSelf: "flex-end",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      my: 2,
                    }}
                  >
                    <Grid container alignItems={"center"}>
                      <Grid item xs={6}>
                        <Button
                          onClick={handleBack}
                          disabled={activeStep === 0}
                        >
                          Back
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        {activeStep === 0 && (
                          <SubmitButton
                            text={"Send OTP"}
                            isSubmitting={verifyEmailLoading}
                          />
                        )}
                        {activeStep === 1 && (
                          <SubmitButton
                            text={"Verify OTP"}
                            isSubmitting={updateEmailLoading}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              )}
            </Formik>
          </Box>
          {/* </Card> */}
        </Box>
      </ModalItemsContainer>

      {/* </BodyWrapper> */}
    </>
  );
};

export default EditUserEmail;
