import { useAuth } from "hooks/useAuth";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { setPageLoading } from "features/app/appSlice";
import { useGetAllUserAccessQuery } from "features/permissions/permissionsApiSlice";
import { checkRoutePermitted } from "helpers/checkPermissions";
import { adminAsidePages, superAdminPages } from "menu";
import { adminHeaders } from "routes/headerRoutes";
import Header from "./Header";
import { filterAsidePages } from "helpers/checkPermissions";

const HeaderRoutes = () => {
  const headers = adminHeaders;
  const { role } = useAuth();

  const { data, error, isLoading } = useGetAllUserAccessQuery();
  const [filteredMenu, setFilteredMenu] = useState([]);

  useEffect(() => {
    if (!isLoading)
      setFilteredMenu(filterAsidePages(data ?? [], adminAsidePages, role));
  }, [data, isLoading]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isLoading) dispatch(setPageLoading(1));
    else dispatch(setPageLoading(-1));
    // clean up
  }, [isLoading]);

  const menuListItems = Object.values(filteredMenu);

  return (
    // route garding here
    <Routes>
      {headers.map((header) => {
        if (checkRoutePermitted(menuListItems, header.path)) {
          return <Route key={header.path} {...header} />;
        }
        return superAdminPages.includes(header.path) &&
          role === "SUPER_ADMIN" ? (
          <Route key={header.path} {...header} />
        ) : (
          <Route
            path={header.path}
            key={header.path}
            exact
            element={<Header headerBottom={<></>} />}
          />
        );
      })}
    </Routes>
  );
};

export default HeaderRoutes;
