import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mobileNav: false,
  pageLoading: 1,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    toggleMobileNav: (state, { payload }) => {
      state.mobileNav = !state.mobileNav;
    },
    setPageLoading: (state, { payload }) => {
      state.pageLoading += payload;
    },
  },
});

export const { toggleMobileNav, setPageLoading } = appSlice.actions;
export default appSlice.reducer;
