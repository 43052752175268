import React, { useEffect, useRef, useState } from "react";
import { Grid, Box, Input, Button } from "@mui/material";
import { StyledButton } from "../../../pages/auth/Login";
import { baseUrl } from "app/api/apiSlice";
import ReactToPrint from "react-to-print";
import DefaultSnackbar from "components/DefaultSnackbar";
import {
  useUpdateVehicleBadgeMutation,
  useUpdatePersonBadgeMutation,
} from "features/badges/personAndVehicleBadgeApiSlice";
import { useGetEventQuery } from "features/events/eventsApiSlice";
import Card from "./Card";

function CardForm({ invitations, event }) {
  const [open, setOpen] = useState(false);
  const [isBgImgDirty, setIsBgImgDirty] = useState(false);
  const [update, { isLoading: isUpdateLoading }] =
    useUpdateVehicleBadgeMutation();
  const {
    data: currentEvent,
    isLoading,
    refetch: refetchEvent,
  } = useGetEventQuery(event.id);
  const [bgImg, setBgImg] = useState(null);
  const [currentBgImg, setCurrentBgImg] = useState(null);
  const handleClose = () => {
    setSnackOptions({
      open: false,
    });
  };
  const [snackOptions, setSnackOptions] = useState({
    open: false,
    text: "",
  });

  const componentRef = useRef();

  useEffect(() => {
    if (currentEvent) {
      const initialBgImg = currentEvent?.vehicle_badge_image?.image_small_path
        ? `${baseUrl}${currentEvent.vehicle_badge_image.image_small_path}`
        : null;
      setBgImg(initialBgImg);
    }
  }, [currentEvent]);

  function changeHandler(e) {
    const selectedImage = e.target.files[0];
    setIsBgImgDirty(true);
    setCurrentBgImg(selectedImage);
    setBgImg(window.URL.createObjectURL(selectedImage));
  }

  async function saveBackgroundImageHandler() {
    if (isBgImgDirty) {
      const formData = new FormData();
      formData.append("event_id", event.id);
      formData.append("image_file", currentBgImg);

      try {
        const res = await update(formData);
        if (res?.error) {
          throw new Error(res.error.data.message);
        }
        console.log(res);
        setSnackOptions({
          open: true,
          text: "Successfully saved background image",
        });
        refetchEvent();
      } catch (err) {
        console.error(err);
      }
      setIsBgImgDirty(false);
    } else {
      setSnackOptions({
        open: true,
        text: "Background image already saved",
      });
    }
  }

  return (
    <>
      <Input name="vehicle_badge" onChange={changeHandler} type="file" />

      <ReactToPrint
        trigger={() => (
          <Box
            pt={3}
            pr={2}
            pb={2}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <StyledButton>Print Badges</StyledButton>
          </Box>
        )}
        content={() => componentRef.current}
      />

      {bgImg && (
        <Box
          pt={3}
          pr={2}
          pb={2}
          sx={{ display: "flex", justifyContent: "flex-start" }}
        >
          <Button variant="contained" onClick={saveBackgroundImageHandler}>
            Save Background Image
          </Button>
        </Box>
      )}

      <Grid ref={componentRef} container spacing={2}>
        {invitations?.map((inv) => (
          <Grid item xs={12} key={inv.id}>
            <Card
              name={inv.name}
              sex={inv.sex}
              image={inv.image}
              phone={inv.phone}
              organization={inv.organization}
              rsvp={inv.rsvp}
              ticket={inv.ticket}
              email={inv.email}
              sponsors={event?.sponsors}
              bgImg={bgImg}
              event={event}
              invitation={inv}
            />
          </Grid>
        ))}
      </Grid>
      <DefaultSnackbar options={snackOptions} handleClose={handleClose} />
    </>
  );
}

export default CardForm;
