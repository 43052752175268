import { apiSlice } from "app/api/apiSlice";

export const publicEventsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPublicEvents: builder.query({
      query: (query) => {
        const { pagination, search, filters } = query;
        const { pageIndex, pageSize } = pagination;

        return {
          url: `/portal/public/events/?page=${pageIndex}&page_size=${pageSize}`,
        };
      },
      transformResponse: (response) => {
        const { page_metadata, data } = response.data;
        return {
          data,
          page_metadata,
        };
      },
    }),
    getPublicTownEvents: builder.query({
      query: (query) => {
        const { pagination, search, filters } = query;
        const { pageIndex, pageSize } = pagination;

        return {
          url: `/portal/public/events/?allow_vendors=true&location_city=Addis Ababa&status=ONGOING,UPCOMING&page=${pageIndex}&page_size=${pageSize}`,
        };
      },
      transformResponse: (response) => {
        const { page_metadata, data } = response.data;
        return {
          data,
          page_metadata,
        };
      },
    }),
    getPublicEvent: builder.query({
      query: (query) => {
        const { id } = query;
        return {
          url: `/portal/public/event/detail/?event_id=${id}`,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
    }),
    getPurchasedEvent: builder.query({
      query: (query) => {
        const { pagination, search, filters } = query;
        const { pageIndex, pageSize } = pagination;

        return {
          url: `/public_app/event/purchased_tickets/?page=${pageIndex}&page_size=${pageSize}`,
        };
      },
      transformResponse: (response) => {
        const { page_metadata, data } = response.data;
        return {
          data,
          page_metadata,
        };
      },
    }),
    getMultipleTickets: builder.query({
      query: (query) => {
        const { order_id } = query;
        return {
          url: `/portal/public/ticket/?system_order_id=${order_id}`,
        };
      },
      transformResponse: (response) => {
        if (!response.success) {
          throw new Error("Error loading tickets");
        }
        return response.data;
      },
    }),
  }),
});

export const {
  useGetPublicEventsQuery,
  useGetPublicTownEventsQuery,
  useGetPublicEventQuery,
  useGetPurchasedEventQuery,
  useGetMultipleTicketsQuery,
} = publicEventsApiSlice;
