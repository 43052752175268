import {
  Button,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Close from "@mui/icons-material/Close";
import { ModalItemsContainer } from "components/CustomModal/Styles";
import {
  useGetAllPaymentPlanQuery,
  useGetPaymentMethodsQuery,
} from "features/paymentPlan/paymentPlanApiSlice";

import PaymentPlanVarient from "pages/publicPages/Subscription/PaymentPlanVarent";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "../../../pages/publicPages/Subscription/splide.css";
import { useSendCheckoutURLMutation } from "features/subscription/subscriptionApiSlice";
import Toast from "components/Toast";
import { StyledTab } from "pages/publicPages/Subscription/style";

import { styled } from '@mui/system';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Box } from '@mui/material';
const UpdateSubscriptionModal = ({
  eventId,
  handleClose,
  open,
  //   data,
  title,
  setSpecialServices,
  // subscription,
  ...props
}) => {
  const { data, isLoading } = useGetAllPaymentPlanQuery();
  const plans = data ? data.data : [];
  console.log("plans", plans);
  const [cardPerPage, setCardPerPage] = useState(4);
  const [paymentsPerPage, setPaymentsPerPage] = useState(3);
  const [paymentWidth, setPaymentWidth] = useState("");
  const [cardWidth, setCardWidth] = useState("");
  const { data: paymentMethods, isLoading: isPaymentMethodsLoading } =
    useGetPaymentMethodsQuery();
  const [sendCheckoutURL, { isLoading: isGeneratingURL }] =
    useSendCheckoutURLMutation();

  useEffect(() => {
    function handleResize() {
      const newWidth = window.innerWidth;
      setDimensions(newWidth);
      if (newWidth > 0 && newWidth < 360) {
        setCardWidth("17rem");
        setPaymentWidth("14rem");
      } else if (newWidth > 359 && newWidth < 700) {
        setCardWidth("19rem");
        setPaymentWidth("16rem");
      } else if (newWidth >= 699 && newWidth < 768) {
        if (plans?.length <= 2 && paymentMethods?.length <= 2) {
          setCardWidth("19rem");
          setPaymentWidth("15rem");
        } else {
          setCardWidth("40rem");
          setPaymentWidth("25rem");
        }
      } else if (newWidth >= 767 && newWidth < 1150) {
        if (plans?.length <= 2 && paymentMethods?.length <= 2) {
          setCardWidth("19rem");
          setPaymentWidth("15rem");
        } else {
          setCardWidth("40rem");
          setPaymentWidth("27rem");
        }
      } else if (newWidth >= 1149 && newWidth < 1350) {
        if (plans?.length <= 2 && paymentMethods?.length <= 2) {
          setCardWidth("19rem");
          setPaymentWidth("15rem");
        } else {
          setCardWidth("65rem");
          setPaymentWidth("42rem");
        }
      } else if (newWidth >= 1349 && newWidth < 1540) {
        if (plans?.length <= 2 && paymentMethods?.length <= 2) {
          setCardWidth("19rem");
          setPaymentWidth("15rem");
        } else if (plans?.length <= 3 && paymentMethods?.length <= 3) {
          setCardWidth("40rem");
          setPaymentWidth("27rem");
        } else {
          setCardWidth("65rem");
          setPaymentWidth("40rem");
        }
      } else {
        if (plans?.length <= 2 && paymentMethods?.length <= 2) {
          setCardWidth("19rem");
          setPaymentWidth("15rem");
        } else {
          setCardWidth("88rem");
          setPaymentWidth("60rem");
        }
      }
    }

    setDimensions(window.innerWidth);
    handleResize();

    window.addEventListener("resize", handleResize);
  }, [paymentMethods, plans]);

  const [dimensions, setDimensions] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      const newWidth = window.innerWidth;
      setDimensions(newWidth);
      if (newWidth > 0 && newWidth < 700) {
        setCardPerPage(1);
        setPaymentsPerPage(1);
      } else if (newWidth >= 700 && newWidth < 1150) {
        if (plans?.length <= 2 && paymentMethods?.length <= 2) {
          setCardPerPage(plans?.length);
          setPaymentsPerPage(plans?.length);
        } else {
          setCardPerPage(2);
          setPaymentsPerPage(2);
        }
      } else if (newWidth >= 1150 && newWidth < 1540) {
        if (plans?.length <= 3 && paymentMethods?.length <= 3) {
          setCardPerPage(plans?.length);
          setPaymentsPerPage(plans?.length);
        } else {
          setCardPerPage(3);
          setPaymentsPerPage(3);
        }
      } else {
        if (plans?.length <= 4 && paymentMethods?.length <= 4) {
          setCardPerPage(plans?.length);
          setPaymentsPerPage(plans?.length);
        } else {
          setCardPerPage(4);
          setPaymentsPerPage(4);
        }
      }
    }

    setDimensions(window.innerWidth);
    handleResize();

    window.addEventListener("resize", handleResize);
  }, [paymentMethods, plans]);
  const [currency, setCurrency] = useState('ETB');
  const [isSelected, setIsSelected] = useState();
  const [selectedID, setSelectedID] = useState();
  const [isPayementSelected, setIsPayementSelected] = useState();
  const [selectedPayementID, setSelectedPayementID] = useState();

  const features = [
    "Contacts",
    "Email marketing campains",
    "Events",
    "In app ads",
    "Invitations",
    "Marketing campain templates",
    "Push notifications",
    "System users",
  ];
  const [error, setError] = useState();

  const handleUpgrade = async (selectedID, selectedPayementID) => {
    const res = await sendCheckoutURL({
      payment_plan_option_id: selectedID,
      payment_method_identifier: selectedPayementID,
      currency:currency
    });
    if (
      res?.error?.data?.message ===
      "Error : Free plan available only for companies who did not subscribe."
    ) {
      setError("Free plan available only for companies who did not subscribe.");
    } else if (res?.error?.data?.message) {
      setError(res?.error?.data?.message);
    } else {
      console.log(res);
      window.location.href = res?.data?.checkout_url;
    }
  };
  const [duration, setDuration] = useState(3);
  const [durationType, setDurationType] = useState("MONTH");
  const CustomRadio = styled('div')(({ theme }) => ({
    width: 44,
    height: 24,
    borderRadius: 20,
    backgroundColor: theme.palette.grey[300],
    border: '2px solid #ccc',
    display: 'flex',
    alignItems: 'center',
    padding: 2,
    position: 'relative',
    transition: 'background-color 0.3s ease',
    '&::before': {
      content: '""',
      width: 18,
      height: 18,
      borderRadius: '50%',
      backgroundColor: '#fff',
      transition: 'transform 0.3s ease',
      position: 'absolute',
      left: 2,
    },
  }));


  const CustomRadioChecked = styled('div')(({ theme, color }) => ({
    width: 44,
    height: 24,
    borderRadius: 20,
    backgroundColor: color,
    border: `2px solid ${color}`,
    display: 'flex',
    alignItems: 'center',
    padding: 2,
    position: 'relative',
    transition: 'background-color 0.3s ease',
    '&::before': {
      content: '""',
      width: 18,
      height: 18,
      borderRadius: '50%',
      backgroundColor: '#fff',
      transform: 'translateX(20px)',
      transition: 'transform 0.3s ease',
      position: 'absolute',
      left: 2,
    },
  }));
  const customPlan = {
    options: {
      id: 0,
      dvr_setting: {
        id: 0,
        max_no_events: "Custom",
        max_no_contacts: "Custom",
        max_no_email_marketing_campaigns: "Custom",
        max_no_marketing_campaign_templates: "Custom",
        max_no_invitation: "Custom",
        max_no_system_users: "Custom",
        max_no_in_app_ads: "Custom",
        max_no_push_notifications: "Custom",
      },
      price: "CUSTOM",
    },

    name: "Custom",
    description: "Custom Payment Plan",
    is_recommended: false,
    is_active: true,
    is_premium: false,
  };
  console.log("nehas", selectedID);
  const handleChange = (event) => {
    setCurrency(event.target.value);

  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ModalItemsContainer sx={{ width: "fit-content" }}>
        <Typography
          align="center"
          variant="h2"
          sx={{
            fontWeight: 700,
            mb: 3,
            fontSize: 24,
            color: "primary.main",
          }}
        >
          Update Subscription
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{
            alignSelf: "flex-end",
            position: "absolute",
            top: "0",
            right: "0",
            mb: 8,
          }}
        >
          <Close />
        </IconButton>
        {error && <Toast message={error} />}
        <Typography
          variant="h5"
          color={"primary.main"}
          fontWeight="600"
          marginX="auto"
          marginBottom="1rem"
        >
          Choose your plan
        </Typography>
        <Grid
          mb={5}
          item
          sx={{ display: "flex", justifyContent: "center" }}
          xs={12}
        >
          <StyledTab
            sx={{ backgroundColor: duration == 3 ? "#DEA449" : "#FFFFFF" }}
            clickable
            onClick={() => {
              setDuration(3);
              setDurationType("MONTH");
            }}
            label={
              <Typography
                variant="h6"
                color={"textPrimary"}
                fontWeight="500"
                marginX="auto"
                marginY="2rem"
              >
                3 Months
              </Typography>
            }
          />
          <StyledTab
            clickable
            sx={{ backgroundColor: duration == 6 ? "#DEA449" : "#FFFFFF" }}
            onClick={() => {
              setDuration(6);
              setDurationType("MONTH");
            }}
            label={
              <Typography
                variant="h6"
                color={"textPrimary"}
                fontWeight="500"
                marginX="auto"
                marginY="2rem"
              >
                6 Months
              </Typography>
            }
          />
          <StyledTab
            onClick={() => {
              setDuration(1);
              setDurationType("YEAR");
            }}
            clickable
            sx={{ backgroundColor: duration == 1 ? "#DEA449" : "#FFFFFF" }}
            label={
              <Typography
                variant="h6"
                color={"textPrimary"}
                fontWeight="500"
                marginX="auto"
                marginY="2rem"
              >
                1 Year
              </Typography>
            }
          />
        </Grid>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          padding={2}
        >
          <FormControl component="fieldset" style={{ width: '100%' }}>
            <RadioGroup
              aria-label="currency"
              name="currency"
              value={currency}
              onChange={handleChange}
              row
            >
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <FormControlLabel
                    value="ETB"
                    control={
                      <Radio
                        icon={<CustomRadio color="#DEA449" />}
                        checkedIcon={<CustomRadioChecked color="#DEA449" />}
                        sx={{ '&.Mui-checked': { color: '#3f51b5' } }}
                      />
                    }
                    label={
                      <span style={{ color: 'grey', fontWeight: 'bold' }}>ETB</span>
                    }
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    value="USD"
                    control={
                      <Radio
                        icon={<CustomRadio color="#DEA449" />}
                        checkedIcon={<CustomRadioChecked color="#DEA449" />}
                        sx={{ '&.Mui-checked': { color: '#DEA449' } }}
                      />
                    }
                    label={
                      <span style={{ color: 'grey', fontWeight: 'bold' }}>USD</span>
                    }
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </Box>

        <Grid display="flex" flexWrap="wrap" gap="1rem" margin="auto">
        <Splide
  options={{
    type: "slide", // Use "slide" instead of "loop"
    autoplay: true,
    width: cardWidth,
    gap: "25rem",
    perPage: cardPerPage,
    autoplay: {
      enabled: true, // Enable autoplay
      pauseOnHover: true, // Pause autoplay on mouse hover
    },
  }}
  className="custom-splide"
>

            {plans?.map((plan) => {
              const optionToDisplay = plan?.options?.find(
                (option) =>
                  option.duration_type === durationType &&
                  option.duration === duration
              );
              // Conditionally render the SplideSlide only if optionToDisplay exists


              if (optionToDisplay) {

                return (
                  <SplideSlide key={plan.id}>

                    <PaymentPlanVarient

                      key={plan.id}
                      id={plan.id}
                      name={plan.name}
                      title={plan.name}
                      desc={plan.description}
                      price={0}
                      setSelectedID={setSelectedID}
                      selectedID={selectedID}
                      isCustom={false}
                      options={optionToDisplay}
                      isSelected={isSelected}
                      setIsSelected={setIsSelected}
                      selectedCurrency={currency}
                      features={features.map((name, index) => ({
                        name,
                        status: true,
                      }))}
                      plan={plan}
                    // setModalData={setModalData}
                    />
                  </SplideSlide>

                );
              }

              return null; // Render nothing if the condition isn't met
            })}

            <SplideSlide key={customPlan.id}>
              <PaymentPlanVarient
                id={customPlan.id}
                name={customPlan.name}
                title={customPlan.name}
                desc={customPlan.description}
                setSelectedID={setSelectedID}
                selectedID={selectedID}
                isSelected={isSelected}
                setIsSelected={setIsSelected}
                price={0}
                selectedCurrency={currency}
                features={features.map((name, index) => ({
                  name,
                  status: true,
                }))}
                isCustom={true}
                plan={customPlan}
                options={customPlan?.options}
              />
            </SplideSlide>
          </Splide>
        </Grid>
        <Typography
          variant="h5"
          color={"primary.main"}
          fontWeight="600"
          marginX="auto"
          marginY="2rem"
        >
          Choose your payment method
        </Typography>
        <Grid display="flex" flexWrap="wrap" gap="2rem" margin="auto" my={2}>
          <Splide
            options={{
              type: "loop",
              autoplay: true,
              width: paymentWidth,
              gap: "1rem",
              perPage: paymentsPerPage,
              autoplay: {
                enabled: true, // Enable autoplay
                // Delay between each slide change (in milliseconds)
                pauseOnHover: true, // Pause autoplay on mouse hover
              },
            }}
            className="custom-splide"
          >
       {paymentMethods?.filter(method =>
  method.supported_currencies?.includes(currency)
).map(method => (
  <SplideSlide key={method?.identifier}>
    <Grid
      key={method?.identifier}
      onClick={() => {
        setIsPayementSelected(method?.identifier);
        setSelectedPayementID(method?.identifier);
      }}
      sx={{
        border: `${isPayementSelected === method?.identifier ? "solid 2px #DEA449" : ""}`,
        borderRadius: "10px",
        padding: "0.5rem 2rem",
        cursor: "pointer",
      }}
    >
      <img
        src={method?.logo_url}
        alt={method?.name}
        style={{
          width: "10rem",
        }}
      />
    </Grid>
  </SplideSlide>
))}
          </Splide>
        </Grid>

        <Button
          variant="contained"
          sx={{ width: "fit-content", margin: "auto" }}
          onClick={() => handleUpgrade(selectedID, selectedPayementID)}
        >
          Update Plan
        </Button>
      </ModalItemsContainer>
    </Modal>
  );
};

export default UpdateSubscriptionModal;
