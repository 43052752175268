// export const passwordRequiredRoutes = ["/portal/event/postpone/"];

// endpoints
export const apiUrl = process.env.REACT_APP_API_URL;
export const portalUrl = process.env.REACT_APP_PORTAL_URL;

export const webSocketUrl = process.env.REACT_APP_WEB_SOCKET_URL;
export const publicWebUrl = process.env.REACT_APP_PUBLIC_URL;

// export const vendorRegisterUrl = `${publicWebUrl}/vendors/register`;
export const CalendlyURL = 'https://calendly.com/awura_';

