import { Box } from "@mui/material";
import React from "react";
import { UserDetails } from "./userInfo";
import { useGetProfileQuery } from "features/profile/profileApiSlice";
import PageLoadingIndicator from "components/widgets/PageLoadingIndicator";
import { useState } from "react";
import DefaultSnackbar from "components/DefaultSnackbar";

const UserProfile = () => {
  const [snackOptions, setSnackOptions] = useState({
    open: false,
    text: "",
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOptions((prev) => ({
      ...prev,
      open: false,
    }));
  };
  const { data: profile, isLoading: isProfileLoading } = useGetProfileQuery();

  if (isProfileLoading) {
    return <PageLoadingIndicator />;
  }
  return (
    <>
      <Box
        sx={{
          width: "100%",
          px: 1,
          mt: 2,
        }}
      >
        <UserDetails user={profile} setSnackOptions={setSnackOptions} />
      </Box>
      <DefaultSnackbar options={snackOptions} handleClose={handleClose} />
    </>
  );
};

export default UserProfile;
