import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { FiShoppingBag } from "react-icons/fi";

const StyledHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: "1rem",
  paddingRight: "10px",
}));

const StyledEventRequestsIcon = styled(FiShoppingBag)(({ theme }) => ({
  marginLeft: 16,
  fontSize: 24,
  color: theme.palette.primary.main,
  "&:hover": {
    cursor: "pointer",
  },
}));

const EventsHeader = () => {
  return (
    <StyledHeader>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "center",
          marginTop: 5,
        }}
      >
        <Typography
          ml={2}
          variant="h1"
          fontWeight={700}
          fontSize={26}
          sx={{ alignSelf: "flex-end" }}
          color="primary.main"
        >
          Event Requests
        </Typography>
        <StyledEventRequestsIcon />
      </Box>
    </StyledHeader>
  );
};

export default EventsHeader;
