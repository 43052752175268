import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Grid, IconButton } from "@mui/material";
import EnhancedDataTable from "components/EnhancedDataTable";
import ShowSubscriptionHistory from "components/formModals/settings/ShowSubscriptionDetail";
import StyledHeaderText from "components/styles/StyledHeaderText";
import { useModal } from "contexts/modalContext";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

const {
  useGetSubscriptionPaymentHistoryQuery,
  useGetActiveSubscriptionPaymentQuery,
  // useGetSubscriptionPaymentDetailQuery,
} = require("features/subscription/subscriptionApiSlice");

const SubscriptionPaymentHistory = () => {
  const { data: subscription, isLoading: isSubscriptionLoading } =
    useGetActiveSubscriptionPaymentQuery();
  const {
    data: history,
    isLoading: isHistoryLoading,
    isFetching: isHistoryFetching,
  } = useGetSubscriptionPaymentHistoryQuery();

  const [rows, setRows] = useState([]);
  useEffect(() => {
    if (!isHistoryLoading && !isHistoryFetching && history?.data) {
      const newData = history.data.map((data) => ({
        id: data?.id,
        plan: data.payment_plan?.name,
        price: data.payment_plan_option?.price,
        duration: data.payment_plan_option?.duration,
        duration_type: data.payment_plan_option?.duration_type,
        payment_method_identifier: data.payment?.payment_method_identifier,
        paid_date: data.payment?.date_paid,
      }));
      setRows(newData);
    }
  }, [history, isHistoryLoading, isHistoryFetching]);

  const headCells = useMemo(() => [
    {
      id: "plan",
      numeric: false,
      disablePadding: false,
      label: "Subscription Plan",
    },
    {
      id: "duration",
      numeric: true,
      disablePadding: false,
      label: "Duration",
    },
    {
      id: "duration_type",
      numeric: true,
      disablePadding: false,
      label: "Duration Type",
    },
    {
      id: "price",
      numeric: true,
      disablePadding: false,
      label: "Price",
    },
    {
      id: "payment_method_identifier",
      numeric: true,
      disablePadding: false,
      label: "Payment Method",
    },
    {
      id: "paid_date",
      numeric: true,
      disablePadding: true,
      label: "Paid Date",
    },
  ]);

  const [open, setOpen] = useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { showModal, toggleModal } = useModal();
  const onView = (id, row) => {
    toggleModal("modal1", {
      action: "edit",
      id,
      row,
    });
  };
  const navigate = useNavigate();

  return (
    <Box padding={3} mt={4}>
      <Grid display="flex" flexDirection="row" mb={3}>
        <IconButton
          sx={{ color: "primary.main", alignSelf: "center" }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <StyledHeaderText>Subscription Payment History</StyledHeaderText>
      </Grid>
      <EnhancedDataTable
        headCells={headCells}
        rows={[...rows]}
        allowExport={true}
        onEdit={onView}
        // rowCount={rowCount}
        // manualPagination={true}
        // pagination={pagination}
        // onPaginationChange={setPagination}
        // isLoading={isLoading}
        // searchProps={{ searchValue, setSearchValue, showSearch, setShowSearch }}
      />
      {showModal.modal1 && (
        <ShowSubscriptionHistory
          open={open}
          handleClose={handleClose}
          dvr_setting= {subscription?.payment_plan_option?.dvr_setting}

          // id={history?.data[0]?.id}
        />
      )}
    </Box>
  );
};

export default SubscriptionPaymentHistory;
