import React, { lazy } from "react";
import {
  authPages,
  adminAsidePages,
  InnerPages,
  publicPages,
  // page404,
  subscribers,
  commonPages,
} from "menu";
import SubscriptionPaymentHistory from "pages/adminPages/SubscriptionPaymentHistory";
import ScheduleOrder from "pages/adminPages/EventDetail/ScheduleOrder";
// import Page404 from "pages/Page404";

const WithOutAsidePagesContent = {
  Login: lazy(() => import("pages/auth/Login")),
  RegisterAs: lazy(() => import("pages/auth/registerAs")),
  ForgotPassword: lazy(() => import("pages/auth/ForgotPassword")),
  Page404: lazy(() => import("pages/Page404")),
  ResetPassword: lazy(() => import("pages/auth/ResetPassword")),
  CheckEmail: lazy(() => import("pages/auth/CheckEmail")),
  NoPermisison: lazy(() => import("pages/auth/NoPermission")),
  Verify: lazy(() => import("pages/auth/Verify")),
  VerifySuccess: lazy(() => import("pages/auth/VerifySuccess")),
  Subscription: lazy(() => import("pages/publicPages/Subscription")),
  Checkout: lazy(() => import("pages/publicPages/Checkout")),
  PaymentRedirect: lazy(() =>
    import("pages/publicPages/Payment/PaymentRedirect")
  ),
  Help: lazy(() => import("pages/publicPages/Help")),
  SubscriptionExpired: lazy(() => import("pages/auth/SubscriptionExpired")),
};

const AdminAsidePagesContent = {
  Home: lazy(() => import("pages/adminPages/Home")),
  Events: lazy(() => import("pages/adminPages/Events")),
  EventRequests: lazy(() => import("pages/adminPages/EventRequests")),
  Categories: lazy(() => import("pages/adminPages/Categories")),
  EventSpaces: lazy(() => import("pages/adminPages/EventSpace")),

  // Invitation: lazy(() => import("pages/adminPages/Invitation")),
  Users: lazy(() => import("pages/adminPages/Users")),
  Subscribers: lazy(() => import("pages/adminPages/Subscribers")),

  Organizations: lazy(() => import("pages/adminPages/Organizations")),
  Settings: lazy(() => import("pages/adminPages/Settings")),
  Profile: lazy(() => import("pages/adminPages/Profile/index")),
  PlanOptions: lazy(() => import("pages/adminPages/PlanOptions")),

  Attendees: lazy(() => import("pages/adminPages/Attendees")),
  Roles: lazy(() => import("pages/adminPages/Roles")),
  Permissions: lazy(() => import("pages/adminPages/Permissions")),
  Sponsors: lazy(() => import("pages/adminPages/Sponsors")),
  EventInvitations: lazy(() => import("pages/adminPages/EventDetail")),
  EventBundle: lazy(() => import("pages/adminPages/EventDetail/BundleInfo")),
  AssignSecurity: lazy(() => import("pages/adminPages/AssignSecurity")),
  Attendance: lazy(() => import("pages/adminPages/Attendance")),
  EmailTemplates: lazy(() => import("pages/adminPages/EmailTemplates")),
  SharedEvents: lazy(() => import("pages/adminPages/SharedEvents")),
  SharedContacts: lazy(() => import("pages/adminPages/SharedContacts")),
  Companies: lazy(() => import("pages/adminPages/Companies")),
  Vendors: lazy(() => import("pages/adminPages/Vendors")),
  Plans: lazy(() => import("pages/adminPages/Plans")),
  CompanyDetail: lazy(() => import("pages/adminPages/CompanyDetail")),
  VendorDetail: lazy(() => import("pages/adminPages/VendorDetail")),
  ContactGroup: lazy(() => import("pages/adminPages/ContactGroup")),
  ContactGroupContacts: lazy(() =>
    import("pages/adminPages/ContactGroupContacts")
  ),
  ScheduleOrder: lazy(() =>
    import("pages/adminPages/EventDetail/ScheduleOrder")
  ),
  CampaignTemplates: lazy(() => import("pages/adminPages/CampaignTemplates")),
  Campaign: lazy(() => import("pages/adminPages/Campaign")),
  Notifications: lazy(() => import("pages/shared/Notifications")),
  Spaces: lazy(() => import("pages/adminPages/Spaces")),
  Locations: lazy(() => import("pages/adminPages/Locations")),
  Ads: lazy(() => import("pages/adminPages/Ads/index")),
  AdminAds: lazy(() => import("pages/adminPages/Ads/AdminAds")),
  Banks: lazy(() => import("pages/adminPages/Banks")),
  SponsorTypes: lazy(() => import("pages/adminPages/SponsorTypes")),
  ServiceTypes: lazy(() => import("pages/adminPages/ServiceTypes")),
  PushNotifications: lazy(() => import("pages/adminPages/PushNotifications")),

  PaymentHistory: lazy(() => import("pages/adminPages/PaymentHistory")),
  ForeignTransactionHistory: lazy(() =>
    import("components/dashboard/ForeignTransactionHistory")
  ),
  TicketSellers: lazy(() => import("pages/adminPages/TicketSellers")),
  AdminPaymentHistory: lazy(() =>
    import("pages/adminPages/AdminPaymentHistory")
  ),
  SubscriptionPayment: lazy(() =>
    import("pages/adminPages/SubscriptionPaymentHistory")
  ),
  EventAttendance: lazy(() =>
    import("pages/adminPages/Attendance/AttendanceAdapter")
  ),
};

const common = [
  //auth pages
  {
    path: authPages.Login.path,
    element: <WithOutAsidePagesContent.Login />,
    exact: true,
  },
  {
    path: authPages.RegisterAs.path,
    element: <WithOutAsidePagesContent.RegisterAs />,
    exact: true,
  },

  {
    path: authPages.ForgotPassword.path,
    element: <WithOutAsidePagesContent.ForgotPassword />,
    exact: true,
  },
  {
    path: authPages.ResetPassword.path,
    element: <WithOutAsidePagesContent.ResetPassword />,
    exact: true,
  },
  {
    path: authPages.CheckEmail.path,
    element: <WithOutAsidePagesContent.CheckEmail />,
    exact: true,
  },
  {
    path: authPages.NoPermission.path,
    element: <WithOutAsidePagesContent.NoPermisison />,
    exact: true,
  },
  {
    path: authPages.Verify.path,
    element: <WithOutAsidePagesContent.Verify />,
    exact: true,
  },
  {
    path: authPages.VerifySuccess.path,
    element: <WithOutAsidePagesContent.VerifySuccess />,
    exact: true,
  },
  {
    path: publicPages.Subscription.path,
    element: <WithOutAsidePagesContent.Subscription />,
    exact: true,
  },
  {
    path: publicPages.Checkout.path,
    element: <WithOutAsidePagesContent.Checkout />,
    exact: true,
  },
  {
    path: publicPages.PaymentRedirect.path,
    element: <WithOutAsidePagesContent.PaymentRedirect />,
    exact: true,
  },
  {
    path: publicPages.Help.path,
    element: <WithOutAsidePagesContent.Help />,
    exact: true,
  },
  {
    path: InnerPages.SubscriptionPaymentHistory.path,
    element: <SubscriptionPaymentHistory />,
    exact: true,
  },
  {
    path: commonPages.SubscriptionExpired.path,
    element: <WithOutAsidePagesContent.SubscriptionExpired />,
    exact: true,
  },
];

const adminAsidePagesContent = [
  // pages in the Aside content
  {
    path: adminAsidePages.Home.path,
    element: <AdminAsidePagesContent.Home />,
    exact: true,
  },
  {
    path: adminAsidePages.Ads.path,
    element: <AdminAsidePagesContent.Ads />,
    exact: true,
  },
  {
    path: adminAsidePages.ContactSettings.items.Organizations.path,
    element: <AdminAsidePagesContent.Organizations />,
    exact: true,
  },
  // {
  //   path: adminAsidePages.Invitation.path,
  //   element: <AdminAsidePagesContent.Invitation />,
  //   exact: true,
  // },
  {
    path: adminAsidePages.Events.path,
    element: <AdminAsidePagesContent.Events />,
    exact: true,
  },
  {
    path: adminAsidePages.EventRequests.path,
    element: <AdminAsidePagesContent.EventRequests />,
    exact: true,
  },
  {
    path: adminAsidePages.ContactSettings.items.Attendees.path,
    element: <AdminAsidePagesContent.Attendees />,
    exact: true,
  },
  {
    path: adminAsidePages.ContactSettings.items.ContactGroup.path,
    element: <AdminAsidePagesContent.ContactGroup />,
    exact: true,
  },
  {
    path: adminAsidePages.EmailMarketing.items.CampaignTemplate.path,
    element: <AdminAsidePagesContent.CampaignTemplates />,
    exact: true,
  },
  {
    path: adminAsidePages.EmailMarketing.items.Campaign.path,
    element: <AdminAsidePagesContent.Campaign />,
    exact: true,
  },

  {
    path: adminAsidePages.EventSettings.items.Sponsors.path,
    element: <AdminAsidePagesContent.Sponsors />,
    exact: true,
  },
  // {
  //   path: adminAsidePages.EventSettings.items.Locations.path,
  //   element: <AdminAsidePagesContent.Locations />,
  //   exact: true,
  // },
  {
    path: adminAsidePages.EventSettings.items.EventSpaces.path,
    element: <AdminAsidePagesContent.EventSpaces />,
    exact: true,
  },
  {
    path: adminAsidePages.EventSettings.items.Spaces.path,
    element: <AdminAsidePagesContent.Spaces />,
    exact: true,
  },
  {
    path: adminAsidePages.EventSettings.items.EmailTemplates.path,
    element: <AdminAsidePagesContent.EmailTemplates />,
    exact: true,
  },

  {
    path: adminAsidePages.Security.items.Attendance.path,
    element: <AdminAsidePagesContent.Attendance />,
    exact: true,
  },
  {
    path: adminAsidePages.UserSettings.items.Users.path,
    element: <AdminAsidePagesContent.Users />,
    exact: true,
  },
  {
    path: subscribers.items.Subscribers.path,
    element: <AdminAsidePagesContent.Subscribers />,
    exact: true,
  },
  {
    path: adminAsidePages.UserSettings.items.Roles.path,
    element: <AdminAsidePagesContent.Roles />,
    exact: true,
  },
  {
    path: adminAsidePages.UserSettings.items.Permissions.path,
    element: <AdminAsidePagesContent.Permissions />,
    exact: true,
  },

  {
    path: adminAsidePages.Security.items.AssignSecurity.path,
    element: <AdminAsidePagesContent.AssignSecurity />,
    exact: true,
  },
  {
    path: adminAsidePages.Security.items.Attendance.path,
    element: <AdminAsidePagesContent.Attendance />,
    exact: true,
  },
  {
    path: adminAsidePages.Security.items.TicketSellers.path,
    element: <AdminAsidePagesContent.TicketSellers />,
    exact: true,
  },
  {
    path: adminAsidePages.Settings.path,
    element: <AdminAsidePagesContent.Settings />,
    exact: true,
  },
  {
    path: InnerPages.ScheduleOrder.path,
    element: <AdminAsidePagesContent.ScheduleOrder />,
    exact: true,
  },
  {
    path: InnerPages.ContactGroupContacts.path,
    element: <AdminAsidePagesContent.ContactGroupContacts />,
    exact: true,
  },
  {
    path: InnerPages.PlanOptions.path,
    element: <AdminAsidePagesContent.PlanOptions />,
    exact: true,
  },
  {
    path: InnerPages.EventInvitations.path,
    element: <AdminAsidePagesContent.EventInvitations />,
    exact: true,
  },
  {
    path: InnerPages.EventBundle.path,
    element: <AdminAsidePagesContent.EventBundle />,
    exact: true,
  },
  {
    path: InnerPages.SharedEvents.path,
    element: <AdminAsidePagesContent.SharedEvents />,
    exact: true,
  },
  {
    path: InnerPages.SharedContacts.path,
    element: <AdminAsidePagesContent.SharedContacts />,
    exact: true,
  },
  {
    path: InnerPages.Profile.path,
    element: <AdminAsidePagesContent.Profile />,
    exact: true,
  },
  {
    path: InnerPages.CompanyDetail.path,
    element: <AdminAsidePagesContent.CompanyDetail />,
    exact: true,
  },
  {
    path: InnerPages.VendorDetail.path,
    element: <AdminAsidePagesContent.VendorDetail />,
    exact: true,
  },
  {
    path: InnerPages.PaymentHistory.path,
    element: <AdminAsidePagesContent.PaymentHistory />,
    exact: true,
  },
  {
    path: InnerPages.ForeignTransactionHistory.path,
    element: <AdminAsidePagesContent.ForeignTransactionHistory />,
    exact: true,
  },
  {
    path: InnerPages.EventAttendance.path,
    element: <AdminAsidePagesContent.EventAttendance />,
    exact: true,
  },
  {
    path: subscribers.items.Companies.path,
    element: <AdminAsidePagesContent.Companies />,
    exact: true,
  },
  {
    path: subscribers.items.Vendors.path,
    element: <AdminAsidePagesContent.Vendors />,
    exact: true,
  },
  {
    path: subscribers.items.Plans.path,
    element: <AdminAsidePagesContent.Plans />,
    exact: true,
  },
  // {
  //   path: subscribers.items.EventSpaces.path,
  //   element: <AdminAsidePagesContent.EventSpaces />,
  //   exact: true,
  // },
  {
    path: subscribers.items.Categories.path,
    element: <AdminAsidePagesContent.Categories />,
    exact: true,
  },
  {
    path: subscribers.items.Ads.path,
    element: <AdminAsidePagesContent.AdminAds />,
    exact: true,
  },
  {
    path: subscribers.items.Banks.path,
    element: <AdminAsidePagesContent.Banks />,
    exact: true,
  },
  {
    path: subscribers.items.SponsorTypes.path,
    element: <AdminAsidePagesContent.SponsorTypes />,
    exact: true,
  },
  {
    path: subscribers.items.ServiceTypes.path,
    element: <AdminAsidePagesContent.ServiceTypes />,
    exact: true,
  },
  {
    path: subscribers.items.PushNotifications.path,
    element: <AdminAsidePagesContent.PushNotifications />,
    exact: true,
  },
  {
    path: subscribers.items.AdminPaymentHistory.path,
    element: <AdminAsidePagesContent.AdminPaymentHistory />,
    exact: true,
  },
  {
    path: commonPages.Notifications.path,
    element: <AdminAsidePagesContent.Notifications />,
    exact: true,
  },
];

export const adminContents = [...adminAsidePagesContent];
export const rentalCustomersContents = [];
export default common;
