import { Box, Grid } from "@mui/material";
import { useGetNotificationsQuery } from "features/notifications/notificationsApiSlice";
import useGetData from "hooks/useGetData";
import React, { useState } from "react";
import NotificationFilters from "./NotificationFilters";
import NotificationsTable from "./NotificationsTable";

const Notifications = () => {
  const [orderBy, setOrderBy] = useState("DESC");
  const [category, setCategory] = useState("ALL");
  const {
    isLoading,
    data,
    rowCount,
    pagination,
    setPagination,
    searchValue,
    setSearchValue,
    showSearch,
    setShowSearch,
  } = useGetData()(
    useGetNotificationsQuery,
    {
      order_by: "date_created",
      order_by_clause: orderBy,
      category: category === "ALL" ? "" : category,
    },
    { pageIndex: 0, pageSize: 5 }
  );

  return (
    <Box sx={{ width: "100%", maxWidth: "100vw" }}>
      {/* grid 3 by 9, filters and table */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={1.8}>
          <NotificationFilters setCategory={setCategory} />
        </Grid>
        <Grid item xs={12} md={10.2} sx={{ pr: { xs: 0, md: 4 } }}>
          <NotificationsTable
            data={data}
            pagination={pagination}
            setPagination={setPagination}
            isLoading={isLoading}
            rowCount={rowCount}
            searchProps={{
              searchValue,
              setSearchValue,
              showSearch,
              setShowSearch,
            }}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Notifications;
