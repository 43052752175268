import React from "react";
import { Box } from "@mui/material";
import HeaderTop from "./HeaderTop";

const Header = ({ headerTop, headerBottom }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {headerTop}
      {headerBottom}
    </Box>
  );
};

Header.defaultProps = {
  headerTop: <HeaderTop />,
};
export default Header;
