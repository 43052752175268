import { Form, Formik } from "formik";

import {
  SubmitButton,
  FormikTextField,
  FormikPhoneField,
  FormikPasswordField,
  FormikDateField,
} from "../userComponents";
// import { FormikDateField } from "components/formComponents/CustomFormikFields";
import * as Yup from "yup";
import { subYears } from "date-fns";
import { startOfDay } from "date-fns/esm";
import { StyledLink } from "pages/auth/Login";
import moment from "moment";
import jwtDecode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setRedirect } from "features/routing/routingSlice";
import { setCredentials } from "features/auth/authSlice";
import { userPages } from "menu";

import {
  Typography,
  Stack,
  Box,

  Grid,
  Button,
  Card,
} from "@mui/material";
import { useState } from "react";
import {
  useEmailVerifyMutation,
  useEmailValidateMutation,
  useTextValidateMutation,
  useTextVerifyMutation,
  useSignUpMutation,
} from "features/auth/authApiSlice";

const UserRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirectPath = useSelector((state) => state.routing.redirectPath);

  const [verifyEmail, { isLoading: verifyEmailLoading }] =
    useEmailVerifyMutation();
  const [verifyPhone, { isLoading: verifyPhoneLoading }] =
    useTextVerifyMutation();
  const [validateText, { isLoading: validateTextLoading }] =
    useTextValidateMutation();
  const [validateEmail, { isLoading: validateEmailLoading }] =
    useEmailValidateMutation();
  const [signUp, { isLoading: signUpLoading }] = useSignUpMutation();

  const minDate = subYears(startOfDay(new Date()), 18);
  const [activeStep, setActiveStep] = useState(0);
  const [codeSentTo, setCodeSentTo] = useState("");
  const [error, setError] = useState("");
  const [useEmail, setUseEmail] = useState(true);
  const loginSchema = Yup.object().shape({
    email: useEmail
      ? Yup.string().required("Email is required").email("Enter a valid email")
      : Yup.string().nullable(),
    phone_number: useEmail
      ? Yup.string().nullable()
      : Yup.string().required("Phone number is required"),
    otp:
      activeStep !== 1
        ? Yup.string().nullable()
        : Yup.string()
            .required("OTP is required")
            .min(6, "OTP must be at least 6 digits"),
    // .min(100000, "OTP must be at least 6 digits"),
    user_name:
      activeStep !== 2
        ? Yup.string().nullable()
        : Yup.string().required("user name is required"),
    date_of_birth:
      activeStep !== 2
        ? Yup.string().nullable()
        : Yup.string().required("Birth Date is required"),
    password:
      activeStep !== 2
        ? Yup.string().nullable()
        : Yup.string()
            .required("Password is required")
            .min(6, "Password must be at least 6 characters"),
    confirm_password:
      activeStep !== 2
        ? Yup.string().nullable()
        : Yup.string()
            .required("Confirm password is required")
            .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });
  const navigateFn = () => {
    let navUrl = userPages.UserProfile.path;
    console.log("is redirecting", redirectPath, navUrl, "4^&&^*^*(^(**( ");
    if (redirectPath) {
      navUrl = redirectPath;
    }
    dispatch(setRedirect({ redirectPath: null }));
    navigate(navUrl, { replace: true });
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    // setSubmitting(true);
    try {
      setError("");
      if (activeStep === 0) {
        const form = new FormData();
        form.append(
          useEmail ? "email" : "phone",
          useEmail ? values?.email : values?.phone_number
        );
        const res = useEmail
          ? await verifyEmail(form)
          : await verifyPhone(form);
        if (res?.error) {
          // setErrors(res?.error.data.message);
          // setLoading(false);
          setError(res?.error.data.message);
          throw new Error(res.error.data.message);
        }
        if (!res?.error) {
          setActiveStep(1);
        }
        setCodeSentTo(useEmail ? values.email : values.phone_number);
        // Perform validation and submission logic for the first step
        setSubmitting(true);
        // Example: login logic
      } else if (activeStep === 1) {
        const form = new FormData();
        form.append("code", values.otp);

        const res = useEmail
          ? await validateEmail(form)
          : await validateText(form);
        if (!res?.error) {
          setActiveStep(2);
        }
        if (res?.error) {
          // setErrors(res?.error.data.message);
          // setLoading(false);
          setError(res?.error.data.message);
          throw new Error(res.error.data.message);
        }
        // Perform validation and submission logic for the second step
        setSubmitting(true);
        // const date = moment(values.date_of_birth);
        // const formattedDate = date.format("YYYY-MM-DD");
        // formData.append("date_of_birth", formattedDate);
        // Example: OTP verification logic
      } else {
        const form = new FormData();
        form.append("code", values.otp);
        form.append(
          useEmail ? "email" : "phone",
          useEmail ? values?.email : values?.phone_number?.replace(/\s/g, "")
        );
        form.append("username", values.user_name);
        form.append("password", values.password);
        const date = moment(values.date_of_birth);
        const formattedDate = date.format("YYYY-MM-DD");
        form.append("date_of_birth", formattedDate);
        const res = await signUp(form);

        if (res?.error) {
          // setErrors(res?.error.data.message);
          // setLoading(false);
          setError(res?.error.data.message);
          throw new Error(res.error.data.message);
        }

        const user_info = jwtDecode(res.data.token.access);
        console.log(user_info, "thasd asdiausbd asdiasbdi asd");
        //saving refresh token to local storage
        localStorage.setItem("access", res.data.token.access);
        localStorage.setItem("refresh", res.data.token.refresh);

        dispatch(
          setCredentials({
            accessToken: res.data.token.access,
            refreshToken: res.data.token.refresh,
            user: user_info.username,
            role: user_info.role_name,
            isAuth: true,
            companyName: "public",
            companyType: "public",
          })
        );
        console.log("sami", user_info, "thasd asdiausbd asdiasbdi asd");
        navigateFn();
        // Perform validation and submission logic for the second step
        setSubmitting(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleBack = () => {
    if (activeStep !== 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <Typography sx={{ color: "black", fontWeight: "900", my: 2 }}>
              Sign Up
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "end", my: 1 }}>
              <Typography
                textAlign={{ sm: "end" }}
                style={{
                  border: "none",
                  background: "none",
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "black",
                }}
                variant="span"
                onClick={() => setUseEmail(!useEmail)}
              >
                {useEmail ? "Use Phone" : "Use Email"}
              </Typography>
            </Box>
            {useEmail ? (
              <FormikTextField
                fullWidth
                margin="dense"
                label="Email Address"
                name="email"
                id="email"
              />
            ) : (
              <FormikPhoneField
                fullWidth
                margin="dense"
                label="Phone Number"
                name="phone_number"
                id="phone_number"
              />
            )}
          </>
        );
      case 1:
        // OTP Verification Step
        return (
          <>
            <Box sx={{ my: 3 }}>
              <Box sx={{ mb: 1 }}>
                <Typography sx={{ color: "#646566" }} variant="body2">
                  Phone Verification
                </Typography>
                <Typography
                  style={{
                    color: "black",
                    fontWeight: "900",
                    mb: 2,
                  }}
                  variant="h6"
                >
                  Enter Your OTP code
                </Typography>
              </Box>
              <Typography
                style={{
                  color: "#646566",
                }}
                variant="body2"
              >
                Enter the 6-digit code sent to you at {codeSentTo}
              </Typography>
            </Box>
            <FormikTextField
              fullWidth
              margin="dense"
              label="OTP"
              name="otp"
              id="otp"
            />
          </>
        );
      case 2:
        // User Data Entry Step
        return (
          <>
            <Typography sx={{ color: "black", fontWeight: "900", my: 2 }}>
              Sign Up
            </Typography>

            <FormikTextField
              fullWidth
              margin="dense"
              label="User Name"
              name="user_name"
              id="user_name"
            />
            <FormikDateField
              fullWidth
              margin="dense"
              minDate={minDate}
              label={
                <Typography
                  // color={colors.background.tertiary}
                  fontFamily={"Comfortaa"}
                  fontSize={"14px"}
                  fontWeight={"500"}
                >
                  Date of Birth
                </Typography>
              }
              name="date_of_birth"
              id="date_of_birth"
              required
            />
            <FormikPasswordField
              // required
              fullWidth
              name="password"
              id="password"
              label="Password"
            />
            <FormikPasswordField
              // required
              fullWidth
              name="confirm_password"
              id="confirm_password"
              label="Confirm Password"
            />
          </>
        );
      default:
        return "Unknown step";
    }
  };
  return (
    <>
      {/* <BodyWrapper> */}
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          sx={{
            py: 2,
            px: 2,
            minWidth: { xs: "90vw", md: "65vw", lg: "35vw" },
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              width={64}
              height={64}
              src={
                "https://dev.api.event.testserver.awuraplc.org/image/image/system_setting_logo/large/24591df5890f48b3834ed38d50565c15.png"
              }
              alt="Owl Events"
              sx={{ m: 1 }}
            />
          </Stack>

          <Formik
            initialValues={{
              email: "",
              phone_number: "",
              otp: "",
              user_name: "",
              date_of_birth: "",
              password: "",
              confirm_password: "",
            }}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Box component={Form} noValidate width="100%">
                {getStepContent(activeStep)}
                {error && (
                  <Typography sx={{ color: "red" }}>{error}</Typography>
                )}
                <Box
                  sx={{
                    alignSelf: "flex-end",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    my: 2,
                  }}
                >
                  <Grid container alignItems={"center"}>
                    <Grid item xs={6}>
                      <Button onClick={handleBack} disabled={activeStep === 0}>
                        Back
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      {activeStep === 0 && (
                        <SubmitButton
                          text={"Send OTP"}
                          isSubmitting={
                            verifyEmailLoading || verifyPhoneLoading
                          }
                        />
                      )}
                      {activeStep === 1 && (
                        <SubmitButton
                          text={"Verify OTP"}
                          isSubmitting={
                            validateTextLoading || validateEmailLoading
                          }
                        />
                      )}
                      {activeStep === 2 && (
                        <SubmitButton
                          text={"Register"}
                          isSubmitting={signUpLoading}
                        />
                      )}
                    </Grid>
                  </Grid>

                  {activeStep === 0 && (
                    <StyledLink
                      sx={{
                        fontStyle: "normal",
                        fontFamily: "Comfortaa",
                        fontSize: 16,
                        textDecoration: "none",
                        alignSelf: "flex-start",
                        color: "#DEA449",
                        fontWeight: "900",
                        cursor: "pointer",
                        mt: 1,
                      }}
                      href={`/login`}
                    >
                      Do you have an account? Login
                    </StyledLink>
                  )}
                </Box>
              </Box>
            )}
          </Formik>
          {/* </Box> */}
        </Card>
      </Box>
      {/* </BodyWrapper> */}
    </>
  );
};

export default UserRegister;
