import { Box, InputLabel, FormHelperText, Checkbox } from "@mui/material";
import { styled } from "@mui/system";
import { useField } from "formik";

export const InputWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  marginBottom: "8px",
}));

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: "16px",
  color: "black",
}));

const CustomCheckbox = ({ label, id, name, ...props }) => {
  const [field, meta] = useField(name);

  let error = meta.touched && meta.error;
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "min-content 20px",
        gap: 1,
        alignItems: "center",
      }}
    >
      <StyledInputLabel htmlFor={id}>{label}</StyledInputLabel>
      <Box>
        <Checkbox
          sx={{ ml: "-8px" }}
          checked={field.value}
          {...field}
          {...props}
        />
        {error && <FormHelperText>{meta.error}</FormHelperText>}
      </Box>
    </Box>
  );
};

export default CustomCheckbox;
