import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";
import { StyledText } from "./Styles";

const StyledHeader = styled(Box)(({}) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingRight: "2em",
  paddingLeft: "2em",
}));

const CompanyDetailHeader = () => {
  const navigate = useNavigate()
  return (
    <StyledHeader sx={{ py: 2, color: "primary.main" }}>
      <Stack spacing={0.5}>
        <Stack direction="row" spacing={1}>
          <StyledText  onClick={()=>navigate('/admin/companies')}>Companies</StyledText>
          <KeyboardArrowRightIcon />
          <Typography>Dashboard</Typography>
        </Stack>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            alignItems: "center",
          }}
        >
          <Typography
            variant="h1"
            fontWeight={700}
            fontSize={26}
            sx={{ alignSelf: "flex-end" }}
            color="primary.main"
          >
            Here is What's happening
          </Typography>
        </Box>
      </Stack>
      <Box>
        <Typography variant="body" fontSize={12} fontWeight={700}>
          LAST 30 DAYS
        </Typography>
      </Box>
    </StyledHeader>
  );
};

export default CompanyDetailHeader;
