import { 
  // Box, Card, 
  Chip, Typography } from "@mui/material";
const { styled } = require("@mui/system");

export const StyledHeader = styled(Typography)(({ theme }) => ({
  color: "#333333",
  fontSize: "48px",
  fontWeight: 400,
  //   fontFamily: "Campton",
  textAlign: "start",
  lineHeight: 1.1,
  backgroundColor: "transparent",
  [theme.breakpoints.down("md")]: {
    fontSize: "26px",
  },
}));

export const StyledBody = styled(Typography)({
  color: "#333333",
  fontSize: "14px",
  //   fontFamily: "Campton",
  fontWeight: 400,

  textAlign: "start",
  lineHeight: 1.1,
});

export const StyledButton = styled("button")(({ theme }) => ({
  textTransform: "none",
  //   fontFamily: "Campton",
  fontWeight: "400",
  // border: `0.5px ${colors.background.yellow} solid`,
  backgroundColor: "#333333",
  color: "#F2F2F2",
  transition: "background-color 0.3s ease-in-out", // add transition for smooth effect
  border: "none",
  // Add hover effect
  "&:hover": {
    backgroundColor: "#DEA449",
    color: "#F2F2F2",
  },
  borderRadius: "15px",
  padding: "5px 20px 5px 20px",
}));
export const StyledTab = styled(Chip)({
  // display:'inline',
  // width: "fit-content",
  backgroundColor:'primary',
  borderRadius: 2,
  color: 'tertiary',
  height: "25px",
  margin: "5px",
  width: "8rem",
  "&:hover": {
    backgroundColor: 'secondary'

    // filter: `drop-shadow(5px 5px 10px rgba(222, 164, 73, 0.5))`,
  },

});
export const StyledSmallText = styled("span")({
  color: 'secondary',
  fontSize: "2.3rem",
  fontFamily: "Comfortaa",
  fontWeight: 'bold',
});