import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import EventNoteIcon from "@mui/icons-material/EventNote";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

export const side1 = [
  {
    text: "My Tickets",
    icon: <HomeOutlinedIcon />,
    path: "/users-web",
  },
  // {
  //   text: "My Tickets",
  //   icon: <EventNoteIcon />,
  //   path: "/users-web/my-tickets",
  // },
];

export const side2 = [
  {
    text: "Profile",
    icon: <PersonOutlineOutlinedIcon />,
    path: "/users-web/profile",
  },
];
