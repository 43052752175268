import { apiSlice } from "../../app/api/apiSlice";
// import { format } from "date-fns";
import { getParsedDate, getParsedTime } from "helpers/dateFormat";

function getLocation(coordinates) {
  return `${coordinates.lat},${coordinates.lon}`;
}

export const eventsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllEvents: builder.query({
      query: (query) => {
        const { pagination, searchValue, filters } = query;
        const { pageIndex, pageSize } = pagination;
        const searchQuery = searchValue ? `&query=${searchValue}` : "";
        console.log(pageIndex, pageSize);
        return {
          url: `/portal/event/?page=${pageIndex}&page_size=${pageSize}${searchQuery}${filters}`,
        };
      },
      transformResponse: (response) => {
        const { page_metadata, data } = response.data;
        const events = data.map((item) => ({
          ...item,
          name: item.name,
          location: item.is_virtual
            ? null
            : getLocation(item.location.coordinates),
          event_categories: item.categories.map((item) => item.name).join(","),
          event_type: item.event_type,
          id: item.id,
          originalEvent: item,
          isCanceled: item.is_canceled,
          created_by: item.created_by.email,
          status: item.status,
          attendees_count: item.attendees_count,
          location_name: item.is_virtual
            ? item.virtual_meeting_url
            : item.location.name,
          has_invitation: item.has_invitation,
          ticket_scan_method: item.ticket_scan_method,
          formatted_start_date: item.start_date
            ? getParsedDate(new Date(item.start_date))
            : "-",
          formatted_start_time: item.start_date
            ? getParsedTime(new Date(item.start_date))
            : "-",
          formatted_end_date: item.end_date
            ? getParsedDate(new Date(item.end_date))
            : "-",
          formatted_end_time: item.end_date
            ? getParsedTime(new Date(item.end_date))
            : "-",
        }));
        return {
          page_metadata,
          data: events,
        };
      },
      providesTags: ["Event"],
    }),
    getAllEvents2: builder.query({
      query: (query) => {
        const { pageIndex, pageSize } = query;

        console.log(pageIndex, query);

        return {
          url: `/portal/event/?page=${pageIndex}&pageSize=${pageSize}`,
        };
      },
      transformResponse: (response) => {
        const { page_metadata, data } = response.data;
        const events = data.map((item) => ({
          ...item,
          name: item.name,
          location: item.is_virtual
            ? null
            : getLocation(item.location.coordinates),
          event_categories: item.categories.map((item) => item.name).join(","),
          event_type: item.event_type,
          id: item.id,
          originalEvent: item,
          isCanceled: item.is_canceled,
          created_by: item.created_by.email,
          status: item.status,
          attendees_count: item.attendees_count,
          location_name: item.is_virtual
            ? item.virtual_meeting_url
            : item.location.name,
          has_invitation: item.has_invitation,
          ticket_scan_method: item.ticket_scan_method,
          formatted_start_date: item.start_date
            ? getParsedDate(new Date(item.start_date))
            : "-",
          formatted_start_time: item.start_date
            ? getParsedTime(new Date(item.start_date))
            : "-",
          formatted_end_date: item.end_date
            ? getParsedDate(new Date(item.end_date))
            : "-",
          formatted_end_time: item.end_date
            ? getParsedTime(new Date(item.end_date))
            : "-",
        }));
        return {
          page_metadata,
          data: events,
        };
      },
      providesTags: ["Event"],
    }),

    getEvent: builder.query({
      query: (id) => {
        return {
          url: `/portal/event/detail/?event_id=${id}`,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: ["Event"],
    }),
    getSingleEventStat: builder.query({
      query: (id) => {
        return {
          url: `/portal/dashboard/event_stat/?event_id=${id}`,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: ["Event"],
    }),

    getEventStat: builder.query({
      query: (query) => {
        const { pagination, searchValue, filters } = query;
        const { pageIndex, pageSize } = pagination;
        const searchQuery = searchValue ? searchValue : "";
        return {
          url: `/portal/dashboard/events/stat/?page=${pageIndex}&page_size=${pageSize}&${searchQuery}`,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: ["Event"],
    }),

    createEvent: builder.mutation({
      query: (body) => ({
        url: "/portal/event/create/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Event"],
    }),
    createPushNotifications: builder.mutation({
      query: (body) => ({
        url: "/portal/push_notification/event/send/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Event"],
    }),
    resendPushNotification: builder.mutation({
      query: (body) => ({
        url: "/portal/push_notification/resend/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Event"],
    }),

    getPushNotifications: builder.query({
      query: (query) => {
        const { pagination, searchValue, filters } = query;
        const { pageIndex, pageSize } = pagination;
        const searchQuery = searchValue ? searchValue : "";
        return {
          url: `/portal/push_notification/?page=${pageIndex}&page_size=${pageSize}&${searchQuery}`,
        };
      },
      transformResponse: (response) => {
        const { page_metadata, data } = response.data;

        return {
          page_metadata,
          data,
        };
      },
      providesTags: ["Event"],
    }),
    getSUPushNotifications: builder.query({
      query: (query) => {
        const { pagination, searchValue, filters } = query;
        const { pageIndex, pageSize } = pagination;
        const searchQuery = searchValue ? searchValue : "";
        return {
          url: `/su/push_notification/?${searchQuery}&page=${pageIndex}&page_size=${pageSize}`,
        };
      },
      transformResponse: (response) => {
        const { page_metadata, data } = response.data;

        return {
          page_metadata,
          data,
        };
      },
      providesTags: ["Event"],
    }),
    createSUPushNotifications: builder.mutation({
      query: (body) => ({
        url: "/su/push_notification/send/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Event"],
    }),
    createSUPushWebviewNotification: builder.mutation({
      query: (body) => ({
        url: "/su/push_notification/webview/send/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Event"],
    }),
    createPromoPushNotification: builder.mutation({
      query: (body) => ({
        url: "/portal/push_notification/event/promo_code/send/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Event"],
    }),
    updateEvent: builder.mutation({
      query: (body) => ({
        url: "/portal/event/update/",
        method: "PUT",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Event"],
    }),
    updateTicketScan: builder.mutation({
      query: (body) => ({
        url: "/portal/event/set_allow_ticket_scan/",
        method: "PUT",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Event"],
    }),
    updateVendorAllowed: builder.mutation({
      query: (body) => ({
        url: "/portal/event/vendor/set_allow_vendors/",
        method: "PUT",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Event"],
    }),
    updateEventPublish: builder.mutation({
      query: (body) => ({
        url: "/portal/event/update/is_published/",
        method: "PUT",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Event"],
    }),
    updateEventAliveRate: builder.mutation({
      query: (body) => ({
        url: "/portal/event/set_ticket_alive_rate/",
        method: "PUT",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Event"],
    }),
    updateEventFree: builder.mutation({
      query: (body) => ({
        url: "/portal/event/update/is_free/",
        method: "PUT",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Event"],
    }),
    updateAvailableTickets: builder.mutation({
      query: (body) => ({
        url: "/portal/event/update/total_available_ticket/",
        method: "PUT",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Event"],
    }),
    updateTicketsCheckout: builder.mutation({
      query: (body) => ({
        url: "/portal/event/update/ticket_checkout_limit/",
        method: "PUT",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Event"],
    }),
    updateAvailableBundles: builder.mutation({
      query: (body) => ({
        url: "/portal/event/update/total_available_ticket/",
        method: "PUT",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Event"],
    }),
    getSpecialServices: builder.query({
      query: (id) => {
        return {
          url: `/portal/event/special_services/?event_id=${id}`,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      invalidatesTags: ["Event"],
    }),
    addSpecialServices: builder.mutation({
      query: (body) => ({
        url: "/portal/event/special_services/add/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Event"],
    }),
    updateSpecialServices: builder.mutation({
      query: (body) => ({
        url: "/portal/event/special_services/edit/",
        method: "PATCH",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Event"],
    }),
    deleteSpecialServices: builder.mutation({
      query: (body) => ({
        url: "/portal/event/special_services/remove/",
        method: "DELETE",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Event"],
    }),
    postponeEvent: builder.mutation({
      query: (body) => ({
        url: "/portal/event/postpone/",
        method: "PUT",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Event"],
    }),
    extendEvent: builder.mutation({
      query: (body) => ({
        url: "/portal/event/extend/",
        method: "PUT",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Event"],
    }),
    cancelEvent: builder.mutation({
      query: (body) => ({
        url: "/portal/event/cancel/",
        method: "PUT",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Event"],
    }),
    deleteEvent: builder.mutation({
      query: ({ event_ids, password }) => ({
        url: "/portal/event/delete/",
        method: "DELETE",
        body: { event_ids, password },
      }),
      invalidatesTags: ["Event"],
    }),
    copyEvent: builder.mutation({
      query: ({ event_id, eventInfo }) => ({
        url: "/portal/event/copy/",
        method: "POST",
        body: { event_id, eventInfo },
      }),
      invalidatesTags: ["Event"],
    }),
    updateAllowAllPaymentmethods: builder.mutation({
      query: (body) => ({
        url: "/portal/event/update/allow_all_payment_methods/",
        method: "put",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Event"],
    }),
  }),
});

export const {
  useCopyEventMutation,
  useGetAllEventsQuery,
  useGetAllEvents2Query,
  useGetEventQuery,
  useCreatePushNotificationsMutation,
  useResendPushNotificationMutation,
  useGetSpecialServicesQuery,
  useUpdateEventFreeMutation,
  useCreateEventMutation,
  useUpdateEventMutation,
  useUpdateTicketScanMutation,
  useUpdateVendorAllowedMutation,
  useUpdateEventPublishMutation,
  useUpdateAvailableTicketsMutation,
  useUpdateTicketsCheckoutMutation,
  useUpdateEventAliveRateMutation,
  useUpdateSpecialServicesMutation,
  usePostponeEventMutation,
  useCreatePromoPushNotificationMutation,
  useAddSpecialServicesMutation,
  useDeleteSpecialServicesMutation,
  useGetEventStatQuery,
  useGetSingleEventStatQuery,
  useCancelEventMutation,
  useDeleteEventMutation,
  useGetPushNotificationsQuery,
  useGetSUPushNotificationsQuery,
  useExtendEventMutation,
  useCreateSUPushNotificationsMutation,
  useCreateSUPushWebviewNotificationMutation,
  useUpdateAllowAllPaymentmethodsMutation,
} = eventsApiSlice;
