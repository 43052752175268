import { 
  // Box, 
  Grid 
} from "@mui/material";
import { styled } from "@mui/system";
// import image from "res/images/bg-image.jpeg";
// import concert from "res/images/concert.svg";
import concert2 from "res/images/production/registration2.jpg";
import vendor from "res/images/Vendor.webp";
import service from "res/images/ServiceProvidersLogin.webp";
export const StyledAuthBackground = styled(Grid)(({ theme }) => ({
  height: "100vh",
  backgroundImage: `url(${concert2})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",

  backgroundSize: "cover",
  // backgroundColor:
  //   theme.palette.type === "light"
  //     ? theme.palette.grey[50]
  //     : theme.palette.grey[900],

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledAuthVendorBackground = styled(Grid)(({ theme }) => ({
  height: "100vh",
  backgroundImage: `url(${vendor})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",

  backgroundSize: "cover",
  // backgroundColor:
  //   theme.palette.type === "light"
  //     ? theme.palette.grey[50]
  //     : theme.palette.grey[900],

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledAuthServiceProviderBackground = styled(Grid)(
  ({ theme }) => ({
    height: "100vh",
    backgroundImage: `url(${service})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",

    backgroundSize: "cover",
    // backgroundColor:
    //   theme.palette.type === "light"
    //     ? theme.palette.grey[50]
    //     : theme.palette.grey[900],

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  })
);

export const StyledWrapper = styled(Grid)(({ theme }) => ({
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledLoginContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "5px",
}));

export const StyledLoginItemsContainer = styled("div")(({ theme }) => ({
  margin: theme.spacing(2, 6),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));
