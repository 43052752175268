import React from "react";
import PropTypes from "prop-types";
import { Button, CircularProgress, Typography } from "@mui/material";

const StepperNavigation = (props) => {
  const { isLastStep, hasPrevious, handlePrev, isSubmitting,iss } = props;

  return (
    <React.Fragment>
      {false ? (
        <React.Fragment>
          <Typography variant="h5" gutterBottom>
            Thank you for your order.
          </Typography>
          <Typography variant="subtitle1">
            Your order number is #2001539. We have emailed your order
            confirmation, and will send you an update if there are any changes.
          </Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {hasPrevious && (
              <Button onClick={handlePrev} sx={{ mt: 3, ml: 1 }}>
                Back
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 3, ml: 1 }}
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting || iss ? (
                <CircularProgress  color="secondary" size={24} />
              ) : isLastStep ? (
                "Place order"
              ) : (
                "Next"
              )}
            </Button>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

StepperNavigation.propTypes = {
  isLastStep: PropTypes.bool.isRequired,
  hasPrevious: PropTypes.bool.isRequired,
  handlePrev: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default StepperNavigation;
