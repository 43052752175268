import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

import { FaTicketAlt } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const EventBundleHeader = () => {
  const StyledHeader = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "1rem",
    paddingRight: "10px",
  }));
  const StyledBackButton = styled(KeyboardBackspaceIcon)(({ theme }) => ({
    marginLeft: 16,
    color: theme.palette.primary.main,
    "&:hover": {
      cursor: "pointer",
    },
    marginBottom: 2,
  }));
  const StyledContactGroupIcon = styled(FaTicketAlt)(({ theme }) => ({
    marginLeft: 16,
    fontSize: 24,
    color: theme.palette.primary.main,
    "&:hover": {
      cursor: "pointer",
    },
  }));
  const navigate = useNavigate();

  return (
    <StyledHeader>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "center",
          marginTop: 5,
        }}
      >
        <StyledBackButton onClick={() => navigate(-1)} />

        <Typography
          ml={2}
          variant="h1"
          fontWeight={700}
          fontSize={26}
          sx={{ alignSelf: "flex-end" }}
          color="primary.main"
        >
          Bundle Rules
        </Typography>
        <StyledContactGroupIcon />
      </Box>
    </StyledHeader>
  );
};

export default EventBundleHeader;
