// import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CategoryIcon from "@mui/icons-material/Category";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { Box, Button, Grid, Modal, Skeleton, Typography } from "@mui/material";
import { baseUrl } from "app/api/apiSlice";
import DefaultSnackbar from "components/DefaultSnackbar";
import VendorApplyEventModal from "components/formModals/vendorEvent/VendorApplyEventModal";
import { differenceInDays } from "date-fns";
import { useGetPublicEventQuery } from "features/publicEvents/publicEventsApiSlice";
import { getParsedDate, getParsedTime } from "helpers/dateFormat";
import { useLanguage } from "hooks/useLanguage";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const DescListItem = (props) => {
  const { Icon, desc } = props;
  // const { language } = useLanguage();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        py: 1.5,
      }}
    >
      <Icon sx={{ width: 28, height: 28 }} />
      <Typography
        color="textPrimary"
        variant="body"
        fontWeight={500}
        fontSize={"1rem"}
        sx={{ ml: 1.5 }}
      >
        {desc}
      </Typography>
    </Box>
  );
};

const EventDetail = (props) => {
  const { language } = useLanguage();
  const [searchParams] = useSearchParams();
  const event_id = searchParams?.get("event_id");
  const vendorApply = searchParams?.get("vendor_apply");
  const { data: event, isLoading } = useGetPublicEventQuery({ id: event_id });
  const [isTrancuated, setIsTrancuated] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [modalData, setModalData] = useState({
    open: vendorApply === "true" ? true : false,
    eventId: event_id,
    spaceId: 1,
  });

  const info = event?.detail ? event.detail[language] : "";
  const [trancuatedValue, setTrancuatedValue] = useState();
  useEffect(() => {
    if (info?.length > 100) {
      setIsTrancuated(true);
      setTrancuatedValue(info?.substring(0, 100));
    }
    if (info?.length < 100) {
      setIsTrancuated(true);
      setTrancuatedValue(info?.substring(0, 30));
    }

  }, [info]);

  const [snackOptions, setSnackOptions] = useState({
    open: false,
    text: "",
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOptions({
      open: false,
    });
  };

  return (
    <>
      <Box sx={{ px: 1, py: 2 }}>
        {isLoading ? (
          <Box>
            <Skeleton variant="rectangular" width="100%" height="500px" />
            <Skeleton variant="text" width="40%" sx={{ mt: 6 }} />
            <Skeleton variant="text" width="20%" />
            <Skeleton variant="text" width="40%" sx={{ mt: 2 }} />
            <Skeleton variant="text" width="20%" />
            <Skeleton variant="text" width="40%" sx={{ mt: 2 }} />
            <Skeleton variant="text" width="20%" />
          </Box>
        ) : (
          <>
            {/* image */}
            <Box sx={{ px: { xs: 0, sm: 2 } }}>
              <img
                src={`${baseUrl}${event?.image?.image_large_path}`}
                style={{ objectFit: "cover", width: "100%", height: "500px" }}
                alt="event images large path"
              />
            </Box>
            <Box sx={{ mt: { xs: 2, sm: 6 }, px: { xs: 0, sm: 12 } }}>
              {/* title h1, and some info */}
              <Grid container spacing={1}>
                <Grid item xs={12} sm={8}>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      variant="h3"
                      fontWeight={700}
                      color="textPrimary"
                      sx={{
                        fontSize: { xs: 28, sm: 36 },
                      }}
                    >
                      {event.name[language]}
                    </Typography>
                    {event.allow_vendors && (
                      <Box sx={{ ml: 1, display: { xs: "none", sm: "flex" } }}>
                        <StorefrontIcon
                          color="primary"
                          sx={{ width: 20, height: 20 }}
                        />
                        <Typography
                          variant="p"
                          fontWeight={300}
                          color="primary"
                        >
                          &nbsp; Vendor enabled
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Box display="flex" justifyContent={"space-between"}>
                    <Typography variant="p" fontWeight={600} color="primary">
                      {differenceInDays(new Date(event.start_date), new Date())}{" "}
                      Days left
                    </Typography>
                    {event.allow_vendors && (
                      <Box sx={{ ml: 1, display: { xs: "flex", sm: "none" } }}>
                        <StorefrontIcon
                          color="primary"
                          sx={{ width: 20, height: 20 }}
                        />
                        <Typography
                          variant="p"
                          fontWeight={300}
                          color="primary"
                        >
                          &nbsp; Vendor enabled
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
              {/* short description in a list category, organizer */}
              <Box sx={{ mt: { xs: 2, sm: 6 } }}>
                <Grid container>
                  <Grid item xs={12} lg={6}>
                    <DescListItem
                      Icon={CategoryIcon}
                      desc={event.categories.length > 0?event?.categories?.map((obj) => obj.name[language]).join(", "): "..."
                        // event.categories.length > 0
                        //   ? event.categories[0]?.name[language]
                        //   : "..."
                      }
                    />
                    <DescListItem
                      Icon={LocationOnIcon}
                      desc={event.location.name}
                    />
                    {/* <DescListItem Icon={AttachMoneyIcon} desc={"1500"} /> */}
                    <DescListItem
                      Icon={CalendarMonthIcon}
                      desc={`${getParsedDate(
                        new Date(event.start_date)
                      )} (${getParsedTime(
                        new Date(event.start_date)
                      )}) - ${getParsedDate(
                        new Date(event.end_date)
                      )} (${getParsedTime(new Date(event.end_date))})`}
                    />
                    <Box mt={2}>
                      {event.allow_vendors && (
                        <Box width="70%" sx={{ mb: 4 }}>
                          <Button
                            variant="outlined"
                            fullWidth
                            onClick={() =>
                              setModalData((prev) => ({ ...prev, open: true }))
                            }
                          >
                            <Typography color="textPrimary" >
                            <span style={{ fontWeight: 500 }}> Apply as a vendor </span>
                              </Typography>
                          </Button>
                        </Box>
                      )}
                      <Typography variant={"p"} color="textPrimary">
                        <span style={{ fontWeight: 600 }}>Organized By:</span> @
                        {event.company.name[language]}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Typography
                      variant={"h5"}
                      fontWeight={500}
                      color="textPrimary"
                    >
                      About
                    </Typography>
                    <Box sx={{ mt: 1.5 }}>
                      {isTrancuated ? trancuatedValue : info}
                      {(isTrancuated || isExpanded) && (
                        <Typography
                          variant={"p"}
                          color="primary"
                          onClick={() => {
                            setIsTrancuated(!isTrancuated);
                            setIsExpanded(!isExpanded);
                          }}
                          sx={{ cursor: "pointer" }}
                        >
                          {info.length > 30&&(isExpanded ? " less" : " more")}
                        </Typography>
                      )}
                    </Box>
                    <Box mt={4}>
                      <iframe
                        title="map"
                        width="100%"
                        height="235"
                        id="gmap_canvas"
                        src={`https://maps.google.com/maps?q=${event.location.coordinates.lat},${event.location.coordinates.lon}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                        frameBorder="0"
                        scrolling="no"
                        marginHeight={0}
                        marginWidth={0}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </>
        )}
      </Box>
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={modalData.open}
        onClose={() => setModalData((prev) => ({ ...prev, open: false }))}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <VendorApplyEventModal
          modalData={modalData}
          setModalData={setModalData}
          onSucceed={(text) => {
            setSnackOptions({
              open: true,
              text: text || "Vendor request sent",
            });
          }}
        />
      </Modal>
      <DefaultSnackbar options={snackOptions} handleClose={handleClose} />
    </>
  );
};

export default EventDetail;
