import MaterialReactTable, {
  MRT_FullScreenToggleButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
} from "material-react-table";

import { Delete as DeleteIcon } from "@mui/icons-material/";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";

import DefaultSnackbar from "components/DefaultSnackbar";
import ConfirmDeleteDialog from "components/formModals/ConfirmDeleteDialog";
import GlobalSearch from "components/GlobalSearch";
import StyledToolbarButton from "components/styled-components/StyledToolbarButton";
import { download, generateCsv, mkConfig } from "export-to-csv";
import { StyledInnerToolbarWrapper, StyledToolbarWrapper } from "./Styles";
import TableActionsMenu from "./TableActionsMenu";
import { useModal } from "contexts/modalContext";
import { FaIdBadge } from "react-icons/fa";
import AttendeeBadgeModal from "components/formModals/Badge/AttendeeBadgeModal";
import StyledToolbarMenu from "components/styled-components/StyledToolbarMenu";
import { useGetInvitationTypesQuery, useUpdateBulkInvitationTypeMutation } from "features/invitations/invitationTypeSlice";

export default function EnhancedDataTable({
  rows,
  headCells,
  onDelete,
  onEdit,
  onView,
  onSelect,
  isRowDisabled,
  isEvent = false,
  editIcon = true,
  deleteIcon = true,
  showIcon = true,
  actions = [],
  isLoading = false,
  manualPagination = false,
  pagination,
  onPaginationChange,
  rowCount = null,
  toolbarActions = null,
  allowExport = true,
  additionalExportActions = null,
  onDeleteSelected,
  editTooltip = null,
  deleteTooltip = null,
  viewToolTip = null,
  toolbarInternalActions = [],
  editDisabled,
  deleteDisabled,
  allowMassDelete = true,
  columnPinning = {},
  enableRowSelection = true,
  enableRowNumbers = true,
  searchProps = null,
  event = null,
  tableType = "",
  invitationTypes
}) {
  const rowActions = useMemo(() => {
    return ({ row }) => {
      return (
        <TableActionsMenu
          row={row}
          actions={actions}
          isRowDisabled={isRowDisabled}
          showIcon={showIcon}
          editIcon={editIcon}
          deleteIcon={deleteIcon}
          onView={onView}
          onEdit={onEdit}
          onDelete={onDelete}
          viewTooltip={viewToolTip}
          editTooltip={editTooltip}
          deleteTooltip={deleteTooltip}
          isEditDisabled={isEditDisabled}
          isDeleteDisabled={isDeleteDisabled}
        />
      );
    };
  }, [showIcon, editIcon, deleteIcon, actions]);
  const [rowSelection, setRowSelection] = useState({});
  
  const [deleteSelected, setDeleteSelected] = useState(false);
  const [snackOptions, setSnackOptions] = useState({ open: false, text: "" });
  const noActionTabTables = [
    "attendeeBadgeList",
    "transactionHistoryList",

    "pushNotificationTable",
  ];
  const noToolbarActionsTables = [
    "attendeeBadgeList",
    "transactionHistoryList",
  ];

  const columns = useMemo(
    () =>
      headCells.map(({ label, id, ...item }) => ({
        header: label,
        accessorKey: id,
        ...item,
      })),
    [headCells]
  );

  const filteredRowIds = useMemo(() => {
    if (isRowDisabled) {
      return rows.filter((row) => !isRowDisabled(row)).map((row) => row.id);
    }
    console.log(rows);
    return rows.map((row) => row.id);
  }, [rows]);

  const onRowSelectionChange = (e) => {
    let r = e(rowSelection);

    if (isRowDisabled) {
      Object.keys(r).forEach((key) => {
        if (!filteredRowIds.includes(parseInt(key))) delete r[key];
      });
    }

    const isAllSelected =
      Object.keys(rowSelection).length === filteredRowIds.length &&
      Object.keys(rowSelection).length === Object.keys(r).length;
    if (isAllSelected) {
      setRowSelection({});
      return;
    }

    setRowSelection(r);
  };

  const numberOfSelectedRows = Object.keys(rowSelection).map((i) =>
    Number(i)
  ).length;

  useEffect(() => {
    if (onSelect) onSelect(Object.keys(rowSelection).map((i) => Number(i)));
  }, [rowSelection]);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOptions({
      open: false,
    });
  };

  const [enableRowActions, setEnableRowActions] = useState(true);
  useEffect(() => {
    setEnableRowActions(
      showIcon || deleteIcon || editIcon || actions.length > 0
    );
  }, [showIcon, deleteIcon, editIcon, actions]);

  const [defaultPagination, setDefaultPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const csvConfig = useMemo(() => {
    const csvOptions = {
      useKeysAsHeaders: false,
      columnHeaders: columns.map((c) => c.header),
    };

    return mkConfig(csvOptions);
  }, [columns]);

  const handleExportRows = useCallback(
    (selectedRows) => {
      console.log("selected rows", selectedRows);      
      const csvData = selectedRows
      .map((row) => row.original)
      .map((row) => {
        const info = {};
        columns.forEach((col) => {
          console.log("col test", col);        
          if (col.header === 'Event Schedule') {            
            info[col.header] = `${"Date: " + row[col.accessorKey].start_date + " Start: " + row[col.accessorKey].start_time + " End: " + row[col.accessorKey].end_time}`;
          }
          else {              
            info[col.header] = col.header == 'Image' ? "Image" : row[col.accessorKey];
          }
        });
        
        console.log("info test", info);
        return info;
      });
      console.log("selected csv", csvData)
      const csv = generateCsv(csvConfig)(csvData);
      console.log("csv normal", csv);      
      download(csvConfig)(csv);
      setSnackOptions({
        open: true,
        text: "Successfully exported selected table data",
      });
    },
    [columns, csvConfig]
  );
  
  const handleExportData = useCallback(() => {
    const csvData = rows.map((row) => {
      const info = {};
      columns.forEach((col) => {
        if (col.header === 'Event Schedule') {            
          info[col.header] = `${"Date: " + row[col.accessorKey].start_date + " Start: " + row[col.accessorKey].start_time + " End: " + row[col.accessorKey].end_time}`;
        }
        else {            
          info[col.header] = col.header == 'Image' ? "Image" : row[col.accessorKey];
        }
      });
      console.log("info test", info);
      return info;
    });
    console.log("selected csv", csvData)
    const csv = generateCsv(csvConfig)(csvData);
    console.log("csv normal", csv);      
    download(csvConfig)(csv);
    setSnackOptions({
      open: true,
      text: "Successfully exported table data",
    });
  }, [rows, columns, csvConfig]);
  const handleDeleteSelection = async (password) => {
    if (onDeleteSelected)
      await onDeleteSelected(
        Object.keys(rowSelection).map((i) => Number(i)),
        password
      );
    setRowSelection({});
  };

  const isEditDisabled = (row) => {
    if (isRowDisabled !== undefined && editDisabled !== undefined) {
      return isRowDisabled(row) || editDisabled(row);
    }
    if (isRowDisabled !== undefined) return isRowDisabled(row);
    if (editDisabled !== undefined) return editDisabled(row);

    return false;
  };

  const isDeleteDisabled = (row) => {
    if (isRowDisabled !== undefined && deleteDisabled !== undefined) {
      return isRowDisabled(row) || deleteDisabled(row);
    }
    if (isRowDisabled !== undefined) return isRowDisabled(row);
    if (deleteDisabled !== undefined) return deleteDisabled(row);

    return false;
  };

  const { showModal, toggleModal } = useModal();
  const isTableAttendeeBadge = noActionTabTables.includes(tableType);
  const isNoToolbarActionsTable = noToolbarActionsTables.includes(tableType);

  const [bulkUpdateInvitationTypes, { isLoading: isSendLoading }] =
  useUpdateBulkInvitationTypeMutation();

  const [anchorEl, setAnchorEl] = useState(null);
  
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if(invitationTypes?.data.length)
      setAnchorEl(event.currentTarget);
    else {
      setSnackOptions({
        text: "No invitation types added",
        open: true,
        severity: "error",
      });
    }
  };
  
  const handleClosing = (selection) => {
    setAnchorEl(null);  
    if(typeof selection === 'number') {
      console.log({
        'invitation_ids': Object.keys(rowSelection).map(row => parseInt(row)),
        'invitation_type_id': selection
      });

      console.log(rowSelection);
      bulkUpdateInvitationTypes({
        'invitation_ids': Object.keys(rowSelection).map(row => parseInt(row)),
        'invitation_type_id': selection
      }).then((returned) => {
        console.log(returned);          
        setSnackOptions({
          text: "Successfully modified invitaton types",
          open: true,
          severity: "success",
        });
        window.location.reload()
      }).catch(error => {
        console.log(error);          
        setSnackOptions({
          text: "failed to modified invitaton types",
          open: true,
          severity: "error",
        });
      })      
    }
    console.log('here');
  };

  console.log(allowExport);
  console.log(tableType);
  console.log(event);
  
  return (
    <Box>
      <MaterialReactTable
        columns={columns}
        data={rows}
        enableRowNumbers={enableRowNumbers}
        enableRowSelection={enableRowSelection}
        enableRowActions={!isTableAttendeeBadge ? enableRowActions : null}
        enableSubRowSelection={true}
        manualPagination={manualPagination}
        enablePinning
        state={{
          isLoading,
          rowSelection,
          pagination: manualPagination ? pagination : defaultPagination,
        }}
        initialState={{
          density: "compact",
          columnPinning,
        }}
        onPaginationChange={
          manualPagination ? onPaginationChange : setDefaultPagination
        }
        rowCount={manualPagination ? rowCount : null}
        enableDensityToggle={true}
        getRowId={(originalRow) => originalRow.id}
        onRowSelectionChange={onRowSelectionChange}
        renderRowActions={rowActions}
        positionActionsColumn="last"
        muiSelectCheckboxProps={({ row }) => ({
          disabled: isRowDisabled ? isRowDisabled(row.original) : false,
        })}
        muiTableBodyRowProps={({ row, table }) => ({
          onClick: (e) => {
            if (e.target.attributes[0].nodeValue == "visible") {
              return;
            }
            if (
              e.target?.id !== "row-menu" &&
              e.target?.id !== "demo-customized-button" &&
              e.target?.parentNode?.id !== "demo-customized-menu" &&
              e.target.nodeName !== "svg" &&
              e.target.nodeName !== "IMG"
            ) {
              if (onEdit && !isEvent)
                return onEdit(row.original.id, row.original);
              if (onView && isEvent)
                return onView(row.original.id, row.original);
              return () =>
                setSnackOptions({
                  text: "No detail page for this row",
                  open: true,
                  severity: "info",
                });
            }

            return () => {};
          },
          sx: {
            "&:hover > *": { cursor: "pointer" },
          },
        })}
        renderTopToolbarCustomActions={({ table }) => {
          if (!isNoToolbarActionsTable) {
            return (
              <StyledToolbarWrapper>
                {toolbarActions}

                {allowExport && (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "1rem",
                      p: "0.5rem",
                      flexWrap: "wrap",
                    }}
                  >
                    <StyledToolbarButton
                      onClick={handleExportData}
                      startIcon={<FileDownloadIcon />}
                    >
                      <Typography variant="p">Export All Data</Typography>
                    </StyledToolbarButton>

                    <StyledToolbarButton
                      disabled={
                        !table.getIsSomeRowsSelected() &&
                        !table.getIsAllRowsSelected()
                      }
                      //only export selected rows
                      onClick={() =>
                        handleExportRows(table.getSelectedRowModel().rows)
                      }
                      startIcon={<FileDownloadIcon />}
                    >
                      <Typography variant="p">Export Selected Rows</Typography>
                    </StyledToolbarButton>
                    {additionalExportActions}
                  </Box>
                )}
              </StyledToolbarWrapper>
            );
          }
          return null;
        }}
        renderToolbarInternalActions={({ table }) => {
          if (!isTableAttendeeBadge) {
            return (
              <StyledInnerToolbarWrapper>
                {toolbarInternalActions.map((action) => action)}
                {numberOfSelectedRows > 0 && allowMassDelete && (
                  <Tooltip title="Delete Selected Rows">
                    <span>
                      <IconButton
                        onClick={() => {
                          setDeleteSelected(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
                {searchProps && <GlobalSearch {...searchProps} />}
                <MRT_ToggleFiltersButton table={table} />
                <MRT_ShowHideColumnsButton table={table} />
                <MRT_ToggleDensePaddingButton table={table} />
                <MRT_FullScreenToggleButton table={table} />
              </StyledInnerToolbarWrapper>
            );
          }
          if (tableType === "attendeeBadgeList") {
            return (
              <StyledInnerToolbarWrapper>
                {toolbarInternalActions.map((action) => action)}
                {numberOfSelectedRows > 0 && (
                  <>
                    <StyledToolbarMenu disabled={Object.keys(rowSelection).length < 1 || isSendLoading} anchorEl={anchorEl} open={open} 
                      handleClose={handleClosing} handleClick={handleClick} 
                      options={invitationTypes?.data?.map(invitation => ({
                        id: invitation.id, 
                        name: invitation.name}))}
                    /> 
                    
                    <StyledToolbarButton
                      startIcon={<FaIdBadge />}
                      sx={{ width: "145px", marginLeft: '2rem' }}
                      onClick={() => {
                        const selectedRowsModel = table.getSelectedRowModel();
                        const rowValue = selectedRowsModel.rows.map(
                          (row) => row.original
                        );

                        toggleModal("modal5", { rowValue });
                        console.log("Selected Rows:", rowValue);
                      }}
                    >
                      <Typography variant="p">Print Badge</Typography>
                    </StyledToolbarButton>
                  </>
                )}
                {searchProps && <GlobalSearch {...searchProps} />}
              </StyledInnerToolbarWrapper>
            );
          }
        }}
      />

      {deleteSelected && (
        <ConfirmDeleteDialog
          open={deleteSelected}
          onClose={() => setDeleteSelected(false)}
          action={handleDeleteSelection}
          text={`Delete ${numberOfSelectedRows} items`}
        />
      )}
      {showModal.modal5 && <AttendeeBadgeModal event={event} />}
      <DefaultSnackbar options={snackOptions} handleClose={handleClose} />
    </Box>
  );
}
