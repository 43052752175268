import React, { useState } from "react";
import { Form, Formik, 
} from "formik";
import { Typography } from "@mui/material";

import { ModalItemsContainer } from "components/CustomModal/Styles";
import {
  SubmitButton,
} from "components/form-components/CustomFormikFields";
import { useApplyEventMutation } from "features/vendorEvent/vendorEventApiSlice";

import SelectSpaces from "./SelectSpaces";
import * as yup from "yup";

const validationSchema = yup.object({
  event_space_id: yup.string().required("Please select a space."),
});

const VendorApplyEventModal = (props) => {
  const { modalData, setModalData } = props;
  const [errorMessage, setErrorMessage] = useState(null);

  const [create] = useApplyEventMutation();

  const handleClose = () => {
    setModalData((prev) => ({ ...prev, open: !prev.open }));
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const formData = new FormData();
    formData.append("event_space_id", values.event_space_id);
    try {
      const res = await create(formData);
      setSubmitting(false);
      if (res?.error) {
        throw new Error(res.error.data.message);
      }
      props.onSucceed("Vendor request sent");
      handleClose();
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  return (
    <ModalItemsContainer sx={{ width: "80rem" }}>
      <Typography
        variant="h2"
        align="center"
        sx={{ fontWeight: 700, mb: 3, fontSize: 24, color: "primary.main" }}
      >
        Select a space
      </Typography>
      <Formik
        initialValues={{ event_space_id: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <Typography
              align="center"
              variant="p"
              sx={{ mb: 3, fontSize: 20, color: "red" }}
            >
              {errorMessage}
            </Typography>

            <SelectSpaces name="event_space_id" id={modalData.eventId} />
            <SubmitButton isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
    </ModalItemsContainer>
  );
};

export default VendorApplyEventModal;
