import { 
  Dialog, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Form, Formik } from "formik";
import {
  StyledModalItemsContainer,
} from "./ConfirmDeleteModal";
import { useState } from "react";
import {
  FormikPasswordField,
  SubmitButton,
} from "components/form-components/CustomFormikFields";

const ConfirmPasswordDialog = ({
  open,
  onClose,
  text,
  buttonText = "confirm",
  action,
  onSucceed,
  color = "primary.main",
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const handleSubmit = async (values) => {
    try {
      await action(values["password"]);
      onSucceed();
      onClose();
    } catch (err) {
      setErrorMessage(err.response.data.message);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <StyledModalItemsContainer>
        <Typography
          align="center"
          variant="h2"
          sx={{ fontWeight: 700, mb: 1, fontSize: 24, color }}
        >
          {text}
        </Typography>
        <Typography
          align="center"
          variant="p"
          sx={{ mb: 1, fontSize: 20, color: "red" }}
        >
          {errorMessage}
        </Typography>
        <IconButton
          onClick={() => onClose()}
          sx={{
            alignSelf: "flex-end",
            position: "absolute",
            top: "0",
            right: "0",
            mb: 8,
          }}
        >
          <Close />
        </IconButton>

        <Formik initialValues={{}} onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <Form>
              <FormikPasswordField
                required
                fullWidth
                margin="dense"
                id="password"
                name="password"
                label="Password"
              />
              <SubmitButton text={buttonText} isSubmitting={isSubmitting} />
            </Form>
          )}
        </Formik>
      </StyledModalItemsContainer>
    </Dialog>
  );
};

export default ConfirmPasswordDialog;
