import { apiSlice } from "app/api/apiSlice";

export const portalSettingApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPortalSetting: builder.query({
      query: () => {
        return {
          url: `/portal/setting/portal/`,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      providesTags: ["PortalSetting"],
    }),
    updatePortalSetting: builder.mutation({
      query: (body) => ({
        url: "/portal/setting/portal/update/",
        method: "PUT",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["PortalSetting"],
    }),
  }),
});

export const { useGetPortalSettingQuery, useUpdatePortalSettingMutation } =
  portalSettingApiSlice;
