import { apiSlice } from "../../app/api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: "/users/user/login/",
        method: "POST",
        body,
      }),
      // invalidatesTags: ["ModuleAccess"],
    }),
    sendOtp: builder.mutation({
      query: (body) => ({
        url: "/users/user/sendForgetPasswordOtp/",
        method: "POST",
        body,
      }),
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: "/users/user/editPasswordWithOtp/",
        method: "PUT",
        body,
      }),
    }),

    updatePassword: builder.mutation({
      query: (body) => ({
        url: "/users/user/editPassword/",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Auth"],
    }),
    emailVerify: builder.mutation({
      query: (body) => ({
        url: "/users/user/send_email_verification/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Auth"],
    }),
    textVerify: builder.mutation({
      query: (body) => ({
        url: "/users/user/send_phone_verification/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Auth"],
    }),
    updatePhone: builder.mutation({
      query: (body) => ({
        url: "/users/user/update_phone/",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Auth"],
    }),
    updateEmail: builder.mutation({
      query: (body) => ({
        url: "/users/user/update_email/",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Auth"],
    }),
    emailValidate: builder.mutation({
      query: (body) => ({
        url: "/users/user/validate_email_verification_code/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Auth"],
    }),
    textValidate: builder.mutation({
      query: (body) => ({
        url: "/users/user/validate_phone_verification_code/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Auth"],
    }),
    deleteAccount: builder.mutation({
      query: () => ({
        url: "/users/user/removeAccount/",
        method: "DELETE",
      }),
      invalidatesTags: ["Auth"],
    }),
    signUp: builder.mutation({
      query: (body) => ({
        url: "/users/user/signup/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Auth"],
    }),
  }),
});

export const {
  useResetPasswordMutation,
  useLoginMutation,
  useSendOtpMutation,
  useUpdatePasswordMutation,
  useUpdateEmailMutation,
  useUpdatePhoneMutation,
  useDeleteAccountMutation,
  useEmailValidateMutation,
  useEmailVerifyMutation,
  useTextValidateMutation,
  useTextVerifyMutation,
  useSignUpMutation,
} = authApiSlice;
