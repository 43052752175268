import { noAccessReq } from "menu";
import { getViewableModules } from "helpers/permissionUtils";
import { ADMIN_ONLY } from "constants/accessHelpers";

export const checkRoutePermitted = (pages, path, permitted = false) => {
  pages.every(({ items, ...item }) => {
    if (items) {
      permitted = checkRoutePermitted(Object.values(items), path, permitted);
    } else if (item.path === path || noAccessReq.includes(path)) {
      permitted = true;
    }
    if (permitted) return false;
    return true;
  });

  return noAccessReq.includes(path) ? true : permitted;
};

export const filterAsidePages = (permissions, pages, role) => {
  const modules = getViewableModules(permissions, "READ");
  const values = Object.values(pages);
  const filteredPages = {};
  values.forEach(({ items, ...item }) => {
    if (
      modules.includes(item.module) ||
      item.dropdown ||
      (ADMIN_ONLY.includes(role) && item.module === "ADMIN")
    ) {
      filteredPages[item.id] = item;
      if (items) {
        filteredPages[item.id].items = filterAsidePages(
          permissions,
          items,
          role
        );
      }
    }
  });
  return filteredPages;
};
