import { Box, Grid } from "@mui/material";
import SquarePurchasedEventCard from "components/Cards/SquareEventCard/SquarePurchasedEventCard";
import SquareLoadingEventCard from "components/Cards/SquareEventCard/SquareLoadingEventCard";
import SquareLoadingEventCardHome from "components/Cards/SquareEventCard/SquareLoadingEventCardHome";
import {
  useGetPublicEventsQuery,
  useGetPurchasedEventQuery,
} from "features/publicEvents/publicEventsApiSlice";
import { usePagination } from "hooks/usePagination";
import NoEventsFound from "./NoEventsFound";
import Pagination from "@mui/lab/Pagination";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
const EventList = (props) => {
  const [pagination] = usePagination();
  // const { data: eventsRaw, isLoading: isEventsLoading } =
  //   useGetPublicEventsQuery({
  //     pagination,
  //   });
  const { data: eventsRaw, isLoading: isEventsLoading } =
    useGetPurchasedEventQuery({
      pagination,
    });
  const events = eventsRaw;
  const loadingCards = [1, 2, 3, 4, 5, 6];
  const pageMetadata = eventsRaw ? eventsRaw.page_metadata : null;
  const [displayedEvents, setDisplayedEvents] = useState([]);
  const [activePage, setActivePage] = useState(1);

  // Set the number of events to display per page
  const eventsPerPage = 4;

  useEffect(() => {
    if (events?.data?.length > 0) {
      // const startIndex = pageMetadata ? pageMetadata.start_index : 0;

      // Calculate the start and end indices based on active page and events per page
      const start = (activePage - 1) * eventsPerPage;
      const end = Math.min(start + eventsPerPage, events?.data?.length);

      const currentEvents = events?.data?.slice(start, end);

      setDisplayedEvents(currentEvents);
    }
  }, [events, activePage, pagination, pageMetadata]);

  const handlePageChange = (page) => {
    // Set the active page when the user clicks a new page
    setActivePage(page);
  };

  // Calculate the total number of pages
  const pageCount = pageMetadata
    ? Math.ceil(events?.data?.length / eventsPerPage)
    : 1;

  return (
    <Box sx={{ pb: 4 }}>
      {events?.data?.length > 0 ? (
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {isEventsLoading
            ? loadingCards.map((item) => (
                <Grid key={`${item}`} item xs={12} sm={6} md={4}>
                  <SquareLoadingEventCard />
                </Grid>
              ))
            : displayedEvents.map((event) => (
                <Grid key={event.name} item xs={12} sm={6} md={4}>
                  <SquarePurchasedEventCard event={event} />
                </Grid>
              ))}
        </Grid>
      ) : (
        <>
          {isEventsLoading ? (
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {loadingCards.map((item) => (
                <Grid key={`${item}`} item xs={12} sm={6} md={4}>
                  <SquareLoadingEventCardHome />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Box className="flex justify-center align-center">
              <NoEventsFound />
            </Box>
          )}
        </>
      )}

      {
        /* Only display pagination if there is more than one page */
        pageCount > 1 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "50px",
              alignItems: "center",
            }}
          >
            <Paper elevation={8}>
              <Pagination
                count={pageCount}
                page={activePage}
                onChange={(e, page) => handlePageChange(page)}
                // boundaryCount={3}
                color="primary"
              />
            </Paper>
          </div>
        )
      }
    </Box>
  );
};

export default EventList;
