import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useGetPaymentMethodsQuery } from "features/paymentPlan/paymentPlanApiSlice";
import {
  FormikSelectWithImageField,
  FormikTextField,
} from "components/form-components/CustomFormikFields";

function PaymentForm({ price }) {
  const { data } = useGetPaymentMethodsQuery();
  const paymentMethods = data
    ? data.map((item) => ({
        label: item.logo_url,
        value: item.identifier,
      })).filter((item) => item.value !== 'CHAPA')
    : [];
    console.log(paymentMethods)
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Payment details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            <span style={{ fontWeight: 700 }}>Price:</span> &nbsp;&nbsp;ETB{" "}
            {price}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormikTextField
            required
            fullWidth
            margin="dense"
            type="number"
            label="Quantity"
            name="quantity"
            id="quantity"
            disabled={true}
          />

          <FormikSelectWithImageField
            label="Payment Method"
            name="pMethod"
            options={paymentMethods}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PaymentForm;
