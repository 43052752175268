import React from "react";
// import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import { useGetPaymentMethodsQuery } from "features/paymentPlan/paymentPlanApiSlice";

function Review({ snapshot }) {
  const { price, reason, quantity } = snapshot;
  const priceDesc = [
    { name: "Reason", amount: reason },
    { name: "Price", amount: price },
    { name: "quantity", amount: quantity },
    { name: "Sub total", amount: price * quantity },
  ];

  // const getTotal = (price, tax) => {
  //   return price;
  // };

  const { data } = useGetPaymentMethodsQuery();
  const selectedPaymentMethod = data.find(
    (item) => item.identifier === snapshot.pMethod
  );

  return (
    <React.Fragment>
      <Box sx={{ px: 4 }}>
        <Typography variant="h6" gutterBottom>
          Summary
        </Typography>
        <List disablePadding sx={{ mb: 4 }}>
          {priceDesc.map((p) => (
            <ListItem sx={{ padding: "8px 0" }} key={p.name}>
              <ListItemText primary={p.name} />
              <Typography variant="body2">{p.amount}</Typography>
            </ListItem>
          ))}
          <ListItem sx={{ padding: "8px 0" }}>
            <ListItemText primary="Total" />
            <Typography variant="h6">ETB {price * quantity}</Typography>
          </ListItem>
        </List>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom fontWeight={700}>
              Item
            </Typography>
            <Typography gutterBottom>{reason}</Typography>
          </Grid>
          <Grid item container direction="column" xs={12} sm={6}>
            <Typography variant="h6" gutterBottom fontWeight={700}>
              Payment details
            </Typography>
            <Grid container>
              <React.Fragment>
                <Grid item xs={12} lg={6}>
                  <Typography gutterBottom>Payment method</Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Typography gutterBottom fontWeight={500}>
                    {selectedPaymentMethod.name}
                  </Typography>
                </Grid>
              </React.Fragment>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default Review;
