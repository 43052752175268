import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import ProtectedRoutes from "routes/ProtectedRoutes";
import { servicesPages, vendorPages, userPages } from "menu";
import UsersAppBar from "./UserAppBar";
import UserRegister from "pages/users/UserRegister";
import UserLogin from "pages/users/UserLogin";
import UserHome from "pages/users/UserHome";
import UserEventDetail from "pages/users/UserEventDetail";
import UserTicketList from "pages/users/Tickets";
import UserProfile from "pages/users/UserProfile";
import UserTickets from "pages/users/UserRequests";
import UserForgotPassword from "pages/users/UserForgotPassword";
const routes = [
  { path: userPages.UserHome.path, exact: true, element: <UserHome /> },
  {
    path: userPages.UserEventDetail.path,
    exact: true,
    element: <UserEventDetail />,
  },
  {
    path: userPages.UserTickets.path,
    exact: true,
    element: <UserTicketList />,
  },
  // {
  //   path: userPages.UserTickets.path,
  //   exact: true,
  //   element: <UserTickets />,
  // },
  {
    path: userPages.UserProfile.path,
    exact: true,
    element: <UserProfile />,
  },
];

const noAsideRoutes = [
  servicesPages.ServicesLogin.path,
  vendorPages.VendorLogin.path,
  userPages.UserLogin.path,
  userPages.UserRegister.path,
  userPages.UserForgotPassword.path,
];

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function Nav() {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      {!noAsideRoutes.includes(location.pathname) && (
        <UsersAppBar open={open} setOpen={setOpen} />
      )}

      <Box
        width={"100%"}
        sx={{
          py: !noAsideRoutes.includes(location.pathname) ? 1 : 0,
          px: !noAsideRoutes.includes(location.pathname) ? 2 : 0,
        }}
      >
        {!noAsideRoutes.includes(location.pathname) && <DrawerHeader />}
        <Routes>
          <Route
            path={userPages.UserLogin.path}
            exact
            element={<UserLogin />}
          />
          <Route
            path={userPages.UserRegister.path}
            exact
            element={<UserRegister />}
          />
          <Route
            path={userPages.UserForgotPassword.path}
            exact
            element={<UserForgotPassword />}
          />
          {/*remove when completed  */}
          {/* <Route
            path={userPages.UserProfile.path}
            exact
            element={<UserProfile />}
          /> */}
          {/*remove when completed  */}
          <Route
            element={
              // <ProtectedRoutes redirectPath={userPages.UserLogin.path} />
              <ProtectedRoutes redirectPath={"/login"} />
            }
          >
            {routes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                exact={route.exact}
                element={route.element}
              />
            ))}
            <Route
              path="*"
              element={<Navigate to={userPages.UserHome.path} replace />}
            />
          </Route>
        </Routes>
      </Box>
    </>
  );
}
