import { apiSlice } from "../../app/api/apiSlice";

export const invitationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInvitations: builder.query({
      query: (query) => {
        const { pagination, searchValue, filters } = query;
        const { pageIndex, pageSize } = pagination;
        const searchQuery = searchValue ? `&query=${searchValue}` : "";
        return {
          url: `/portal/invitation/?page=${pageIndex}&page_size=${pageSize}${searchQuery}${filters}`,
        };
      },
      transformResponse: (response) => {
        const { page_metadata, data } = response.data;
        const invitations = data.map((item) => ({
          ...item,
          name: item.attendee.full_name,
          email: item.attendee.email,
          phone: item.attendee.phone,
          image: item.attendee.image,
          event: item.event.name,
          phone_allowed: item.is_phone_allowed,
          vip: item.is_vip,
          vehicle: item.is_vehicle_allowed,
          plate_number: item.vehicle_plate_number,
          ticket: item.ticket_code,
          invited: false, //TODO update invited
          event_id: item.event.id,
          event_data: item.event,
          attendee_data: item.attendee,
        }));

        return {
          page_metadata,
          data: invitations,
        };
      },
      providesTags: ["Invitation"],
    }),

    getEventInvitations: builder.query({
      query: (query) => {
        const { pagination, searchValue, filters } = query;
        const { pageIndex, pageSize } = pagination;
        const searchQuery = searchValue ? `&query=${searchValue}` : "";
        return {
          url: `/portal/event/invitations/?page=${pageIndex}&page_size=${pageSize}${searchQuery}${filters}`,
        };
      },
      transformResponse: (response) => {
        const { page_metadata, data } = response.data;
        const invitations = data.map((item) => ({
          ...item,
          name: item.attendee.full_name,
          sex: item.attendee.sex,
          organization: item.attendee.organization,
          image: item.attendee.image,
          phone: item.attendee.phone,
          email: item.attendee.email,
          invited: item.is_email_sent ? "Yes" : "No",
          is_invited: item.is_email_sent,
          ticket: item.ticket_code,
          rsvp: item?.rsvp.rsvp,
        }));

        return {
          page_metadata,
          data: invitations,
        };
      },
      providesTags: ["Invitation"],
    }),

    getInvitation: builder.query({
      query: (id) => {
        return {
          url: `/portal/invitation/detail/?invitation_id=${id}`,
        };
      },
      transformResponse: (response) => {
        const data = response.data;
        const invitation = {
          ...data,
          attendee_id: data.attendee.id,
          full_name: data.attendee.full_name,
          phone: data.attendee.phone,
          email: data.attendee.email,
          sex: data.attendee.sex,
          event: data.event.id,
          event_name: data.event.name,
        };

        return invitation;
      },
      providesTags: ["Invitation"],
    }),

    createInvitation: builder.mutation({
      query: (body) => ({
        url: "/portal/invitation/create/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue, meta, arg) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Invitation"],
    }),

    approveInvitation: builder.mutation({
      query: (body) => ({
        url: "/portal/invitation/ticket/send/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue, meta, arg) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Invitation"],
    }),

    declineInvitation: builder.mutation({
      query: (body) => ({
        url: "/portal/invitation/decline/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue, meta, arg) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Invitation"],
    }),

    updateInvitation: builder.mutation({
      query: (body) => ({
        url: "/portal/invitation/update/",
        method: "PUT",
        body,
        
      }),
      transformResponse(baseQueryReturnValue, meta, arg) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Invitation"],
    }),

    sendInvitation: builder.mutation({
      query: (body) => ({
        url: "/portal/invitation/send/",
        method: "POST",
        body,
      }),
      transformResponse(baseQueryReturnValue, meta, arg) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Invitation"],
    }),

    sendBulkInvitation: builder.mutation({
      query: (body) => ({
        url: "/portal/invitation/create/bulk/",
        method: "PUT",
        body,
      }),
      transformResponse(baseQueryReturnValue, meta, arg) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Invitation"],
    }),

    deleteInvitation: builder.mutation({
      query: (body) => ({
        url: "/portal/invitation/delete/",
        method: "DELETE",
        body,
      }),
      transformResponse(baseQueryReturnValue, meta, arg) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Invitation"],
    }),

    // Invitation Type Management
    // getInvitationTypes: builder.query({
    //   query: (eventId) => ({
    //     url: `/portal/invitation/types/?event_id=${eventId}`,
    //   }),
    //   providesTags: ["InvitationType"],
    // }),
    getInvitationTypes: builder.query({
      query: (query) => {
        console.log(query,"dddddddddddddd")
        const { pagination, eventId } = query;
        const { pageIndex, pageSize } = pagination;
        return {
          url: `/portal/invitation/types/?event_id=${eventId}&page=${pageIndex}&page_size=${pageSize}`,
        };
      },
      transformResponse: (response) => {
        const { page_metadata, data } = response.data;
        return {
          page_metadata,
          data, // Adjust as necessary based on the response structure
        };
      },
      providesTags: ["InvitationType"],
    }),

    createInvitationType: builder.mutation({
      query: (body) => ({
        url: "/portal/invitation/type/create/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["InvitationType"],
    }),

    updateInvitationType: builder.mutation({
      query: (body) => ({
        url: "/portal/invitation/type/update/",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["InvitationType"],
    }),

    deleteInvitationType: builder.mutation({
      query: (body) => ({
        url: "/portal/invitation/type/delete/",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["InvitationType"],
    }),
  }),
});

export const {
  useGetInvitationsQuery,
  useGetEventInvitationsQuery,
  useGetInvitationQuery,
  useApproveInvitationMutation,
  useDeclineInvitationMutation,
  useCreateInvitationMutation,
  useUpdateInvitationMutation,
  useSendInvitationMutation,
  useSendBulkInvitationMutation,
  useDeleteInvitationMutation,
  useGetInvitationTypesQuery,
  useCreateInvitationTypeMutation,
  useUpdateInvitationTypeMutation,
  useDeleteInvitationTypeMutation,
} = invitationApiSlice;