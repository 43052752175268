import { apiSlice } from "../../app/api/apiSlice";

export const invitationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInvitationTypes: builder.query({
      query: (query) => {
        console.log(query,"dddddddddddddd")
        const { pagination, eventId } = query;
        const { pageIndex, pageSize } = pagination;
        return {
          url: `/portal/invitation/types/?event_id=${eventId}&page=${pageIndex}&page_size=${pageSize}`,
        };
      },
      transformResponse: (response) => {
        const { page_metadata, data } = response.data;
        return {
          page_metadata,
          data, // Adjust as necessary based on the response structure
        };
      },
      providesTags: ["InvitationType"],
    }),
    
    UpdateBulkInvitationType: builder.mutation({
      query: (body) => (console.log(body), {
        url: "/portal/invitation/type/set/",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["InvitationType"],
    }),
  }),
});

export const {
  useGetInvitationTypesQuery,
  useUpdateBulkInvitationTypeMutation,
} = invitationApiSlice;