// import { useState } from "react";
import { apiSlice } from "../../app/api/apiSlice";

export const subscriptionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getActiveSubscriptionPayment: builder.query({
      query: () => ({
        url: "/company/subscription/payment/active/",
      }),
      transformResponse: (response) => {
        return response.data;
      },
      provideTags: ["Subscription"],
    }),
    getSubscriptionPaymentHistory: builder.query({
      query: () => {
        // const { pagination } = query;
        // const { pageIndex, pageSize } = pagination;
        // const [paymentIdentifier, setPaymentIdentifier] = useState("");
        return {
          url: `/company/subscription/payment/history/?page=0&page_size=10&order_by=ends_time&order_by_clause=DESC`,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      provideTags: ["Subscription"],
    }),
    getSuperAdminSubscriptionPayments: builder.query({
      query: (query, company_id) => {
        const { pagination, searchValue, filters } = query;
        const { pageIndex, pageSize } = pagination;
        // const [companyId, setCompmanyId] = useState();

        return {
          url: `/su/organiser/subscription/payment/history/?company_id=${company_id}&page=${pageIndex}&page_size=${pageSize}&order_by_clause=ASC`,
        };
      },
    }),
    getSubscriptionPaymentDetail: builder.query({
      query: (payment_id) => ({
        url: `/company/subscription/payment/detail/?company_subscription_payment_id=${payment_id}`,
      }),
      transformResponse: (response) => {
        return response.data;
      },
      provideTags: ["Subscription"],
    }),
    sendUnsubscribeRequest: builder.mutation({
      query: (body) => ({
        url: "/company/unsubscribe/otp/",
        method: "POST",
        body,
      }),
      transformResponse: (response) => {
        return response.data;
      },
      provideTags: ["Subscription"],
    }),
    sendUnsubscribe: builder.mutation({
      query: (body) => ({
        url: "/company/unsubscribe/",
        method: "POST",
        body,
      }),
      transformResponse: (response) => {
        return response.data;
      },
      provideTags: ["Subscription"],
    }),
    sendCheckoutURL: builder.mutation({
      query: (body) => ({
        url: "/company/subscribe/",
        method: "POST",
        body,
      }),
      transformResponse: (response) => {
        return response.data;
      },
      provideTags: ["Subscription"],
    }),
  }),
});

export const {
  useGetActiveSubscriptionPaymentQuery,
  useGetSubscriptionPaymentHistoryQuery,
  useGetSuperAdminSubscriptionPaymentsQuery,
  useGetSubscriptionPaymentDetailQuery,
  useSendUnsubscribeRequestMutation,
  useSendUnsubscribeMutation,
  useSendCheckoutURLMutation,
} = subscriptionApiSlice;
