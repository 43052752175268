import { format } from "date-fns";

// export const getProperDate = (date) => {
//   return format(date, "yyyy-MM-dd");
// };

export const getProperDateTime = (date) => {
  return format(date, "dd/MM/yy HH:mm:SS");
};

export const getParsedDate = (date) => {
  try {
    return format(date, "PP");
  } catch (error) {
    return "error";
  }
};

export const getParsedTime = (date) => {
  try {
    return format(date, "pp");
  } catch (error) {
    return "error";
  }
};
