import AddIcon from "@mui/icons-material/Add";
import { Box, Typography } from "@mui/material";
import DefaultSnackbar from "components/DefaultSnackbar";
import EnhancedDataTable from "components/EnhancedDataTable";
import ConfirmDeleteModal from "components/formModals/ConfirmDeleteModal";
import CreateServicesModal from "components/formModals/CreateServicesModal";
import StyledToolbarButton from "components/styled-components/StyledToolbarButton";
import { useModal } from "contexts/modalContext";
import {
  useDeleteServiceMutation,
  useGetServicesQuery,
} from "features/serviceProvider/serviceproviderApiSlice";
import useGetData from "hooks/useGetData";
import { useState } from "react";
// import { useNavigate } from "react-router-dom";
import ServiceProviderRequestsHeader from "../ServiceProviderRequest/ServiceProviderRequestHeader";
const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "price",
    numeric: true,
    disablePadding: true,
    label: "Price",
  },
  {
    id: "description",
    numeric: true,
    disablePadding: true,
    label: "Description",
  },
  // {
  //   id: "accessories",
  //   numeric: true,
  //   disablePadding: true,
  //   label: "Accessories",
  // },
];

const ToolbarActions = () => {
  const { toggleModal } = useModal();

  // module access
  // const moduleAccess = useModuleAccess("SERVICE_PROVIDER"); // make this SERVICE_PROVIDER

  return (
    <Box sx={{ display: "flex", gap: "0.6rem", p: "0.5rem", flexWrap: "wrap" }}>
      {
        <StyledToolbarButton
          onClick={() => toggleModal("modal1")}
          startIcon={<AddIcon />}
        >
          <Typography variant="p">Create Service</Typography>
        </StyledToolbarButton>
      }
    </Box>
  );
};

const Services = () => {
  const {
    data,
    isLoading,
    pagination,
    setPagination,
    rowCount,
    searchValue,
    setSearchValue,
    showSearch,
    setShowSearch,
    refetch,
  } = useGetData(true)(useGetServicesQuery);

  const [deleteService, response] = useDeleteServiceMutation();
  // const navigate = useNavigate();

  const { showModal, toggleModal, modalPayload } = useModal();

  if (showModal.modal1) {
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOptions({
      open: false,
    });
  };

  // const [modalData, setModalData] = useState({
  //   open: false,
  //   id: "",
  //   price: "",
  //   name: "",
  //   description: "",
  //   accessories: "",
  // });

  const [snackOptions, setSnackOptions] = useState({
    open: false,
    text: "",
  });

  const onEdit = (id, row) => {
    toggleModal("modal1", {
      action: "edit",
      id,
      row,
    });
  };

  // const [person, setPerson] = useState();
  const onView = (id, row) => {
    toggleModal("modal1", {
      action: "view",
      id,
      row,
    });
  };

  const onDeleteIconClicked = (id) => {
    toggleModal("modal3", {
      action: "delete",
      id,
    });
  };

  // const getDeleteBody = (items, password) => {
  //   return {
  //     event_request_ids: items,
  //     password,
  //   };
  // };

  const deleteHandler = async (id, password) => {
    const res = await deleteService({
      service_id: modalPayload?.id,
    });
    if (res?.error) {
      throw new Error(res.error.data.message);
    }
  };

  return (
    <Box mt={4}>
      <ServiceProviderRequestsHeader
        subHeader={"Displays list of services offered"}
        header={"My Services"}
      />

      <EnhancedDataTable
        headCells={headCells}
        rows={data}
        rowCount={rowCount}
        manualPagination={true}
        pagination={pagination}
        onPaginationChange={setPagination}
        onEdit={onEdit}
        onView={onView}
        onDelete={onDeleteIconClicked}
        isLoading={isLoading}
        allowExport={false}
        toolbarActions={<ToolbarActions />}
        // editIcon={moduleAccess.update}
        // deleteIcon={moduleAccess.delete}
        // onDeleteSelected={handleMassDelete}
        searchProps={{ searchValue, setSearchValue, showSearch, setShowSearch }}
      />
      {showModal.modal1 && (
        <CreateServicesModal
          onSucced={(text) => {
            setSnackOptions({
              open: true,
              text: text || "Successfully created service",
            });
          }}
        />
      )}
      {showModal.modal3 && (
        <ConfirmDeleteModal
          text="Delete Service?"
          action={deleteHandler}
          onSucceed={() => {
            setSnackOptions({
              open: true,
              text: "Successfully deleted service",
            });
          }}
        />
      )}
      <DefaultSnackbar options={snackOptions} handleClose={handleClose} />
    </Box>
  );
};

export default Services;
