import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box,
  Grid,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { useGetEventQuery } from "features/events/eventsApiSlice";
import { InnerPages } from "menu";
import { useNavigate, useSearchParams } from "react-router-dom";

const StyledEventInvitationsHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "end",
  paddingBottom: "1rem",
  borderBottom: `3px solid ${theme.palette.primary.light}`,
  // boxShadow: `0px 3px 7px 1px rgba(0,0,0,0.17)`,
}));

const StyledBackButton = styled(KeyboardBackspaceIcon)(({ theme }) => ({
  marginLeft: 16,
  color: theme.palette.primary.main,
  "&:hover": {
    cursor: "pointer",
  },
  marginBottom: 2,
}));

const StyledSkeleton = styled(Skeleton)(() => ({
  display: "inline-block",
  width: "170px",
}));

const EventInvitationsHeader = ({
  onSendToSelected,
  onSendForAll,
  sendAllDisabled = false,
  sendSelectedDisabled = false,
}) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let event_id = searchParams.get("event_id");
  const { data: event, isLoading } = useGetEventQuery(event_id);

  return (
    <StyledEventInvitationsHeader>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "start",
          width: "100%",
          paddingTop: 4,
        }}
      >
        <StyledBackButton onClick={() => navigate(-1)} />
        <Grid container>
          <Grid item xs={12} md={9}>
            <Typography
              ml={2}
              mr={0}
              variant="h1"
              fontWeight={700}
              fontSize={22}
              sx={{ alignSelf: "flex-start" }}
              color="primary.main"
            >
              {isLoading ? (
                <StyledSkeleton variant="text" />
              ) : event ? (
                event["name"]
              ) : null}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography color={"primary.main"}>{event?.status}</Typography>
            <Tooltip
              title={
                event?.status === "ONGOING" || event?.status === "PAST"
                  ? "Show attendance"
                  : "Attendance is available only for ongoing and past events."
              }
            >
              <Box>
                <IconButton
                  color="primary"
                  size="small"
                  sx={{ ml: 1 }}
                  disabled={
                    !(event?.status === "ONGOING" || event?.status === "PAST")
                  }
                  onClick={() =>
                    navigate(
                      `${InnerPages.EventAttendance.path}/?event_id=${event.id}&type=${event.event_type}&name=${event.name}`
                    )
                  }
                >
                  <RemoveRedEyeIcon sx={{ width: 18, height: 18 }} />
                </IconButton>
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </StyledEventInvitationsHeader>
  );
};

export default EventInvitationsHeader;
