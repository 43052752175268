import { Box, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { baseUrl } from "app/api/apiSlice";
import { useLanguage } from "hooks/useLanguage";
import { FeaturedBody, FeaturedTitle } from "../FeaturedEventCard/Styles";
import {
  StyledEventSquareCardWrapper,
  StyledSquareCardImg,
  StyledDateTextTitle,
  StyledDateTextBody,
} from "./Styles";
import moment from "moment";
import Marquee from "react-fast-marquee";

const apiUrl = baseUrl;

const SquarePurchasedEventCard = ({ event }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { language } = useLanguage();
  console.log("rugu the event", event, "kljhiu igiu");
  return (
    <StyledEventSquareCardWrapper>
      <Box
        sx={{
          width: "100%",
          maxHeight: "220px",
          overflow: "hidden",
          position: "relative",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        }}
      >
        <StyledSquareCardImg
          src={`${apiUrl}${event?.event?.image?.image_medium_path}`}
          alt="event images"
          onClick={() =>
            navigate(
              `/users-web/tickets/?id=${event?.payment?.system_order_id}`
            )
          }
        />
        <Box sx={{ position: "absolute", top: 20, right: 20 }}></Box>
      </Box>

      <Box sx={{ mt: 2, px: 1, pb: 1 }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <FeaturedTitle>{event?.event?.name[language]}</FeaturedTitle>
        </Box>
        <StyledDateTextTitle style={{ fontSize: "1rem" }}>
          Date
        </StyledDateTextTitle>{" "}
        <StyledDateTextBody style={{ fontSize: "1rem" }}>
          {event?.is_recurring
            ? "Multiple Dates"
            : moment(event?.event?.start_date).format("MMM DD, YY")}
        </StyledDateTextBody>
        <br />
        <div style={{ display: "flex" }}>
          <StyledDateTextTitle
            style={{
              fontSize: "1rem",
              marginRight: "5px",
            }}
          >
            Location
          </StyledDateTextTitle>{" "}
          <StyledDateTextBody
            style={{
              fontSize: "1rem",
            }}
          >
            <Marquee speed={30}>
              {event?.event?.location?.name || event?.location_name}
            </Marquee>
          </StyledDateTextBody>
        </div>
        <div style={{ display: "flex" }}>
          <StyledDateTextTitle
            style={{
              fontSize: "1rem",
              marginRight: "5px",
            }}
          >
            Tickets Bought
          </StyledDateTextTitle>{" "}
          <StyledDateTextBody
            style={{
              fontSize: "1rem",
            }}
          >
            {event?.ticket_codes?.length}
          </StyledDateTextBody>
        </div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <FeaturedBody
            overflow="hidden"
            whiteSpace="nowrap"
            width={"200px"}
            textOverflow="ellipsis"
            color={theme.palette.text.secondary}
            sx={{ mr: 1 }}
          >

          </FeaturedBody>
        </Box>
      </Box>
    </StyledEventSquareCardWrapper>
  );
};

export default SquarePurchasedEventCard;
