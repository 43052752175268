import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import {
  // Box,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
// import Toast from "components/Toast";
import {
  FormikPasswordField,
  SubmitButton,
} from "components/form-components/CustomFormikFields";

import { ModalItemsContainer } from "components/CustomModal/Styles";
import { useDeleteAccountMutation } from "features/auth/authApiSlice";
import { useDispatch } from "react-redux";
import { logout } from "features/auth/authSlice";

export default function DeleteAccountModal({ setSnackOptions }) {
  const [error, setError] = useState(null);
  const [updated, setUpdated] = useState(false);
  const [deleteAccount, { isLoading }] = useDeleteAccountMutation();
  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    try {
      let res = await deleteAccount();
      if (res?.error) {
        throw new Error(res.error.data.message);
      } else {
        // dispatch(logout());
        localStorage.removeItem("refresh");
        localStorage.removeItem("access");
        window.location.reload();
        // window.location.replace("/users-web/login");
      }
    } catch (err) {
      setError(err.message);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setUpdated(false);
    }, 2000);
  });
  return (
    <ModalItemsContainer>
      <Formik
        initialValues={{ p: "" }}
        // validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <Typography
              variant="h1"
              fontWeight={600}
              fontSize={23}
              sx={{ textAlign: "center", mt: 1, mb: 2 }}
              color="red"
            >
              Are you sure you want to delete your account?
            </Typography>

            <Typography
              variant="h1"
              fontWeight={400}
              fontSize={"1rem"}
              sx={{ textAlign: "start", mt: 1, mb: 2, color: "#b3b1b1" }}
              //   color="primary.main"
            >
              Deleting your account is a significant step, and it's important to
              be aware of the implications:import {logout} from
              'features/auth/authSlice';
            </Typography>
            <ul>
              <li>
                {" "}
                <Typography
                  variant="h1"
                  fontWeight={400}
                  fontSize={"1rem"}
                  sx={{ textAlign: "start", mt: 1, mb: 2, color: "#b3b1b1" }}
                  //   color="primary.main"
                >
                  Once you remove your account, data associated with your
                  account, such as saved events, reactions, comments, ratings,
                  reviews or any user-generated content, will no longer be
                  available. This means any personalized settings, history, and
                  content you've contributed will be permanently lost.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="h1"
                  fontWeight={400}
                  fontSize={"1rem"}
                  sx={{ textAlign: "start", mt: 1, mb: 2, color: "#b3b1b1" }}
                  //   color="primary.main"
                >
                  you will no longer have access to your account, and all
                  app-related services will be discontinued for your account.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="h1"
                  fontWeight={400}
                  fontSize={"1rem"}
                  sx={{ textAlign: "start", mt: 1, mb: 2, color: "#b3b1b1" }}
                  //   color="primary.main"
                >
                  Your username and associated profile information will be
                  removed from the app.
                </Typography>
              </li>
            </ul>
            <Typography
              variant="h1"
              fontWeight={400}
              fontSize={"1rem"}
              sx={{ textAlign: "start", mt: 1, mb: 2, color: "#b3b1b1" }}
              //   color="primary.main"
            >
              Feel free to contact us if you have questions or concerns at
              <a color="#b3b1b1" href="mailto:support@awuraplc.org">
                support@awuraplc.org.
              </a>{" "}
              If you're certain about your decision, you can confirm to delete
              your account.
            </Typography>
            <Typography
              variant="h1"
              fontWeight={400}
              fontSize={"1rem"}
              sx={{ textAlign: "start", mt: 1, mb: 2, color: "#b3b1b1" }}
            >
              Thank you for using our platform.
            </Typography>
            <Typography align="center" variant="p" sx={{ mb: 3, color: "red" }}>
              {error}
            </Typography>

            <SubmitButton
              text="Confirm"
              isSubmitting={isLoading}
              backgroundColor="red"
            />
          </Form>
        )}
      </Formik>
    </ModalItemsContainer>
  );
}
