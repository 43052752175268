import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
// import CorporateFareIcon from "@mui/icons-material/CorporateFare";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const StyledHeader = styled(Box)(({}) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const ServiceProviderRequestsHeader = ({ subHeader, header, showAllTime }) => {
  return (
    <StyledHeader sx={{ pb: 2, color: "primary.main", mt: 2 }}>
      <Stack spacing={0.5}>
        <Stack
          direction="row"
          spacing={1}
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          <Typography variant={"h5"}>{subHeader}</Typography>
        </Stack>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            alignItems: "center",
          }}
        >
          <Typography
            variant="h1"
            fontWeight={700}
            fontSize={26}
            sx={{ alignSelf: "flex-end" }}
            color="primary.main"
          >
            {header}
          </Typography>
        </Box>
      </Stack>
      {showAllTime ? (
        <Box>
          <Typography
            variant="body"
            fontSize={12}
            fontWeight={700}
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            All time
          </Typography>
        </Box>
      ) : null}
    </StyledHeader>
  );
};

export default ServiceProviderRequestsHeader;
