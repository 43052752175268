import jwtDecode from "jwt-decode";

export const useAuth = () => {
  const accessToken = localStorage.getItem("access") || null;
  // const refreshToken = localStorage.getItem("refresh") || null;

  try {
    const user_info = accessToken ? jwtDecode(accessToken) : null;
    const user = user_info?.username;

    const role = user_info?.role_name;

    const isAuth = Boolean(accessToken);

    return { user, accessToken, role, isAuth };
  } catch (err) {
    return { user: null, accessToken: null, role: null, isAuth: null };
  }
  //saving refresh token to local storage
};
