import {
  Button,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { useState } from "react";
import Close from "@mui/icons-material/Close";
import { ModalItemsContainer } from "components/CustomModal/Styles";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useSendUnsubscribeMutation } from "features/subscription/subscriptionApiSlice";
import { publicWebUrl } from "constants/api";

const CancelSubscriptionModal = ({
  eventId,
  handleClose,
  open,
  otpActive,
  title,
  setSpecialServices,
  ...props
}) => {
  const [otp, setOtp] = useState("");

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const [unsubscribe, isLoading] = useSendUnsubscribeMutation();

  const handleSubmit = async (values) => {
    const res = await unsubscribe({
      otp_code: values,
    });
    handleClose();
    window.location.href = publicWebUrl;
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ModalItemsContainer sx={{ width: "fit-content" }}>
        <Typography
          align="center"
          variant="h2"
          sx={{
            fontWeight: 700,
            mb: 3,
            fontSize: 24,
            color: "primary.main",
          }}
        >
          Cancel Subscription
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{
            alignSelf: "flex-end",
            position: "absolute",
            top: "0",
            right: "0",
            mb: 8,
          }}
        >
          <Close />
        </IconButton>
        <Typography
          align="center"
          variant="h5"
          sx={{
            fontWeight: 600,
            mb: 3,
            fontSize: 18,
            color: "primary.main",
          }}
        >
          Enter the OTP sent to your email
        </Typography>
        {!otpActive ? (
          <Typography align="center" color="#c84848" mb={2}>
            OTP hasn't expired please your that
          </Typography>
        ) : (
          <></>
        )}
        <form
          onSubmit={() => handleSubmit(otp)}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <MuiOtpInput
            value={otp}
            onChange={handleChange}
            length={6}
            gap={1}
            name="otp"
            TextFieldsProps={{
              size: "medium",
              placeholder: "-",
              sx: { width: { xs: "2.5rem", sm: "5rem" } },
            }}
          />
          <Button type="submit" variant="contained">
            Submit
          </Button>
        </form>
      </ModalItemsContainer>
    </Modal>
  );
};

export default CancelSubscriptionModal;
