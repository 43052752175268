import { Box, Tooltip, Typography } from "@mui/material";
import React from "react";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";

const NotificationsCounter = ({ count, height = 20, width = 20 }) => {
  return (
    <Box
      sx={{
        borderRadius: "50%",
        height: height,
        width: width,
        backgroundColor: "error.main",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // px: 0.9,
        // pt: 0.4,
      }}
    >
      {/* <Typography
        variant="body"
        sx={{ color: "#fff", fontSize: "0.79rem", fontWeight: 600 }}
      >
        {count <= 9 && count >= 0 ? count : `9+`}
      </Typography> */}
    </Box>
  );
};

const NotificationIndicator = ({ count }) => {
  return (
    <Tooltip title="notifications">
      <Box
        sx={{
          position: "relative",
          height: 20,
          width: 20,
          mr: 1,
        }}
      >
        <NotificationsNoneOutlinedIcon
          sx={{
            color: "white",
            position: "absolute",
            top: 0,
            botom: 0,
            right: 0,
            left: 0,
            height: 20,
            width: 20,
          }}
        />
        {count > 0 && (
          <Box sx={{ position: "absolute", top: 0, right: 0 }}>
            <NotificationsCounter count={count} height={10} width={10} />
          </Box>
        )}
      </Box>
    </Tooltip>
  );
};

export default NotificationIndicator;
