import { apiSlice } from "app/api/apiSlice";

export const attendeesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllAttendees: builder.query({
      query: (query) => {
        const { pagination, searchValue, filters } = query;
        const { pageIndex, pageSize } = pagination;
        const searchQuery = searchValue ? `&query=${searchValue}` : "";
        return {
          url: `/portal/event/attendee/?page=${pageIndex}&page_size=${pageSize}${searchQuery}${filters}`,
        };
      },

      transformResponse: (response) => {
        const { page_metadata, data } = response.data;
        const attendees = data.map((item) => ({
          ...item,
          name: item.full_name,
          organization: item.organization?.name,
          orgId: item.organization?.id,
          group_name: item.group?.name,
          attendee_group_id: item.group?.id,
          created_by: item.created_by.email,
        }));

        return {
          page_metadata,
          data: attendees,
        };
      },
      providesTags: ["Attendee"],
    }),
    createAttendee: builder.mutation({
      query: (body) => ({
        url: `/portal/event/attendee/create/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Attendee"],
    }),
    importAttendees: builder.mutation({
      query: (body) => ({
        url: "/portal/event/attendee/import/",
        method: "PUT",
        body,
      }),
      transformResponse(baseQueryReturnValue, meta, arg) {
        return baseQueryReturnValue;
      },
      invalidatesTags: ["Attendee"],
    }),
    updateAttendee: builder.mutation({
      query: (body) => ({
        url: `/portal/event/attendee/update/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Attendee"],
    }),
    deleteAttendee: builder.mutation({
      query: (body) => ({
        url: `/portal/event/attendee/delete/`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Attendee"],
    }),
  }),
});

export const {
  useGetAllAttendeesQuery,
  useImportAttendeesMutation,
  useCreateAttendeeMutation,
  useUpdateAttendeeMutation,
  useDeleteAttendeeMutation,
} = attendeesApiSlice;
